import axios from "axios";
import IBuild from "../components/app/builds/IBuild";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export default function GetBuildsByProductType(productTypeId:number, doGetBuildsOfAllTime:boolean) {
  return axios
    .get<Array<IBuild>>(`${API_URL}/Builds/GetBuildsByProductType?productType=${productTypeId}&doGetBuildsOfAllTime=${doGetBuildsOfAllTime}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      if (response.data) {
        const builds = response.data.sort(
          (a, b) =>
            new Date(b.uploadDate).getTime() - new Date(a.uploadDate).getTime()
        );
        return builds
      }

      return []
    })
}

export function GetRoofMountLatestBuild()
{
  return axios
    .get<IBuild>(API_URL + "/Builds/GetLatestRmBuild", {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
        return response.data;
    });
}

export function GetGroundMountLatestBuild()
{
 return axios
    .get<IBuild>(API_URL + "/Builds/GetLatestGmBuild", {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
        return response.data;
    })
}
