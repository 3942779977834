import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import {Auth0Provider} from "@auth0/auth0-react";

if (
    !process.env.REACT_APP_AUTH0_AUDIENCE ||
    !process.env.REACT_APP_AUTH0_CLIENT_ID ||
    !process.env.REACT_APP_AUTH0_DOMAIN
) {
    throw new Error(
        "Missing Auth0 configuration. Check your environment variables.",
    )
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  
  // These errors are being thrown by 3rd party libraries
  // and doesn't provide any information for us.
  // Clients are not being affected by it.
  // More info: https://github.com/getsentry/sentry-javascript/issues/3440
  ignoreErrors: ['Object Not Found Matching Id', 'Non-Error promise rejection captured']

});

ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          authorizationParams={{
            redirect_uri: window.location.origin+"/auth",
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          }}
      >
        <App />
      </Auth0Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
