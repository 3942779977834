import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import smallLogo from "../../../assets/images/logo-small.png";
import {useLogout} from "../../authentication/AuthenticationService";

type Email = {
  email: string;
};

function HeaderContainer({ email }: Email) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const logout = useLogout()
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  return (
    <header id="navbar">
      <div id="navbar-container" className="boxed">
        <div className="navbar-header">
          <div className="navbar-brand pl-1">
            <img src={smallLogo} alt="" className="img-fluid" />
            <div className="brand-title d-inline-block pl-2 align-top"></div>
          </div>
        </div>

        <div className="navbar-content">
          <ul className="nav navbar-top-links">
            <li className="tgl-menu-btn d-md-none">
              <div className="mainnav-toggle">
                <i className="pli-list-view icon-lg"></i>
              </div>
            </li>
          </ul>
          <ul className="nav navbar-top-links">
            <Dropdown
              className="custom-dropdown"
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <DropdownToggle color="transparent" className="text-right" caret>
                <span className="ic-user pull-right">
                  <span className="d-inline-block">
                    {email + " "}
                  </span>
                  <i className="pli-male"></i>
                </span>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-sm dropdown-menu-right panel-default">
                <ul className="head-list">
                  <li>
                    <Link to="/logout" onClick={logout}>
                      <div className="pli-unlock icon-lg icon-fw"></div> Logout
                    </Link>
                  </li>
                </ul>
              </DropdownMenu>
            </Dropdown>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default HeaderContainer;
