import React, { useState, useEffect } from "react";

function OpenTextResults(props) {
  const [question, setQuestion] = useState(props.question);
  const [answers, setAnswers] = useState(props.answers);

  useEffect(() => {
    setQuestion(props.question);
    setAnswers(props.answers);
  }, [props]);

  return (
    <div
      style={{
        borderTop: "1px solid black",
        padding: "5px 0 5px 0",
        display: "flex",
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRight: "1px solid lightgray",
          minWidth: "130px",
        }}
      >
        Open Text
      </div>
      <div style={{ paddingLeft: "20px" }}>
        <h4>{question}</h4>
        {answers.length === 0 ? "There are currently no answers" :
        answers.map((answer) => {
            return <div style={{borderTop: '1px solid lightgray'}}>{answer.answer}</div>;
        })}
      </div>
    </div>
  );
}

export default OpenTextResults;
