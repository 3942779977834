import React from "react";
import ILicense from "../allLicenses/ILicense";
import { Column } from "react-table";
import { nameof } from "ts-simple-nameof";
import KeyCopyCell from "../clients/manageClient/KeyCopyCell";
import { GetDate } from "../../DateHelpers";
import IBuild from "../builds/IBuild";
import { DownloadBuild } from "../builds/DownloadBuild";
import { GetIsNotSuspendedLicense } from "../../authentication/AuthenticationService"

function CompanyUserLicensesColumns(gmbuild : IBuild | undefined,  rmbuild : IBuild | undefined ): Array<Column<ILicense>> {
  var columns : Array<Column<ILicense>> = [
    {
      Header: "Product",
      accessor: nameof<ILicense>((license) => license.productTypeName),
    },
    {
      Header: "Activation Key",
      accessor: nameof<ILicense>((license) => license.licenseKey),
      Cell: ({value}) => {
        return KeyCopyCell(value, false);
      }
    },
    {
      Header: "Valid till",
      accessor: nameof<ILicense>((license) => license.validTill),
      Cell: ({ value }) => {
        return <span>{GetDate(value)}</span>;
      },
    }
  ]

  var isGmLicenseNotSuspended = GetIsNotSuspendedLicense(0);
  var isRmLicenseNotSuspended = GetIsNotSuspendedLicense(1);

  if (isGmLicenseNotSuspended || isRmLicenseNotSuspended) {
    columns.push(
      {
        Header: `Latest version`,
        accessor: nameof<ILicense>((license) => license.productTypeId),
        Cell: ({value}) => {

          if (gmbuild !== undefined && gmbuild.productTypeId === value && isGmLicenseNotSuspended) {
            return DownloadBuild(gmbuild.id, gmbuild.file, gmbuild.versionNumber, true, true, true)
          }
    
          if(rmbuild !== undefined && rmbuild.productTypeId === value && isRmLicenseNotSuspended) {
            return DownloadBuild(rmbuild.id, rmbuild.file, rmbuild.versionNumber, true, true, true)
          }
          
          return <span></span>;
        }
      });
  }

  return columns;
}

export default CompanyUserLicensesColumns;
