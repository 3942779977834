import React, { useState, useEffect } from "react";
import QuestionTypes from "../questionTypes";
import { Button } from "reactstrap";
import TextField from "../../forms/TextField";

function OpenTextQuestion(props) {
  const [title, setTitle] = useState(props.question.question);
  const setQuestions = props.setQuestions;
  const updateQuestion = props.updateQuestion;

  useEffect(() => {
    updateValue();
  }, [title]);

  const updateValue = () => {
    const questions = [...props.questions];
    questions.splice(props.index, 1, {
      id: props.question.id,
      question: title,
      sequenceNumber: props.index 
    });
    setQuestions(questions);
    updateQuestion({ type: QuestionTypes.OpenText, id: props.question.id});
  };

  return (
    <div
      style={{
        borderTop: "1px solid black",
        padding: "5px 0 5px 0",
        display: "flex",
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRight: "1px solid lightgray",
          minWidth: "65px",
        }}
      >
        {" "}
        Open Text{" "}
      </div>
      <div style={{ paddingLeft: "20px" }}>
        {TextField(
          "Question",
          title,
          setTitle,
          undefined,
          undefined,
          undefined,
          "text",
          true,
          1,
          300,
          true,
        )}
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          onClick={() => {
            props.removeQuestion({
              type: QuestionTypes.OpenText,
              id: props.question.id,
              index: props.index,
            });
          }}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

export default OpenTextQuestion;
