import React, { useMemo, useState } from "react";
import { useEffect } from "react";
import { Column } from "react-table";
import { GetCompanyNameByContactId } from "../../../api/CompanyApi";
import { GetUserCompanyLicenses } from "../../../api/LicensesApi";
import ILicense from "../allLicenses/ILicense";
import AppContainer from "../container/AppContainer";
import CompanyAdminLicensesColumns from "./CompanyAdminLicensesColumns";
import {Table} from "../helpers/CompanyAdminLicensesTable";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function CompanyAdminLicenses() {
  usePageTimeLogger("Licenses (Page)");

  const [licenses, setLicenses] = useState(Array<ILicense>());
  const [containerTitle, setContainerTitle] = useState("");

  const columns: Array<Column<ILicense>> = useMemo(
    () => CompanyAdminLicensesColumns(),
    []
  );

  useEffect(() => {
    UpdateLicenses();
  }, []);

  useEffect(() => {
    GetCompanyNameByContactId().then((name) =>
      setContainerTitle(`Licenses of ${name}`)
    );
  }, []);

  function UpdateLicenses() {
    GetUserCompanyLicenses()
      .then((response) => {
        setLicenses(response.length > 0 ? response : []);
      })
      .catch((error) => console.error(error));
  }
 

  return (
    <div>
      <AppContainer name={containerTitle}>
        <div className="panel">
          <div className="panel-body">
            <h3>Ground Mount</h3>
              {Table(columns, licenses.filter(license => license.productTypeId === 0))}
            <h3>Roof Mount</h3>
              {Table(columns, licenses.filter(license => license.productTypeId === 1))}
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default CompanyAdminLicenses;
