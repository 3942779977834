import React from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function ConfirmAlert(message: string, onConfirm: Function, onReject: Function = () => {}) {
  confirmAlert({
    customUI: ({ onClose }) => {
      return (
        <div className='confirm-alert'>
          <p>{message}</p>
          <button autoFocus onClick={() => {
            onClose();
            onConfirm();
          }}>Yes</button>
          <button onClick={() => { 
            onClose(); 
            onReject();
          }}>No</button>
        </div>
      );
    }
  });
}

export default ConfirmAlert;
