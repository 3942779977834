import axios from "axios";
import IContact from "../components/app/clients/IContact";
import { getToken } from "../components/authentication/AuthenticationService";
import UserRole from "../components/authentication/UserRole";
import { API_URL } from "../Constants";
import IBasicResponse from "./IBasicResponse";

export function ChangeUserRole(contactId: string, userRole: UserRole) {
  return axios.post<IBasicResponse>(
    `${API_URL}/Contact/ChangeUserRole`,
    { contactId: contactId, userRole: userRole },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function GetUserCompanyContacts() {
  return axios.get<IContact[]>(API_URL + "/Contact/GetUserCompanyContacts", {
    headers: {
      Authorization: "Bearer " + getToken().toString(),
      "Content-Type": "application/json",
    },
  });
}

export function GetCompanyContacts(clientId: string) {
  if (clientId === "") {
    return GetUserCompanyContacts();
  }

  return axios.get<IContact[]>(
    API_URL + `/Contact/GetContactsByCompanyId/${clientId}`,
    {
      headers: { Authorization: "Bearer " + getToken().toString() },
    }
  );
}

export function IsInSameCompany(companyId: string) {
  return axios
    .get<boolean>(API_URL + `/Contact/IsInSameCompany/${companyId}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      return response.data;
    });
}
