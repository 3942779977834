import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { Button } from "reactstrap";
import { nameof } from "ts-simple-nameof";
import { CSVLink } from "react-csv";
import ISyncLog from "./ISyncLog";
import { GetSyncLogs } from "../../../api/LogsApi";
import { GetDateWithTime } from "../../DateHelpers";
import AppContainer from "../container/AppContainer";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function Columns(): Array<Column<ISyncLog>> {
  return [
    {
      Header: "Date",
      accessor: nameof<ISyncLog>((log) => log.startDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
    },
    {
      Header: "Type",
      accessor: nameof<ISyncLog>((log) => log.type),
    },
    {
      Header: "Record ID",
      accessor: nameof<ISyncLog>((log) => log.primaryKey),
    },
    {
      Header: "Message",
      accessor: nameof<ISyncLog>((log) => log.message),
    },
    {
      Header: "Error Message",
      accessor: nameof<ISyncLog>((log) => log.errorMessage),
    },
  ];
}

function SyncLogs() {
  usePageTimeLogger("Sync log (Page)");

  const { date } = useParams<{ date: string }>();

  const [logs, setLogs] = useState(Array<ISyncLog>());

  useEffect(() => {
    GetSyncLogs(date)
      .then(setLogs)
      .catch((error) => console.error(error));
  }, [date]);

  const columns = useMemo(() => Columns(), []);

  const tableInstance = useTable({
    columns: columns,
    data: logs,
  },
    useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  const csvHeaders = [
    { label: "Date", key: nameof<ISyncLog>(log => log.startDate) },
    { label: "Type", key: nameof<ISyncLog>(log => log.type) },
    { label: "Record ID", key: nameof<ISyncLog>(log => log.primaryKey) },
    { label: "Message", key: nameof<ISyncLog>(log => log.message) },
    { label: "Error Message", key: nameof<ISyncLog>(log => log.errorMessage) }
  ];

  return (
    <div>
      <AppContainer name="Salesforce sync log">
        <div className="panel">
          <div className="panel-body">
            <div className="mx-4 pb-4" style={{ textAlign: "right" }}>
              <CSVLink data={logs} headers={csvHeaders} filename={`sync_log_${date}.csv`} hidden={logs.length === 0}>
                <Button 
                  onClick={()=>logLmEvent(EVENTS.EXPORT_TO_CSV,{Value:`sync_log_${date}.csv`})}
                  color="primary">
                  Export to CSV
                </Button>
              </CSVLink>
            </div>

            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        <div className="sorting d-inline-block align-middle">
                          <i
                            onClick={() => column.toggleSortBy(true, false)}
                            className="pli-down"
                          ></i>
                          <i
                            onClick={() => column.toggleSortBy(false, false)}
                            className="pli-up"
                          ></i>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td className={row.original.isSuccessful ? undefined : "bg-light-danger"}
                            {...cell.getCellProps()}
                            style={{
                              color: "#7a878e",
                              verticalAlign: "top",
                              wordWrap: "break-word",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default SyncLogs;
