import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { Button } from "reactstrap";
import { nameof } from "ts-simple-nameof";
import { GetSyncsByDays } from "../../../api/LogsApi";
import { EVENTS, SYNC_LOGS_URL } from "../../../Constants";
import { GetDate } from "../../DateHelpers";
import AppContainer from "../container/AppContainer";
import ISyncsByDays from "./ISyncsByDays";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

function Columns(): Array<Column<ISyncsByDays>> {
  return [
    {
      Header: "Date",
      accessor: nameof<ISyncsByDays>((log) => log.date),
      Cell: ({ value }) => {
        const date = GetDate(value);
        return (
          <Link to={`${SYNC_LOGS_URL}/${date}`}>
            <Button 
              onClick={()=> logLmEvent(EVENTS.CLICK_ON_DATE,{Value:date})} 
              color="secondary">{date}
            </Button>
          </Link>
        );
      },
    },
    {
      Header: "Records synced",
      accessor: nameof<ISyncsByDays>((log) => log.successfulCount),
    },
    {
      Header: "Errors",
      accessor: nameof<ISyncsByDays>((log) => log.errorCount),
    },
  ];
}

function SyncLogsByDays() {
  usePageTimeLogger("Sync logs (Page)");

  const { clientId } = useParams<{ clientId: string }>();

  const [logs, setLogs] = useState(Array<ISyncsByDays>());

  useEffect(() => {
    GetSyncsByDays()
      .then(setLogs)
      .catch((error) => console.error(error));
  }, [clientId]);

  const columns = useMemo(() => Columns(), []);

  const tableInstance = useTable(
    {
      columns: columns,
      data: logs,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  return (
    <div>
      <AppContainer name="Salesforce sync logs">
        <div className="panel">
          <div className="panel-body">
            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        <div className="sorting d-inline-block align-middle">
                          <i
                            onClick={() => column.toggleSortBy(true, false)}
                            className="pli-down"
                          ></i>
                          <i
                            onClick={() => column.toggleSortBy(false, false)}
                            className="pli-up"
                          ></i>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr
                      className={
                        row.original.errorCount > 0 ? "bg-light-danger" : undefined
                      }
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              color: "#7a878e",
                              verticalAlign: "top",
                              wordWrap: "break-word",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default SyncLogsByDays;
