import React, { useRef, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button, Form, FormGroup, Label, Input, Alert } from "reactstrap";
import AuthenticationContainer from "./AuthenticationContainer";
import { useHistory } from "react-router-dom";
import {
  getUserLicensesInformation,
  getUserRole,
  login,
  LoginResult, useLogout,
} from "./AuthenticationService";
import { ALL_LICENSES_URL, COMPANY_LICENSES_URL, EVENTS } from "../../Constants";
import { PropagateLoader } from "react-spinners";
import { IsServerAvailable } from "../../api/MaintenanceApi";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../Constants";
import UserRole from "./UserRole";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { useAuth0 } from "@auth0/auth0-react"


function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertVisible, setAlertVisibility] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [isServerLoading, setIsServerLoading] = useState(true);
  const [isServerAvailable, setIsServerAvailable] = useState(true);
  const reRef = useRef<ReCAPTCHA>(null);
  const logout = useLogout();
  
  useEffect(() => {
    IsServerAvailable().then((availability) => {
      setIsServerAvailable(availability);
      setIsServerLoading(false);
    });
  }, []);

  var { from }: any = location.state ?? { from: ALL_LICENSES_URL };

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setAlertVisibility(false);

    IsServerAvailable()
      .then((response) => {
        if (!response) {
          window.location.reload();
        }
      })
      .catch(() => {
        window.location.reload();
      });

    var reCaptchaToken: string | undefined | null =
      await reRef.current?.executeAsync();
    reRef.current?.reset();

    login(email, password, reCaptchaToken)
      .then((result) => {
        if (result === LoginResult.Success) {
          let role = getUserRole();
          if (role === UserRole.CompanyAdmin){
            history.push(COMPANY_LICENSES_URL);
          }
          else{
            history.push(from);
          }
          
          getUserLicensesInformation();
        } else {
          setAlertMessage(
            result === LoginResult.InvalidCredentials
              ? "Invalid credentials."
              : "Network error."
          );
          setAlertVisibility(true);
        }
      })
      .catch(() => {
        logout()
        window.location.reload();
      })
      .finally(() => 
      {
        logLmEvent(EVENTS.USER_LOGGED_IN)
        setLoading(false)
      });
  };

  if (isServerLoading) {
    return <> </>;
  }

  if (!isServerAvailable) {
    return (
      <AuthenticationContainer>
        <br />
        <br />
        <Label>
          License manager is currently under maintenance. We will be back online
          soon.
        </Label>
      </AuthenticationContainer>
    );
  }

  return (
    <AuthenticationContainer>
      <Alert isOpen={alertVisible} color="danger">
        {alertMessage}
      </Alert>

      <Form onSubmit={submitForm}>
        <FormGroup>
          <Label className="formLabel" for="emailInput">
            Email
          </Label>
          <Input
            required={true}
            className="form-control"
            type="email"
            name="email"
            id="emailInput"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label className="formLabel" for="passwordInput">
            Password
          </Label>
          <Input
            required={true}
            className="form-control"
            type="password"
            name="password"
            id="passwordInput"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </FormGroup>
        <div className="text-center mb-3">
          <Link className="forgot-link formLabel" to="/forgotPassword">
            Forgot password?
          </Link>
        </div>
        <div className="col-xs-12 pad-no w-100 h-100 text-center">
          <PropagateLoader
            color={"#1c3550"}
            loading={isLoading}
            css={" display: inline-block; "}
          />
          <Button className={isLoading ? "d-none" : "btn btn-primary w-100"}>
            Log in
          </Button>
          <div>
            <br />
          </div>
          <div>
            This site is protected by reCAPTCHA and the Google
            <br />
            <a href="https://policies.google.com/privacy">
              {" "}
              Privacy Policy
            </a>{" "}
            and
            <a href="https://policies.google.com/terms">
              {" "}
              Terms of Service
            </a>{" "}
            apply.
          </div>
        </div>
        <ReCAPTCHA sitekey={RECAPTCHA_SITE_KEY!} size="invisible" ref={reRef} />
      </Form>
    </AuthenticationContainer>
  );
}

export default LoginForm;
