import ProgressBar from "@ramonak/react-progress-bar";
import axios from "axios";
import fileDownload from "js-file-download";
import React, { useState } from "react";
import { Button } from "reactstrap";
import { API_URL, EVENTS } from "../../../Constants";
import { getToken } from "../../authentication/AuthenticationService";
import { logLmEvent } from "src/assets/services/EventHandlerService";

export function DownloadBuild(
  buildId: number,
  fileName: string,
  fileVersion : string,
  isButton: boolean,
  isEmphasized : boolean,
  availableForDownload : boolean
) {
  const [isDownloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const buttonText = isEmphasized ? "Download latest "+ fileVersion +" version" : "Download";

  if(!availableForDownload) {
    return <> </>;
  }

  return isDownloading ? (
    <ProgressBar isLabelVisible={false} completed={progress} />
  ) : (
    <Button
      type="button"
      className= {`${isButton ? "btn-" : "link-button "}${isEmphasized ? "info" : "secondary"}` }
      color= {isButton ? undefined : "link"}
      onClick={() => {
        logLmEvent(EVENTS.DOWNLOAD_LINK_CLICKED,{Value:fileName});
        setDownloading(true);
        axios
          .get(`${API_URL}/Builds/Download/${buildId}`, {
            headers: { Authorization: `Bearer ${getToken().toString()}` },
            responseType: "blob",
            onDownloadProgress: (progressEvent) => {
              var percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setProgress(percentCompleted);
            },
          })
          .then((response) => {
            fileDownload(response.data, fileName);
          })
          .finally(() => {
            setDownloading(false);
            setProgress(0);
          });
      }}
    >
      {(isButton ? buttonText : fileName ?? "")}
    </Button>
  );
}