import React from "react";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { ModalFooter } from "reactstrap/lib";
import TextField from "../forms/TextField";

function IpFilterModal(
  modal: boolean,
  toggle: () => void,
  Filter: () => void,
  ipFilter: string,
  setIpFilter: React.Dispatch<React.SetStateAction<string>>
) {
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        className="ipFilterModal"
      >
        <ModalHeader toggle={toggle}>Filter by IP address</ModalHeader>
        <ModalBody>
          {TextField("IP address:", ipFilter, setIpFilter, undefined, filter)}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              filter();
            }}
          >
            Filter
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  function filter() {
    toggle();
    Filter();
  }
}

export default IpFilterModal;
