import React from "react";
import CSS from "csstype";
import { Link, Route } from "react-router-dom";
import {
  ALL_LICENSES_URL,
  BUILDS_GM_URL,
  SETTINGS_URL,
  GROUPS_URL,
  CLIENTS_URL,
  DOWNLOAD_BUILDS_URL,
  LICENSES_URL,
  COMPANY_LICENSES_URL,
  MEMBERS_URL,
  MEMBERS_URL_ALLOWED_ROLES,
  CLIENTS_ALLOWED_ROLES,
  BUILDS_GM_URL_ALLOWED_ROLES,
  GROUPS_URL_ALLOWED_ROLES,
  ALL_LICENSES_URL_ALLOWED_ROLES,
  COMPANY_LICENSES_URL_ALLOWED_ROLES,
  SYNC_LOGS_BY_DAYS_URL,
  SYNC_LOGS_BY_DAYS_URL_ALLOWED_ROLES,
  DOWNLOAD_BUILDS_RM_URL,
  BUILDS_RM_URL_ALLOWED_ROLES,
  BUILDS_RM_URL,
  SURVEYS_URL_ALLOWED_ROLES,
  GM_SURVEYS_URL,
  RM_SURVEYS_URL,
  LICENSES_URL_ALLOWED_ROLES,
} from "../../../Constants";
import UserRole from "../../authentication/UserRole";
import {
  getUserLicensesInformation,
  getUserRole,
} from "../../authentication/AuthenticationService";

const ScrollbarStyle: CSS.Properties = {
  right: "-17px",
};

function NavigationContainer() {
  const userRole = getUserRole();
  return (
    <nav id="mainnav-container">
      <div id="mainnav">
        <div id="mainnav-menu-wrap">
          <div className="nano has-scrollbar">
            <div className="nano-content" tabIndex={0} style={ScrollbarStyle}>
              <ul id="mainnav-menu" className="list-group">
                <li className="list-header">Navigation</li>
                {IsAllowed(userRole, LICENSES_URL_ALLOWED_ROLES) &&
                  NavigationLink(
                    "My license", 
                    LICENSES_URL, 
                    "pli-diploma-2"
                    )}
                {IsAllowed(userRole, COMPANY_LICENSES_URL_ALLOWED_ROLES) &&
                  NavigationLink(
                    "Licenses",
                    COMPANY_LICENSES_URL,
                    "pli-diploma-2"
                  )}
                {IsAllowed(userRole, MEMBERS_URL_ALLOWED_ROLES) &&
                  NavigationLink("Members", MEMBERS_URL, "pli-add-user-star")}
                {IsAllowed(userRole, CLIENTS_ALLOWED_ROLES) &&
                  NavigationLink("Clients", CLIENTS_URL, "pli-business-man")}
                {IsAllowed(userRole, BUILDS_GM_URL_ALLOWED_ROLES) &&
                  NavigationLink("GM Builds", BUILDS_GM_URL, "pli-hammer")}
                {IsAllowed(userRole, BUILDS_RM_URL_ALLOWED_ROLES) &&
                  NavigationLink("RM Builds", BUILDS_RM_URL, "pli-hammer")}
                {IsCorrectProductType(0) &&
                  IsNotEducationalLicense() &&
                  NavigationLink(
                    "GM Application versions",
                    DOWNLOAD_BUILDS_URL,
                    "pli-download-from-cloud"
                  )}
                {IsCorrectProductType(1) &&
                  IsNotEducationalLicense() &&
                  NavigationLink(
                    "RM Application versions",
                    DOWNLOAD_BUILDS_RM_URL,
                    "pli-download-from-cloud"
                  )}
                {IsAllowed(userRole, GROUPS_URL_ALLOWED_ROLES) &&
                  NavigationLink("Groups", GROUPS_URL, "pli-business-mens")}
                {IsAllowed(userRole, ALL_LICENSES_URL_ALLOWED_ROLES) &&
                  NavigationLink(
                    "All licenses",
                    ALL_LICENSES_URL,
                    "pli-diploma-2"
                  )}
                {NavigationLink("Settings", SETTINGS_URL, "pli-gear")}
                {IsAllowed(userRole, SYNC_LOGS_BY_DAYS_URL_ALLOWED_ROLES) &&
                  NavigationLink(
                    "Sync logs",
                    SYNC_LOGS_BY_DAYS_URL,
                    "pli-note"
                  )}
                  
                {IsAllowed(userRole, SURVEYS_URL_ALLOWED_ROLES) &&
                  NavigationLink("GM surveys", GM_SURVEYS_URL, "pli-question")}
                
                {IsAllowed(userRole, SURVEYS_URL_ALLOWED_ROLES) &&
                  NavigationLink("RM surveys", RM_SURVEYS_URL, "pli-question")}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

function NavigationLink(pageName: string, url: string, icon: string) {
  return (
    <Route path={url}>
      {({ match }) => (
        <li className={match ? "active-link" : undefined}>
          <Link to={url}>
            <i className={icon}></i>
            <span className="menu-title">{pageName}</span>
          </Link>
        </li>
      )}
    </Route>
  );
}

function IsAllowed(userRole: UserRole, allowedRoles: UserRole[]): boolean {
  return allowedRoles.some((role) => role === userRole);
}

function IsCorrectProductType(productType: number) {
  var userInformation = getUserLicensesInformation();

  return (
    userInformation !== null &&
    userInformation.some((info) => info.ProductTypeId === productType)
  );
}

function IsNotEducationalLicense() {
  var userInformation = getUserLicensesInformation();

  return (
    userInformation !== null &&
    !userInformation.some((info) => info.LicenseType === "Educational")
  );
}

export default NavigationContainer;
