import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { GetChildCompanies, GetCompanyById } from "../../../../api/CompanyApi";
import { CLIENTS_MANAGE_URL } from "../../../../Constants";
import { GetDateWithTimeSort } from "../../../SortHelper";
import ClientColumns from "../ClientColumns";
import IClient from "./../IClient";

type ClientProp = {
  clientId: string;
};

function ClientChildCompanies(clientProp: ClientProp) {
  const history = useHistory();
  function ManageClient(clientId: string) {
    history.push(`${CLIENTS_MANAGE_URL}/${clientId}`);
  }

  const [childClients, setChildClients] = useState(Array<IClient>());
  const dateWithTimeSort = React.useMemo(() => GetDateWithTimeSort(), []);
  const columns: Array<Column<IClient>> = useMemo(
    () => ClientColumns(dateWithTimeSort, ManageClient),
    // eslint-disable-next-line
    [dateWithTimeSort]
  );

  const tableInstance = useTable(
    {
      columns: columns,
      data: childClients,
      initialState: { pageIndex: 0, pageSize: 20 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  useEffect(() => {
    GetChildCompanies(clientProp.clientId)
      .then((companies) => {
        setChildClients(companies);
      })
      .catch((error) => console.error(error));
  }, [clientProp]);

  return (
    <>
      {childClients.length === 0 ? (
        <></>
      ) : (
        <div className="panel">
          <legend className="col-sm-2 col-form-label required">
            {"    Child companies"}
          </legend>
          <div className="panel-body">
            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.render("Header")}
                          {column.Header !== "Actions" && (
                            <div className="sorting d-inline-block align-middle">
                              <i
                                onClick={() => column.toggleSortBy(true, false)}
                                className="pli-down"
                              ></i>
                              <i
                                onClick={() =>
                                  column.toggleSortBy(false, false)
                                }
                                className="pli-up"
                              ></i>
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ color: "#7a878e" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>{" "}
          </div>
          <div />
        </div>
      )}
    </>
  );
}

export default ClientChildCompanies;
