import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Alert, Button, Form, FormGroup, Input, Label } from "reactstrap";
import { ChangeUserPassword } from "../../api/UserApi";
import { maxPasswordLength, minPasswordLength, PASSWORD_REQUIREMENTS } from "../../Constants";
import AuthenticationContainer from "./AuthenticationContainer";
import {useLogout} from "./AuthenticationService";

function ChangePassword() {
  const { token } = useParams<{ token: string }>();

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");

  const [buttonDisabled, setButtonDisabled] = useState(false);

  const history = useHistory();
  const logout = useLogout();

  function SubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (newPassword !== repeatNewPassword) {
      setAlertText("Passwords do not match.");
      setAlertColor("danger");
      setAlertVisible(true);
      return;
    }

    ChangeUserPassword(token, newPassword)
      .then(() => {
        setAlertText(
          "Password was changed successfully.\r\nYou will be redirected to Login page."
        );
        setAlertColor("info");
        setAlertVisible(true);
        setButtonDisabled(true);

        logout();
        setTimeout(() => history.push("/"), 3000);
      })
      .catch((error) => {
        if(error?.response?.status === 500){
          setAlertText("Internal server error. Try again later.")
        }else{
          setAlertText(error?.response?.data?.errors?.Password?.join('\n') ?? error?.response?.data ?? error?.message);
        }
        console.log(error);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }

  const isRequirementMet = (requirement: typeof PASSWORD_REQUIREMENTS[number]) => {
    return requirement.metRegex.test(newPassword);
  };

  return (
    <AuthenticationContainer>
      <Alert isOpen={alertVisible} color={alertColor}>
        <div className="new-line">{alertText}</div>
      </Alert>

      <Form onSubmit={SubmitForm}>
        <FormGroup>
          <Label className="required">New password</Label>
          <Input
            className="form-control"
            type={"password"}
            required={true}
            minLength={minPasswordLength}
            maxLength={maxPasswordLength}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
            <div>
              <ul>
                {PASSWORD_REQUIREMENTS.map((requirement) => (
                  <li key={requirement.id} style={{ color: isRequirementMet(requirement) ? "green" : "black" }}>
                    {requirement.label}
                  </li>
                ))}
              </ul>
            </div>
        </FormGroup>
        <FormGroup>
          <Label className="required">Repeat new password</Label>
          <Input
            className="form-control"
            type={"password"}
            required={true}
            minLength={minPasswordLength}
            maxLength={maxPasswordLength}
            onChange={(e) => {
              setRepeatNewPassword(e.target.value);
            }}
          />
        </FormGroup>
        <Button
          className="btn btn-primary w-100 mt-3"
          disabled={buttonDisabled}
        >
          Change password
        </Button>
      </Form>
    </AuthenticationContainer>
  );
}

export default ChangePassword;
