import { Row } from "react-table";
import IContact from "./app/clients/IContact";
import UserRole from "./authentication/UserRole";

export function GetDateWithTimeSort(): (
  rowA: Row<any>,
  rowB: Row<any>,
  id: string
) => 1 | -1 | 0 {
  const dateWithTimeSort = (rowA: Row<any>, rowB: Row<any>, id: string) => {
    if (typeof rowA.original[id] == "undefined") {
      return 1;
    }
    if (typeof rowB.original[id] == "undefined") {
      return -1;
    }

    if (rowA.original[id] > rowB.original[id]) return -1;
    if (rowB.original[id] > rowA.original[id]) return 1;
    return 0;
  };
  return dateWithTimeSort;
}

export function GetEnumSort(): (
  rowA: Row<IContact>,
  rowB: Row<IContact>,
  id: string
) => 1 | -1 | 0 {
  const nameSort = (rowA: Row<IContact>, rowB: Row<IContact>, id: string) => {
    let first = UserRole[rowA.original.userRole];
    let second = UserRole[rowB.original.userRole];

    if (first === second) {
      if (first > second) return -1;
      if (second > first) return 1;
    }
    if (first > second) return -1;
    if (second > first) return 1;
    return 0;
  };
  return nameSort;
}

export function GetNamesSort(): (
  rowA: Row<IContact>,
  rowB: Row<IContact>,
  id: string
) => 1 | -1 | 0 {
  const nameSort = (rowA: Row<IContact>, rowB: Row<IContact>, id: string) => {
    if (rowA.original.name === rowB.original.name) {
      if (rowA.original.surname > rowB.original.surname) return -1;
      if (rowB.original.surname > rowA.original.surname) return 1;
    }
    if (rowA.original.name > rowB.original.name) return -1;
    if (rowB.original.name > rowA.original.name) return 1;
    return 0;
  };
  return nameSort;
}
