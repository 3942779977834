import React from "react";
import { Form, Button } from "reactstrap";
import TextField from "../forms/TextField";
import AppContainer from "../container/AppContainer";
import { useState } from "react";
import { Alert } from "reactstrap";
import { AddCompany } from "../../../api/CompanyApi";
import { useHistory } from "react-router";
import { CLIENTS_MANAGE_URL, EVENTS } from "../../../Constants";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

function CreateCompany() {
  usePageTimeLogger("Create company (Page)");

  const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
  const [isAlertVisibile, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  const [name, setName] = useState("");

  const history = useHistory();

  function Save() {
    setSaveButtonDisabled(true);
    AddCompany(name.trim())
      .then((company) => {
        setAlertText("Company was created successfully.");
        setAlertVisible(true);
        setAlertColor("info");
        logLmEvent(EVENTS.SAVE)
        history.push(CLIENTS_MANAGE_URL + "/" + company.id);
      })
      .catch((error) => {
        setAlertVisible(true);
        setAlertColor("danger");
        setSaveButtonDisabled(false);
        setAlertText(error.response.data.errorMessage ?? "Network error");
      });
  }

  return (
    <AppContainer name="New company">
      <Alert color={alertColor} isOpen={isAlertVisibile}>
        {alertText}
      </Alert>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          Save();
        }}
      >
        <div className="panel">
          <div className="panel-body">
            {TextField(
              "Name",
              name,
              setName,
              EVENTS.ENTER_NAME,
              undefined,
              undefined,
              "text",
              true,
              1,
              200
            )}
          </div>
          <div className="panel-footer mx-4 pb-4">
            <Button
              className="btn btn-primary" 
              disabled={saveButtonDisabled}>
                Save
            </Button>
          </div>
        </div>
      </Form>
    </AppContainer>
  );
}

export default CreateCompany;
