import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {getUserRole, isAuthorized} from './AuthenticationService';
import { useAuth0 } from "@auth0/auth0-react";
import { API_URL } from "../../Constants"
import axios, { AxiosResponse } from "axios";
import UserRole from "./UserRole";

const AuthCallback = () => {
    const history = useHistory();
    const [, setCookie] = useCookies(['jwt']);
    const {
        isAuthenticated,
        getAccessTokenSilently,
        logout,
        isLoading,
        user
    } = useAuth0();

    useEffect(() => {
        const handleAuth0 = async () => {
            if (isLoading) {
                return;
            }

            if (!isAuthenticated || !user) {
                history.push('/');
                return;
            }

            try {
                const auth0Token = await getAccessTokenSilently();

                try {
                    const response = await axios.post(
                        `${API_URL}/Authentication/Auth0Authorize`,
                        null,
                        {
                            headers: { Authorization: `Bearer ${auth0Token}` },
                        },
                    );

                    if (response.status !== 200) {
                        await logout();
                        history.push('/');
                        return;
                    }

                    setCookie('jwt', response.data.token, { path: '/', sameSite: "strict" });

                    let redirectPath = '/';
                    if (await isAuthorized()) {
                        const userRole = getUserRole();
                        redirectPath = userRole === UserRole.CompanyAdmin
                            ? '/companyLicenses'
                            : '/licenses';
                    }

                    history.push(redirectPath);
                } catch (error) {
                    history.push('/');
                }
            } catch (error) {
                history.push('/');
            }
        };

        handleAuth0();
    }, [isLoading, isAuthenticated, user, history, setCookie, getAccessTokenSilently, logout]);

    // Don't render anything while loading
    return null;
};

export default AuthCallback;