import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {getUserRole, isAuthorized} from './AuthenticationService';
import UserRole from "./UserRole";

const Callback = () => {
    const history = useHistory();
    const location = useLocation();
    const [, setCookie] = useCookies(['jwt']);

    useEffect(() => {
        const handleCallback = async () => {
            const searchParams = new URLSearchParams(location.search);
            const token = searchParams.get('key');

            if (!token) {
                history.push('/');
                return;
            }

            setCookie('jwt', token, {path: '/', sameSite: "strict"});

            let redirectPath = '/';
            if (await isAuthorized()) {
                const userRole = getUserRole();
                redirectPath = userRole === UserRole.CompanyAdmin
                    ? '/companyLicenses'
                    : '/licenses';
            }
            history.push(redirectPath);
        };

        handleCallback();
    }, [history, location.search, setCookie]);

    return null;
};

export default Callback;