import axios from "axios";
import IGroup from "../components/app/groups/IGroup";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function GetUserGroups() {
  return axios
    .get<Array<IGroup>>(`${API_URL}/UserGroups/GetAllUserGroups`, {
      headers: { Authorization: `Bearer ${getToken().toString()}` },
    })
    .then((response) => {
      return response.data;
    });
}
