import React, { useEffect } from "react";
import { Form, Button, Label, CustomInput } from "reactstrap";
import TextField from "../forms/TextField";
import AppContainer from "../container/AppContainer";
import { useState } from "react";
import { Alert } from "reactstrap";
import { GetUser, UpdateUser } from "../../../api/UserApi";
import { EVENTS, maxPasswordLength, minPasswordLength, PASSWORD_REQUIREMENTS } from "../../../Constants";
import TextFieldReadOnly from "../forms/TextFieldReadOnly";
import { getCookie, setCookie } from "../helpers/Cookies";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";
import CookiesModal from "../modals/Cookies";

function Settings() {
  usePageTimeLogger("Settings (Page)");
  const trackingVariable = 'isTrackingAccepted';

  const [isAlertVisibile, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  const [email, setEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [isTrackingAccepted, setTrackingState] = useState<boolean>(getCookie(trackingVariable) === 'true');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (isOpen?: boolean) => {
    setIsModalOpen(isOpen ?? !isModalOpen);
  };

  const handleTrackingConsentChange = (consent: boolean) => {
    setTrackingState(consent);
    setCookie(trackingVariable, consent.toString(), 365);
    toggleModal(false);
  };

  const handleConsentTracking = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      handleTrackingConsentChange(false);
    } else {
      toggleModal(true);
    }
  };

  useEffect(() => {
    GetUser()
      .then((response) => {
        setEmail(response.data.email);
      })
      .catch((error) => {
        setAlertText(error.message);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }, []);

  function Save() {
    if (newPassword !== repeatNewPassword) {
      setAlertText("Passwords do not match.");
      setAlertColor("danger");
      setAlertVisible(true);
      return;
    }
    if (oldPassword === newPassword) {
      setAlertText("Old password and new password cannot be the same.");
      setAlertColor("danger");
      setAlertVisible(true);
      return;
    } 

    UpdateUser(oldPassword, newPassword)
      .then(() => {
        logLmEvent(EVENTS.CHANGE_PASSWORD);
        setAlertText("Update successful.");
        setAlertColor("info");
        setAlertVisible(true);
      })
      .catch((error) => {
        setAlertText(error?.response?.data?.errors?.NewPassword?.join('\n') ?? error?.response?.data ?? error?.message);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }

  const handlePasswordChange = (event: React.SetStateAction<string>) => {
    setNewPassword(event);
  };

  const isRequirementMet = (requirement: typeof PASSWORD_REQUIREMENTS[number]) => {
    return requirement.metRegex.test(newPassword);
  };

  return (
    <AppContainer name="Your settings">
      <Alert color={alertColor} isOpen={isAlertVisibile}>
        {alertText}
      </Alert>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          Save();
        }}
      >
        <div className="panel">
          <div className="panel-body">
            {TextFieldReadOnly("Email", email)}
            {TextField(
              "Old password",
              oldPassword,
              setOldPassword,
              undefined,
              undefined,
              undefined,
              "password",
              true
            )}
            {TextField(
              "New password",
              newPassword,
              handlePasswordChange,
              undefined,
              undefined,
              undefined,
              "password",
              true,
              minPasswordLength,
              maxPasswordLength
            )}
            <div>
              <ul>
                {PASSWORD_REQUIREMENTS.map((requirement) => (
                  <li key={requirement.id} style={{ color: isRequirementMet(requirement) ? "green" : "black" }}>
                    {requirement.label}
                  </li>
                ))}
              </ul>
            </div>
            {TextField(
              "Repeat new password",
              repeatNewPassword,
              setRepeatNewPassword,
              undefined,
              undefined,
              undefined,
              "password",
              true,
              minPasswordLength,
              maxPasswordLength
            )}
          </div>
          <div className="panel-footer mx-4 pb-4">
            <Button className="btn btn-primary">Save</Button>
          </div>
        </div>
      </Form>
      <div className="panel p-2">
        <Label className="h6">
          Consent tracking
        </Label>
        <CustomInput
          className="mt-2"
          type="switch"
          id="isTrackingAcceptedSwitch"
          label="Is accepted"
          checked={isTrackingAccepted}
          onChange={(e) => handleConsentTracking(e)}
        />
      </div>
      <CookiesModal isOpenProp={isModalOpen} toggle={toggleModal} onConsentChange={handleTrackingConsentChange} />
    </AppContainer>
  );
}

export default Settings;
