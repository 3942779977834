import React, { useEffect, useMemo, useState } from "react";
import IBuild from "./IBuild";
import AppContainer from "../container/AppContainer";
import { useTable, usePagination } from "react-table";
import { CustomTable } from "../helpers/CreateTableBody";
import CustomPagination from "../CustomPagination";
import GetBuildTableColumns from "./BuildTableColumns";
import GetBuildsByProductType from "src/api/BuildsApi";
import { GetIsNotSuspendedLicense } from "../../authentication/AuthenticationService"
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function DownloadBuilds() {
  usePageTimeLogger("GM application versions (Page)");

  useEffect(() => {
    GetBuildsByProductType(0, false)
      .then((builds) => {
        const availableBuilds = builds.filter(
          (build) => build.availableForDownload
        );
        setGmData(availableBuilds);
      })
      .catch((error) => console.log(error));
  }, []);

  // eslint-disable-next-line
  const columns = useMemo(() => GetBuildTableColumns(GetIsNotSuspendedLicense(0)), []);
  const [gmData, setGmData] = useState(Array<IBuild>());
    // eslint-disable-next-line
    const tableInstance = useTable({ columns: columns, data:gmData , initialState: { pageIndex: 0, pageSize: 20 }}, usePagination)
    const { getTableBodyProps, getTableProps, headerGroups, prepareRow, page, pageOptions,
      state: { pageIndex } } = tableInstance;

  return (
    <AppContainer name="Application versions GM">
      <div className="panel">
        <div className="panel-body">
        {CustomTable(getTableProps, headerGroups, page, prepareRow, getTableBodyProps, "Theres no downloads at the moment")}
        {CustomPagination(pageOptions, pageIndex, tableInstance)}
        </div>
      </div>
    </AppContainer>
  );
}

export default DownloadBuilds;
