import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Column, useSortBy, useTable } from "react-table";
import { Button } from "reactstrap";
import { nameof } from "ts-simple-nameof";
import { GetLicenseTransferLogs } from "../../../../api/LicensesApi";
import { GetDateWithTime } from "../../../DateHelpers";
import AppContainer from "../../container/AppContainer";
import ILicenseTransferLog from "./ILicenseTranferLog";
import { CSVLink } from "react-csv";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function Columns(): Array<Column<ILicenseTransferLog>> {
  return [
    {
      Header: "License Id",
      accessor: nameof<ILicenseTransferLog>((log) => log.licenseId),
    },
    {
      Header: "Transfer date",
      accessor: nameof<ILicenseTransferLog>((log) => log.transferDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
    },
    {
      Header: "From contact",
      accessor: nameof<ILicenseTransferLog>((log) => log.fromContact),
    },
    {
      Header: "To contact",
      accessor: nameof<ILicenseTransferLog>((log) => log.toContact),
    },
  ];
}

function LicenseTransferLog() {
  usePageTimeLogger("License transfer log (Page)");

  const { clientId } = useParams<{ clientId: string; companyName: string }>();
  const history = useHistory();

  const [logs, setLogs] = useState(Array<ILicenseTransferLog>());

  useEffect(() => {
    GetLicenseTransferLogs(clientId)
      .then(setLogs)
      .catch((error) => console.error(error));
  }, [clientId]);

  const columns = useMemo(() => Columns(), []);

  const tableInstance = useTable(
    {
      columns: columns,
      data: logs,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  const csvHeaders = [
    {
      label: "License ID",
      key: nameof<ILicenseTransferLog>((log) => log.licenseId),
    },
    {
      label: "Transfer Date",
      key: nameof<ILicenseTransferLog>((log) => log.transferDate),
    },
    {
      label: "From contact",
      key: nameof<ILicenseTransferLog>((log) => log.fromContact),
    },
    {
      label: "To contact",
      key: nameof<ILicenseTransferLog>((log) => log.toContact),
    },
  ];

  return (
    <div>
      <AppContainer name="License transfer log">
        <Button
          onClick={() => history.goBack()}
          className="btn btn-outline-secondary"
          style={{ marginBottom: "2rem" }}
        >
          Back
        </Button>
        <div className="panel">
          <div className="panel-body">
            <div className="mx-4 pb-4" style={{ textAlign: "right" }}>
              <CSVLink
                data={logs}
                headers={csvHeaders}
                filename="license_transfer_log.csv"
                hidden={logs.length === 0}
              >
                <Button color="primary">Export to CSV</Button>
              </CSVLink>
            </div>

            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        <div className="sorting d-inline-block align-middle">
                          <i
                            onClick={() => column.toggleSortBy(true, false)}
                            className="pli-down"
                          ></i>
                          <i
                            onClick={() => column.toggleSortBy(false, false)}
                            className="pli-up"
                          ></i>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              color: "#7a878e",
                              verticalAlign: "top",
                              wordWrap: "break-word",
                            }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default LicenseTransferLog;
