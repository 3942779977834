import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ISurveyChoiceQuestion } from "../ISurveyQuestions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ChoiceResults(props) {
  const question: ISurveyChoiceQuestion = props.question;
  const [data, setData] = useState<any>({
    labels: Array<any>(),
    datasets: [
      {
        id: 1,
        label: "",
        backgroundColor: ["blue"],
        data: Array<any>(),
      },
    ],
  });

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      xAxis: {
        suggestedMax: 10,
      },
    },
    plugins: {
      legend: {
        display: false
      }
    },
    title: {display: false},
    responsive: true,
  };

  useEffect(() => {
    const currentData = {
      labels: Array<any>(),
      datasets: [
        {
          id: 1,
          label: "",
          data: Array<any>(),
        },
      ],
    };

    const answerOptions = question.answerOptions.split(";");

    answerOptions.forEach((element) => {
      let filtered = question.choiceQuestionAnswers?.filter((answer) => {
        return answer.answer.split(';').some((answerOption) => {return answerOption.trim() === element})
      }) ?? [];
      currentData.labels.push(element);
      currentData.datasets[0].data.push(filtered.length);
    });

    setData(currentData);
  }, [question]);

  return (
    <div
    style={{
      borderTop: "1px solid black",
      padding: "5px 0 5px 0",
      display: "flex",
    }}
  >
    <div
      style={{
        padding: "20px",
        borderRight: "1px solid lightgray",
        minWidth: "130px",
      }}
    >
      <div>
        Choice
      </div>
      <div>
        Multiple choices: {question.isMultipleAnswers ? 'YES' : 'NO'}
      </div>
    </div>
    <div style={{ paddingLeft: "20px", minWidth: '50%' }}>
      <h4>{question.question}</h4>
      <Bar options={options} data={data} />
    </div>
  </div>
  );
}

export default ChoiceResults;
