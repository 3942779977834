import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const UnauthorizedInterceptor = () => {
    const { logout } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            response => response,
            async error => {
                if (error.response &&
                    error.response.status === 401 && window.location.pathname !== "/") {
                    await logout();
                    history.push('/');
                    window.location.reload();
                }
                return Promise.reject(error);
            }
        );
        
        return () => {
            axios.interceptors.response.eject(interceptor);
        };
    }, [logout, history]);
    
    return null;
};

export default UnauthorizedInterceptor;