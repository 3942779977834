import Build from "./Build";
import { useParams } from "react-router-dom";
import { EDIT_BUILD_URL } from "src/Constants";


function EditBuild() {
    const {buildId} = useParams<{ buildId : string}>();
    return Build({ buildId: Number(buildId), pageName: "Edit build", url : EDIT_BUILD_URL });
}

export default EditBuild;