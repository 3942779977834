import axios from "axios";
import ISyncLog from "../components/app/syncLogs/ISyncLog";
import ISyncByDays from "../components/app/syncLogs/ISyncsByDays";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";
import IPaginatedResult from "src/components/app/IPaginatedResult";

export function GetSyncLogs(date: string) {
  return axios
    .get<ISyncLog[]>(API_URL + "/SyncLog/GetLogs/" + date, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data.length > 0 ? response.data : [];
    });
}

export function GetSyncsByDays() {
  return axios
    .get<IPaginatedResult<ISyncByDays>>(API_URL + "/SyncLog/GetDays", {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data.items.length > 0 ? response.data.items : [];
    });
}
