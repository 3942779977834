import axios from "axios";
import IUser from "../components/app/user/IUser";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function GetUser() {
  return axios.get<IUser>(`${API_URL}/User/GetUser`, {
    headers: { Authorization: `Bearer ${getToken().toString()}` },
  });
}

export function UpdateUser(
  oldPassword: string,
  newPassword: string
) {
  return axios.put(
    API_URL + "/User/UpdateUser",
    {
      oldPassword: oldPassword,
      newPassword: newPassword,
    },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function SoftDeleteUser(
  userId: string
) {
  return axios.put(
    API_URL + "/User/SoftDeleteUser",
    {
      userId: userId
    },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function SendForgotPassword(email: string) {
  return axios.post(
    API_URL + "/User/ForgotPassword",
    {
      email: email,
    },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function ChangeUserPassword(token: string, newPassword: string) {
  return axios.post(
    API_URL + "/User/ChangePassword",
    {
      token: token,
      password: newPassword,
    },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function ResetPasswordAsCompanyAdmin(contactId: string) {
  return axios.post(
    API_URL + "/Contact/ResetPasswordAsCompanyAdmin",
    {
      contactId: contactId,
    },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}
