import React, { useState, useRef, useEffect } from "react";
import { ISurveyRatingQuestion } from "../ISurveyQuestions";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import QuestionTypes from "../questionTypes";
import { Button } from "reactstrap";
import TextField from "../../forms/TextField";

function RatingQuestion(props) {
  const question: ISurveyRatingQuestion = props.question;
  const questions = props.questions;
  const [title, setTitle] = useState(question.question);
  const [fromText, setFromText] = useState(question.fromText);
  const [toText, setToText] = useState(question.toText);
  const [from, setFrom] = useState(question.from);
  const [to, setTo] = useState(question.to);
  const setQuestions = props.setQuestions;
  const updateQuestion = props.updateQuestion;

  useEffect(() => {
    updateValue();
  }, [from, to, fromText, toText, title]);

  function updateSlider(values) {
    setFrom(values[0]);
    setTo(values[1]);
  }

  const updateValue = () => {
    questions.splice(props.index, 1, {
      id: question.id,
      question: title,
      fromText: fromText,
      toText: toText,
      from: from,
      to: to,
      sequenceNumber: props.index 
    });
    setQuestions(questions);
    updateQuestion({ type: QuestionTypes.Rating, id: question.id });
  };

  return (
    <div
      style={{
        borderTop: "1px solid black",
        padding: "5px 0 5px 0",
        display: "flex",
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRight: "1px solid lightgray",
          minWidth: "65px",
        }}
      >
        Rating
      </div>
      <div style={{ paddingLeft: "20px" }}>
        {TextField(
          "Question",
          title,
          setTitle,
          undefined,
          undefined,
          undefined,
          "text",
          true,
          1,
          300
        )}
        <div
          style={{ display: "flex", flexDirection: "column", minWidth: "80%" }}
        >
          <div style={{ display: "flex" }}>
            {TextField(
              "From text",
              fromText,
              setFromText,
              undefined,
              undefined,
              undefined,
              "text",
              true,
              1,
              200
            )}
            {TextField(
              "To text",
              toText,
              setToText,
              undefined,
              undefined,
              undefined,
              "text",
              true,
              1,
              200
            )}
          </div>
          <div style={{ maxWidth: "93%", marginTop: "10px" }}>
            <Slider
              range
              dots
              step={1}
              min={1}
              max={10}
              defaultValue={[from, to]}
              onAfterChange={(e) => updateSlider(e)}
            />
          </div>
        </div>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          onClick={() => {
            props.removeQuestion({
              type: QuestionTypes.Rating,
              id: question.id,
              index: props.index,
            });
          }}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

export default RatingQuestion;
