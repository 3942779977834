import axios from "axios";
import { API_URL } from "../Constants";

export function IsServerAvailable() {
  const source = axios.CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, 10000);

  return axios
    .get<boolean>(`${API_URL}/Maintenance/IsServerAvailable`, {
      cancelToken: source.token,
    })
    .then((response) => {
      return response.status === 200;
    })
    .catch(() => {
      return false;
    });
}
