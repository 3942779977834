import React from "react";
import WithChildren from "../../WithChildren";

type PageName = WithChildren<{
  pageName: string;
}>;

function ContentContainer({ pageName, children }: PageName) {
  return (
    <>
      <div id="page-head">
        <div id="page-title">
          <h1 className="page-header text-overflow">{pageName}</h1>
        </div>
      </div>
      <div id="page-content">
        {children}
      </div>
    </>
  );
}

export default ContentContainer;
