import React, { useEffect } from "react";
import { getUserEmail } from "../../authentication/AuthenticationService";
import WithChildren from "../../WithChildren";
import ContentContainer from "./ContentContainer";
import HeaderContainer from "./HeaderContainer";
import NavigationContainer from "./NavigationContainer";
import HotjarTracking from "../metrics/HotjarTracking";

type AppContainerParams = WithChildren<{
  name: string;
}>;

declare global {
  interface Window {
    InitializeNifty: any;
  }
}

const AppContainer = ({ name, children }: AppContainerParams) => {
  
  const initializeNifty = window.InitializeNifty;
  
  HotjarTracking();

  useEffect(() => {
    initializeNifty();
  }, [initializeNifty]);
  
  return (       
    <div id="container" className="effect aside-float aside-bright mainnav-lg">
        <HeaderContainer email={getUserEmail()}></HeaderContainer>
        <div className="boxed">
          <div id="content-container">
              <ContentContainer pageName={name}>{children}</ContentContainer>
          </div>
        </div>
        <NavigationContainer></NavigationContainer>
      </div>
            
  );
}

export default AppContainer;
