import React from "react";

export function CustomTable(getTableProps, headerGroups, page, prepareRow, getTableBodyProps, errorMessage) {
    if (!page.length) {
      return <p className="font-weight-bold">
       {errorMessage}
      </p>
    }

    return (
        <table
        className="table table-striped table-hover table-vcenter"
        {...getTableProps()}
      >
         <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="text-left"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr 
              {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                            color: "#7a878e",
                            verticalAlign: "top"
                      }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };