import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { ISurveyRatingQuestion } from "../ISurveyQuestions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function RatingResults(props) {
  const question: ISurveyRatingQuestion = props.question;
  const from = question.from;
  const to = question.to;
  const [data, setData] = useState<any>({
    labels: Array<any>(),
    datasets: [
      {
        id: 1,
        label: "",
        backgroundColor: ["blue"],
        data: Array<any>(),
      },
    ],
  });

  const options = {
    indexAxis: "y" as const,
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    scales: {
      xAxis: {
        suggestedMax: 10,
      },
    },
    plugins: {
      legend: {
        display: false
      }
    },
    title: {display: false},
    responsive: true,
  };

  useEffect(() => {
    const currentData = {
      labels: Array<any>(),
      datasets: [
        {
          id: 1,
          label: "",
          data: Array<any>(),
        },
      ],
    };

    for (let i = to; i >= from; i--) {
      let filtered = question.ratingQuestionAnswers?.filter((answer) => {
        return answer.answer === i.toString();
      }) ?? [];
      currentData.labels.push(i.toString());
      currentData.datasets[0].data.push(filtered.length);
    }

    setData(currentData);
  }, [question]);

  return (
    <div
    style={{
      borderTop: "1px solid black",
      padding: "5px 0 5px 0",
      display: "flex",
    }}
  >
    <div
      style={{
        padding: "20px",
        borderRight: "1px solid lightgray",
        minWidth: "130px",
      }}
    >
      Rating
    </div>
    <div style={{ paddingLeft: "20px", minWidth: '50%'}}>
      <h4>{question.question}</h4>
      {question.toText}
      <Bar options={options} data={data} style={{paddingLeft: '50px'}}/>
      {question.fromText}
    </div>
  </div>
  );
}

export default RatingResults;
