import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Alert } from "reactstrap";
import { API_URL } from "../../../../Constants";
import { getToken } from "../../../authentication/AuthenticationService";
import UserRole from "../../../authentication/UserRole";
import {
  GetDateWithTimeSort,
  GetEnumSort,
  GetNamesSort,
} from "../../../SortHelper";
import IContact from "../IContact";
import ClientMembersColumns from "./ClientMembersColumns";
import ChangeUserRoleModal from "./modalWindows/ChangeUserRoleModal";

type ClientsProp = {
  clientId: string;
};

function CompanyMembers(clientProp: ClientsProp) {
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");

  const [modal, setModal] = useState(false);
  const [selectedContactId, setSelectedContactId] = useState("");
  const toggle = () => setModal(!modal);

  const dateWithTimeSort = React.useMemo(() => GetDateWithTimeSort(), []);
  const sortByName = React.useMemo(() => GetNamesSort(), []);
  const sortByEnum = React.useMemo(() => GetEnumSort(), []);
  const [contacts, setContacts] = useState(Array<IContact>());

  const onCopyResetLinkClick = (copyText: string) => {
    navigator.clipboard.writeText(copyText);
  };

  const columns: Array<Column<IContact>> = useMemo(
    () =>
      ClientMembersColumns(
        dateWithTimeSort,
        sortByName,
        sortByEnum,
        OpenChangeUserRoleModal,
        ResetPassword,
        onCopyResetLinkClick
      ),
    // eslint-disable-next-line
    [dateWithTimeSort, sortByName]
  );

  function OpenChangeUserRoleModal(contactId: string) {
    setAlertVisible(false);
    setSelectedContactId(contactId);
    toggle();
  }

  useEffect(() => {
    SetContactsFromAPI(clientProp.clientId, setContacts);
  }, [clientProp.clientId]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: contacts,
    },
    useSortBy
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    tableInstance;

  function ResetPassword(contactId: string) {
    axios
      .post(
        API_URL + "/Contact/ResetPassword",
        {
          contactId: contactId,
        },
        {
          headers: {
            Authorization: "Bearer " + getToken().toString(),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setAlertText("Change password email was sent.");
        setAlertColor("info");
        setAlertVisible(true);
        setContacts((contactList) => {
          return contactList.map((item) => {
            return item.id === contactId
              ? { ...item, passwordChangeLink: res.data.passwordChangeLink }
              : item;
          });
        });
      })
      .catch((error) => {
        setAlertText(error.response?.data ?? error.message);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }

  return (
    <div>
      <Alert isOpen={alertVisible} color={alertColor}>
        {alertText}
      </Alert>
      <div className="panel">
        <div className="panel-body">
          <table
            className="table table-striped table-hover table-vcenter"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="text-left"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      <div>
                        {column.render("Header")}
                        {column.Header !== "Actions" && (
                          <div className="sorting d-inline-block align-middle">
                            <i
                              onClick={() => column.toggleSortBy(true, false)}
                              className="pli-down"
                            ></i>
                            <i
                              onClick={() => column.toggleSortBy(false, false)}
                              className="pli-up"
                            ></i>
                          </div>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);

                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{ color: "#7a878e" }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        {ChangeUserRoleModal(
          setContacts,
          clientProp.clientId,
          modal,
          toggle,
          selectedContactId,
          contacts.find((contact) => contact.id === selectedContactId)
            ?.userRole ?? UserRole.Undefined,
          SetContactsFromAPI,
          setAlertVisible,
          setAlertColor,
          setAlertText
        )}
      </div>
    </div>
  );
}

function SetContactsFromAPI(
  clientId: string,
  setContacts: React.Dispatch<React.SetStateAction<IContact[]>>
) {
  axios
    .get<IContact[]>(API_URL + `/Contact/GetContactsByCompanyId/${clientId}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      if (response.status === 200) {
        setContacts(response.data);
      } else {
        setContacts(Array<IContact>());
      }
    })
    .catch((error) => console.error(error));
}

export default CompanyMembers;
