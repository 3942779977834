import React, { useEffect, useMemo, useState } from "react";
import AppContainer from "../container/AppContainer";
import { Column, useTable, usePagination } from "react-table";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import {
  CREATE_SURVEY_URL,
  EDIT_SURVEY_URL,
  EVENTS,
  SURVEY_RESULTS_URL,
} from "../../../Constants";
import { CustomTable } from "../helpers/CreateTableBody";
import ISurveyListItem from "./ISurveyListItem";
import {
  DeleteSurvey,
  DisableSurvey,
  EnableSurvey,
  GetSurveys,
} from "src/api/SurveyApi";
import CustomPagination from "../CustomPagination";
import ConfirmAlert from "../forms/ConfirmAlert";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

function SurveysRM() {
  usePageTimeLogger("RM Surveys (Page)");

  const tableColumns: Array<Column<ISurveyListItem>> = [
    {
      Header: "Title",
      accessor: "internalTitle",
      Cell: ({ value }) => {
        return <div style={{ maxWidth: 150, wordWrap: "break-word"}}>{value}</div>;
      },
    },
    {
      Header: "Stats",
      Cell: (table: any) => {
        let totalRecipients = table.row.original.stats.uniqueRecipients;
        let seenRecipients = table.row.original.stats.seenUniqueRecipients;
        let answeredRecipients = table.row.original.stats.answeredUniqueRecipients;
        let ignoredRecipients = table.row.original.stats.ignoredUniqueRecipients;
        let totalAnswers = table.row.original.stats.totalAnswers;

        return <div style={{ maxWidth: 600 }}>
          {`Unique recipients: ${totalRecipients} | 
          Seen by: ${seenRecipients} | 
          Answered by: ${answeredRecipients} | 
          Ignored by: ${ignoredRecipients} | 
          Total answers: ${totalAnswers}`}</div>;
      },
    },
    {
      Header: "Results",
      Cell: (table: any) => {
        let rowId = table.row.original.id;
        return (
          <span>
            <Link to={`${SURVEY_RESULTS_URL}/${rowId}`}>
              <Button 
                onClick={()=>logLmEvent(EVENTS.SURVEY_RESULTS)} 
                className="btn row-button btn-sm btn-primary">
                Results
              </Button>
            </Link>
          </span>
        );
      },
    },
    {
      Header: "Status",
      Cell: (table: any) => {
        let rowId = table.row.original.id;
        const [isEnabled, setIsEnabled] = useState(
          table.row.original.isEnabled
        );
        return (
          <span>
            <Button
              className="btn row-button btn-sm btn-primary"
              disabled={isEnabled}
              style={{ borderRight: "0", borderRadius: "5px 0 0 5px" }}
              onClick={() => {
                logLmEvent(EVENTS.ENABLED_BUTTON);
                EnableSurvey(rowId).then(() => setIsEnabled(true));
              }}
            >
              {isEnabled ? "Enabled" : "Enable"}
            </Button>
            <Button
              className="btn row-button btn-sm btn-primary"
              disabled={!isEnabled}
              style={{ borderLeft: "0", borderRadius: "0 5px 5px 0" }}
              onClick={() => {
                logLmEvent(EVENTS.DISABLED_BUTTON);
                DisableSurvey(rowId).then(() => setIsEnabled(false));
              }}
            >
              {!isEnabled ? "Disabled" : "Disable"}
            </Button>
          </span>
        );
      },
    },
    {
      Header: "Actions",
      Cell: (table: any) => {
        let rowId = table.row.original.id;
        return (
          <span>
            <Link to={`${EDIT_SURVEY_URL}/${table.row.original.productType}/${rowId}`}>
              <Button className="btn row-button btn-sm btn-primary">
                Edit
              </Button>
            </Link>
            <Button
              className="btn row-button btn-sm btn-primary"
              onClick={() => ConfirmAlert("Are you sure?", () => {
                  DeleteSurvey(rowId).then(() => {
                    setData(table.data.filter((survey) => survey.id !== rowId));
                  })
                })
              }
            >
              Delete
            </Button>
          </span>
        );
      },
    },
  ];

  useEffect(() => {
    GetSurveys(1)
      .then(setData)
      .catch((error) => console.log(error));
  }, []);

  const columns: Array<Column<ISurveyListItem>> = useMemo(() => tableColumns, []);
  const [data, setData] = useState(Array<ISurveyListItem>());
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },
    usePagination
  );
  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  return (
    <AppContainer name="RM Surveys">
      <div className="panel">
        <div className="panel-body">
          <Link to={`${CREATE_SURVEY_URL}/1`}>
            <Button 
              onClick={()=>logLmEvent(EVENTS.OPEN_CREATE_NEW_SURVEY)}
              className="btn row-button btn-sm btn-primary">
              Create new survey
            </Button>
          </Link>
          {CustomTable(
            getTableProps,
            headerGroups,
            page,
            prepareRow,
            getTableBodyProps,
            "There are no surveys at the moment"
          )}
          {CustomPagination(pageOptions, pageIndex, tableInstance)}
        </div>
      </div>
    </AppContainer>
  );
}

export default SurveysRM;
