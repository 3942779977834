import moment from "moment";

export function GetDate(date: Date | null | undefined): string {
  if (date === undefined) {
    return "";
  }
  return moment.utc(date).format("YYYY-MM-DD");
}

export function GetDateWithTime(date: Date | null | undefined): string {
  if (date === undefined || !moment.utc(moment(date)).isAfter("0001-01-01")) {
    return "";
  }
  return moment.utc(date).format("YYYY-MM-DD HH:mm:ss");
}