import React, { useEffect, useMemo, useState } from "react";
import IBuild from "./IBuild";
import AppContainer from "../container/AppContainer";
import { Column, useTable, usePagination } from "react-table";
import { Alert, Button } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ADD_BUILD_URL, EVENTS } from "../../../Constants";
import GetBuildsByProductType from "src/api/BuildsApi";
import { GetBuildTableColumnsForUserRole } from "./BuildTableColumns";
import { CustomTable } from "../helpers/CreateTableBody";
import CustomPagination from "../CustomPagination";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";
import { getUserRole } from "src/components/authentication/AuthenticationService";
import UserRole from "src/components/authentication/UserRole";

function RmBuilds() {
  usePageTimeLogger("RM builds (Page)");

  useEffect(() => {
    GetBuildsByProductType(1, true)
      .then(setData)
      .catch((error) => console.log(error));
  }, []);

  const success = new URLSearchParams(window.location.search).get("success");
  var addedText = "";
  if (success === "added") {
    addedText = "New build was added successfully.";
  } else if (success === "edited") {
    addedText = "Build was saved successfully.";
  }

  const history = useHistory();
  const userRole = getUserRole();

  const [data, setData] = useState(Array<IBuild>());
  const [isAlertVisible, setAlertVisible] = useState<boolean>(!!addedText);
  const [alertText, setAlertText] = useState(addedText);
    // eslint-disable-next-line
  const columns: Array<Column<IBuild>> = useMemo(() => GetBuildTableColumnsForUserRole(userRole, setAlertText, setAlertVisible, setData, 1, true), []);
  const tableInstance =useTable({ columns: columns, data: data, initialState: { pageIndex: 0, pageSize: 10 }}, usePagination );
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, page, pageOptions, state:{pageIndex} } =  tableInstance;
  return (
    <AppContainer name="RM Builds">
      <Alert color="info" isOpen={isAlertVisible}>
        {alertText}
      </Alert>
      <div className="panel">
        <div className="panel-body">
          <div className="pb-2">
          { 
            (userRole === UserRole.Administrator || userRole === UserRole.Development) && 
            <Button
              onClick={() => {
                logLmEvent(EVENTS.ADD_BUILD);
                history.push(ADD_BUILD_URL);
              }}
              className="btn btn-primary"
            >
              Add new
            </Button>
          }
          </div>
          {CustomTable(getTableProps, headerGroups, page, prepareRow, getTableBodyProps, 'No builds to display.')}
          {CustomPagination(pageOptions, pageIndex, tableInstance)}
        </div>
      </div>
    </AppContainer>
  );
}

export default RmBuilds;
