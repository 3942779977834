enum UserRole {
  Undefined = -1,
  User,
  Administrator,
  Development,
  TSM,
  CustomerSupport,
  CompanyAdmin,
  Service
}

export default UserRole;
