import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "reactstrap";
import { EVENTS } from "src/Constants";
import { logLmEvent } from "src/assets/services/EventHandlerService";

function KeyCopyCell(value: string, hideKey: boolean) {
  const [open, setOpen] = useState(!hideKey);
  const keyValue: string = value ?? "";

  return (
    <div>
        {!open && <Button onClick={() => {
          logLmEvent(EVENTS.SHOW_KEY)
          setOpen(true)}}>Show key</Button>}
         {open && (
          <div className="input-group" style={{flexWrap: 'nowrap'}}>
          <div className="row align-items-center mt-25">
              <div style={{marginRight: '20px', marginLeft: '10px'}}>{keyValue}</div>
          </div>
            <div>
              <CopyToClipboard text={keyValue}>
                  <Button onClick={()=>logLmEvent(EVENTS.ACTIVATION_KEY_COPIED)} className="btn-outline-secondary" >Copy</Button>
              </CopyToClipboard>
            </div>
          </div>
         )}
    </div>
  );
}

export default KeyCopyCell;
