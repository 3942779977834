import { Column, SortByFn } from "react-table";
import IContact from "../clients/IContact";
import React from "react";
import { Button } from "reactstrap";
import ConfirmAlert from "../forms/ConfirmAlert";
import { nameof } from "ts-simple-nameof";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function MembersColumns(
  ResetPassword: (contactId: string) => void,
  SoftDeleteUser: (userId: string) => void,
  sortByName: SortByFn<any>
): Array<Column<IContact>> {
  
  return [
    {
      Header: "Name",
      //Sort can't work without accessor (even thought it's not being used)
      accessor: nameof<IContact>((contact) => contact.name),
      Cell: ({ row }) => {
        const name = `${row.original.name} ${row.original.surname}`;
        return (
          <span> {row.original.companyAdmin ? name + " ADMIN" : name}</span>
        );
      },
      sortType: sortByName,
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <Button
            color="primary"
            className="row-button btn-sm"
            onClick={(e) => {
              e.preventDefault();
              ConfirmAlert("Are you sure?", 
              () => {ResetPassword(row.original.id); logLmEvent(EVENTS.RESET_PASSWORD, {Selected: "Yes"})},
              () => {logLmEvent(EVENTS.RESET_PASSWORD, {Selected: "No"})}
              );
            }}
          >
            Reset password
          </Button>
        </div>
      ),
    },
    {
      Header: "Delete",
      accessor: nameof<IContact>((contact) => contact.userId),
      Cell: ({ value }) => {
        
        return <Button
        className="btn-sm btn-primary js-filter-by-groups"
        onClick={(e) => {
          e.preventDefault();
          ConfirmAlert("Are you sure you want to delete?", 
          () => {SoftDeleteUser(value); logLmEvent(EVENTS.DELETE, {Selected: "Yes"})},
          () => {logLmEvent(EVENTS.DELETE, {Selected: "No"})}
        );}
      }
      >
        Delete
      </Button>;
      },
    },
  ];
}

export default MembersColumns;
