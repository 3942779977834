import axios from "axios";
import ILicense from "../components/app/allLicenses/ILicense";
import ILicenseType from "../components/app/allLicenses/ILicenseType";
import IProductType from "../components/app/allLicenses/IProductType";
import ILicenseActivationLog from "../components/app/clients/manageClient/ILicenseActivationLog";
import ILicenseTransferLog from "../components/app/clients/manageClient/ILicenseTranferLog";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function UpdateLicense(
  licenseId: string,
  licenseType: string,
  validTill: Date
) {
  return axios
    .patch<ILicense>(
      API_URL + "/Licenses/UpdateLicenseFrontEnd",
      {
        licenseId: licenseId,
        licenseType: licenseType,
        validTill: validTill,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function DeactivateLicense(licenseId: string) {
  return axios.post(
    API_URL + "/Licenses/DeactivateLicense",
    { licenseId: licenseId },
    {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    }
  );
}

export function AssignLicense(licenseId: string, contactId: string) {
  return axios
    .post<ILicense>(
      `${API_URL}/Licenses/AssignLicense`,
      { licenseId: licenseId, contactId: contactId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken().toString()}`,
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function GetExcludedLicenseTypes(currentType: string) {
  return axios
    .get<ILicenseType[]>(
      API_URL + "/LicenseTypes/GetExcludedLicenseTypes/" + currentType,
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function GetUserLicense() {
  return axios
    .get<Array<ILicense>>(API_URL + "/LicensesInfo/GetUserLicenses", {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetProductTypes() {
  return axios
    .get<Array<IProductType>>(API_URL + "/LicensesInfo/GetProductTypes", {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetUserCompanyLicenses() {
  return axios
    .get<ILicense[]>(API_URL + "/LicensesInfo/GetUserCompanyLicenses", {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetLicenses() {
  return axios
    .get<Array<ILicense>>(API_URL + "/LicensesInfo/GetLicenseInfo", {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      return response.data.length > 0 ? response.data : [];
    });
}

export function GetLicenseTransferLogs(clientId: string) {
  return axios
    .get<Array<ILicenseTransferLog>>(
      API_URL + "/LicenseTransferLog/GetLogs/" + clientId,
      {
        headers: { Authorization: "Bearer " + getToken().toString() },
      }
    )
    .then((response) => {
      return response.data.length > 0 ? response.data : [];
    });
}

export function GetLicenseActivationLogs(clientId: string) {
  return axios
    .get<Array<ILicenseActivationLog>>(
      API_URL + "/LicenseActivationLog/GetLogs/" + clientId,
      {
        headers: { Authorization: "Bearer " + getToken().toString() },
      }
    )
    .then((response) => {
      return response.data.length > 0 ? response.data : [];
    });
}
