import React, { useEffect, useMemo } from "react";
import AppContainer from "../container/AppContainer";
import { Column, useTable } from "react-table";
import { Alert, Button, Input } from "reactstrap";
import IGroup from "./IGroup";
import { useState } from "react";
import { useRef } from "react";
import ConfirmAlert from "../forms/ConfirmAlert";
import axios from "axios";
import { API_URL, EVENTS } from "../../../Constants";
import { getToken } from "../../authentication/AuthenticationService";
import { GetUserGroups } from "../../../api/GroupsApi";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

function Groups() {
  usePageTimeLogger("Groups (Page)");

  const [alertVisible, setAlertVisible] = useState(false);
  const [alertText, setText] = useState("");
  const [data, setData] = useState(new Array<IGroup>());
  const dataRef = useRef<Array<IGroup>>();
  dataRef.current = data;
  const [editingText, setEditingText] = useState("");
  const editingTextRef = useRef<string>();
  editingTextRef.current = editingText;
  const [editingRowId, setEditingGroup] = useState(-1);
  const editingRowIdRef = useRef<number>();
  editingRowIdRef.current = editingRowId;
  const newGroupId = -2;

  function EnableEditMode(rowId: number, text: string) {
    setEditingGroup(rowId);
    setEditingText(text);
  }

  function DisableEditMode() {
    setEditingGroup(-1);
    setEditingText("");
  }

  function IsAddNewMode() {
    return editingRowIdRef.current === -2;
  }

  useEffect(() => {
    GetUserGroups()
      .then((groups) => {
        if (groups) setData(groups);
      })
      .catch((error) => {
        setAlertVisible(true);
        setText(error.toString());
        console.log(error);
      });
  }, []);

  function SaveData() {
    if (editingTextRef.current === "") {
      return;
    }

    axios
      .request<IGroup>({
        method: IsAddNewMode() ? "POST" : "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getToken().toString()}`,
        },
        url: `${API_URL}/UserGroups/${
          IsAddNewMode() ? "AddUserGroup" : "UpdateUserGroup"
        }`,
        data: {
          id: editingRowIdRef.current,
          name: editingTextRef.current,
        },
      })
      .then((response) => {
        const groups = dataRef.current?.map((group) => {
          if (group.id === editingRowIdRef.current) {
            group.id = response.data.id;
            group.name = response.data.name ?? "";
          }
          return group;
        });
        setData(groups ?? []);
        DisableEditMode();
      })
      .catch((error) => {
        setAlertVisible(true);
        setText(error.response.data.errorMessage || error.toString());
        console.log(error);
      });
  }

  const tableColumns: Array<Column<IGroup>> = [
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row, value }) => {
        var rowId = editingRowIdRef.current;
        return rowId === row.original.id ? (
          <Input
            maxLength={50}
            autoFocus
            required
            className="form-control col-10 col-md-8 col-lg-6 col-xl-4"
            style={{ paddingLeft: 7, marginLeft: -7 }}
            defaultValue={value}
            onChange={(value) => {
              setEditingText(value.target.value);
            }}
            onKeyDown={(key) => {
              if (key.key === "Enter") {
                logLmEvent(EVENTS.TEXT_FIELD_EDITED,{Value:editingTextRef.current, OldValue:row.original.name})
                SaveData();
              } else if (key.key === "Escape") {
                if (editingTextRef.current !== "" && row.original.name !== "")
                  DisableEditMode();
              }
            }}
          />
        ) : (
          value
        );
      },
    },
    {
      Header: "Actions",

      Cell: (table: any) => {
        var rowId = table.row.original.id;
        var isEditMode = editingRowIdRef.current === rowId;
        return (
          <div className="row">
            <Button
              color="primary"
              size="sm"
              className="row-button mr-1"
              style={
                isEditMode
                  ? { width: 46, backgroundColor: "#25476a", color: "#fff" }
                  : { width: 46 }
              }
              onClick={() => {
                if (isEditMode) {
                  logLmEvent(EVENTS.TEXT_FIELD_EDITED,{Value:editingTextRef.current, OldValue:table.row.original.name})
                  SaveData();
                } else {
                  EnableEditMode(rowId, table.row.original.name);
                }
              }}
            >
              {isEditMode ? "Save" : "Edit"}
            </Button>
            <Button
              color="primary"
              size="sm"
              className="row-button"
              onClick={() => {
                ConfirmAlert("Are you sure?", () => {
                  if (IsAddNewMode()) {
                    DisableEditMode();
                    setData(
                      table.data.filter((group: IGroup) => group.id !== rowId)
                    );
                    return;
                  }

                  axios
                    .delete(`${API_URL}/UserGroups/RemoveUserGroup/${rowId}`, {
                      headers: {
                        Authorization: `Bearer ${getToken().toString()}`,
                      },
                    })
                    .then(() => {
                      DisableEditMode();
                      setData(
                        table.data.filter((group: IGroup) => group.id !== rowId)
                      );
                    });
                });
              }}
            >
              Delete
            </Button>
          </div>
        );
      },
    },
  ];

  const columns: Array<Column<IGroup>> = useMemo(
    () => tableColumns,
    // eslint-disable-next-line
    []
  );

  return (
    <AppContainer name="Groups">
      <Alert isOpen={alertVisible} color="danger">
        {alertText}
      </Alert>
      <div className="panel">
        <div className="panel-body">
          {Table(columns, data)}
          <div className="mx-4 pb-4" style={{ textAlign: "left" }}>
            <Button
              color="primary"
              onClick={() => {
                if (editingRowIdRef.current !== -1) {
                  return;
                }

                setData([...data, { id: newGroupId, name: "" }]);
                EnableEditMode(newGroupId, "");
              }}
            >
              Add new
            </Button>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

function Table(columns: Column<IGroup>[], data: IGroup[]) {
  const tableInstance = useTable({
    columns: columns,
    data: data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  return (
    <table
      className="table table-striped table-hover table-vcenter"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr className="text-left" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps({
                  style: { width: "50%" },
                })}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} style={{ height: 58 }}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{ color: "#7a878e", verticalAlign: "middle" }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Groups;
