import React from "react";
import { FormGroup, Input, Label } from "reactstrap";
import { EVENTS } from "src/Constants";
import { logLmEvent } from "src/assets/services/EventHandlerService";

export default function Checkbox(
    name: string,
    id: string,
    isChecked: boolean,
    onChange: Function
  ) {
    return (
      <FormGroup key={id} className="row">
        <div className="col-sm-10">
          <div className="form-check">
            <Input
              className="form-check-input"
              type="checkbox"
              id={id}
              onChange={(e) => {
                logLmEvent(EVENTS.SURVEY_MANDATORY,{Value:e.currentTarget.checked});
                onChange(e.currentTarget.checked);
              }}
              checked={!!isChecked}
            />
            <Label className="form-check-label" for={id}>
              {name}
            </Label>
          </div>
        </div>
      </FormGroup>
    );
  }
