import UserRole from "./components/authentication/UserRole";

export const API_URL = process.env.REACT_APP_LM_API_URL;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const ALL_ROLES = [
  UserRole.User,
  UserRole.Administrator,
  UserRole.Development,
  UserRole.TSM,
  UserRole.CustomerSupport,
  UserRole.CompanyAdmin,
];

export const LICENSES_URL = "/licenses";
export const LICENSES_URL_ALLOWED_ROLES = [
  UserRole.User,
  UserRole.Administrator,
  UserRole.Development,
  UserRole.TSM,
  UserRole.CustomerSupport,
];

export const MEMBERS_URL = "/members";
export const MEMBERS_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CompanyAdmin,
];

export const COMPANY_LICENSES_URL = "/companyLicenses";
export const COMPANY_LICENSES_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CompanyAdmin,
];

export const ALL_LICENSES_URL = "/allLicenses";
export const ALL_LICENSES_URL_ALLOWED_ROLES = [UserRole.Administrator];

export const BUILDS_GM_URL = "/gm/builds";
export const BUILDS_GM_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
  UserRole.CustomerSupport
];

export const BUILDS_RM_URL = "/rm/builds";
export const BUILDS_RM_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
  UserRole.CustomerSupport
];

export const DOWNLOAD_BUILDS_URL = "/gm/downloads";
export const DOWNLOAD_BUILDS_RM_URL = "/rm/downloads";

export const ADD_BUILD_URL = "/builds/add";
export const ADD_BUILD_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const ADD_RM_BUILD_URL = "/rm/builds/add";
export const ADD_RM_BUILD_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const EDIT_BUILD_URL = "/builds/edit";
export const EDIT_BUILD_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const FORGOT_PASSWORD_URL = "/forgotPassword";
export const SETTINGS_URL = "/settings";

export const CREATE_COMPANY_URL = "/newCompany";
export const CREATE_COMPANY_ALLOWED_ROLES = [UserRole.Administrator];

export const CLIENTS_URL = "/clients";
export const CLIENTS_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CustomerSupport,
];

export const CLIENTS_MANAGE_URL = "/clients/manage";
export const CLIENTS_MANAGE_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CustomerSupport,
];

export const LICENSE_TRANSFER_LOG_URL = "/clients/licenseTransferLog";
export const LICENSE_TRANSFER_LOG_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CustomerSupport,
];

export const LICENSE_ACTIVATION_LOG_URL = "/clients/licenseActivationLog";
export const LICENSE_ACTIVATION_LOG_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.CustomerSupport,
];

export const GROUPS_URL = "/groups";
export const GROUPS_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const SYNC_LOGS_URL = "/sync";
export const SYNC_LOGS_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const SYNC_LOGS_BY_DAYS_URL = "/syncLogs";
export const SYNC_LOGS_BY_DAYS_URL_ALLOWED_ROLES = [
  UserRole.Administrator,
  UserRole.Development,
];

export const GM_SURVEYS_URL = "/gm/surveys";
export const RM_SURVEYS_URL = "/rm/surveys";
export const SURVEYS_URL_ALLOWED_ROLES = [UserRole.Administrator];

export const CREATE_SURVEY_URL = "/createSurvey";
export const CREATE_SURVEY_ALLOWED_ROLES = [UserRole.Administrator];

export const EDIT_SURVEY_URL = "/editSurvey";
export const EDIT_SURVEY_ALLOWED_ROLES = [UserRole.Administrator];

export const SURVEY_RESULTS_URL = "/surveyResults";
export const SURVEY_RESULTS_ALLOWED_ROLES = [UserRole.Administrator];

export const CHANGE_PASSWORD_URL = "/changepassword";

export const minPasswordLength = 8;
export const maxPasswordLength = 128;

export const USER_PRODUCT_TYPES_KEY = "UserProductTypes";

export const PASSWORD_REQUIREMENTS = [
  {
    id: "uppercase",
    label: "Password must contain an uppercase letter",
    metRegex: /[A-Z]/,
  },
  {
    id: "lowercase",
    label: "Password must contain a lowercase letter",
    metRegex: /[a-z]/,
  },
  {
    id: "number",
    label: "Password must contain a number",
    metRegex: /[0-9]/,
  },
  {
    id: "symbol",
    label:
      "Password must contain one of the following symbols: # ? ! @ $ % ^ & * \\ - _",
    metRegex: /[#?!@$%^&*\\\-_]/,
  },
];

export const EVENTS = {
  USER_LOGGED_IN: "User logged in",
  USER_LOGGED_OUT: "User logged out",
  ACTIVATION_KEY_COPIED: "Activation key copied",
  RESET_PASSWORD: "Reset password",
  DELETE: "Delete",
  ADD_NEW: "Add new",
  ENTER_NAME: "Enter name",
  SAVE: "Save",
  FILTER_BY_GROUPS: "Filter by groups",
  SELECT_GROUP: "Select group",
  FILTER_GROUPS: "Filter groups",
  SEARCH_PERFORMED: "Search performed",
  SEARCH_CLEARED: "Search cleared",
  MANAGE: "Manage",
  SAVE_GROUP: "Save group",
  LICENSE_TRANSFER_LOG: "License transfer log",
  LICENSE_ACTIVATION_LOG: "License activation log",
  SHOW_KEY: "Show key",
  ASSIGN_A_LICENSE: "Assign a license",
  EDIT_LICENSE: "Edit license",
  ACTIVATE_USER: "Activate user",
  CHANGE_USER_ROLE: "Change user role",
  USER_ROLE_SELECTED: "User role selected",
  ASSIGN_ROLE: "Assign role",
  ASSIGN_ROLE_CLOSED: "Assign role closed",
  ADD_BUILD: "Add build",
  SELECT_PRODUCT_TYPE: "Select product type",
  FILE_UPLOADED: "File uploaded",
  VERSION_NUMBER: "Version number",
  BACK_ADD_BUILD: "Back add build",
  RELEASE_NOTES_CLICKED: "Release notes clicked",
  DOWNLOAD_LINK_CLICKED: "Download link clicked",
  TEXT_FIELD_EDITED: "Text field edited",
  SEARCH_LICENSE_ID: "Search license id",
  SEARCH_COMPANY_NAME: "Search company name",
  SEARCH_EMAIL: "Search email",
  CHANGE_PASSWORD: "Change password",
  CLICK_ON_DATE: "Click on date",
  EXPORT_TO_CSV: "Export to CSV",
  OPEN_CREATE_NEW_SURVEY: "Open create new survey",
  SURVEY_MANDATORY: "Survey mandatory",
  PRODUCT_TYPE_SELECTED: "Product type selected",
  RECEIVER_ADDED: "Receiver added",
  RECEIVERS: "Receivers",
  SEND_SURVEY_TO_ALL_RECEIVERS_CHECKBOX: "Send survey to all receivers checkbox",
  ADD_RANDOM_RECIPIENTS_BUTTON: "Add random recipients button",
  VERIFY_RECIPIENTS_BUTTON: "Verify recipients button",
  GENERATE_TRIGGER_CODE_BUTTON: "Generate trigger code button",
  NEW_QUESTION_BUTTON: "New question button",
  OPEN_TEXT_QUESTION: "Open text question",
  RATING_QUESTION: "Rating question",
  CHOICE_QUESTION: "Choice question",
  SURVEY_RESULTS: "Survey results",
  ENABLED_BUTTON: "Enabled button",
  DISABLED_BUTTON: "Disabled button",
  LOGOUT: "Logout"
};
