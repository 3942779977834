import React from "react";
import IClient from "./IClient";
import { Column, DefaultSortTypes, SortByFn } from "react-table";
import { GetDateWithTime } from "../../DateHelpers";
import { Button } from "reactstrap";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function ClientColumns(
  dateWithTimeSort: SortByFn<any> | DefaultSortTypes | string,
  ManageClient: (clientId : string) => void
): Array<Column<IClient>> {
  return [
    {
      Header: "Company",
      accessor: "companyName",
    },
    {
      Header: "Created",
      accessor: "created",
      Cell: ({value}) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Actions",
      Cell: ({row}) => (
        <div>
          <Button
            className="btn row-button btn-sm btn-primary"
            onClick={() => {
              logLmEvent(EVENTS.MANAGE,{Value:row.original.companyName});
              ManageClient(row.original.id)
            }}
          >
            Manage
          </Button>
        </div>
      ),
      disableSortBy: true,
    },
  ];
}

export default ClientColumns;
