import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { IsServerAvailable } from "./api/MaintenanceApi";

import {
  getUserRole,
  isLoggedIn,
} from "./components/authentication/AuthenticationService";
import UserRole from "./components/authentication/UserRole";
import { LICENSES_URL } from "./Constants";

interface IRouteProps {
  children: ReactNode;
  path: string;
  allowedRoles: UserRole[];
}

export function PrivateRoute({ children, allowedRoles, ...rest }: IRouteProps) {
  const location = useLocation();
  const userRole = getUserRole();
  const [isServerAvailable, setIsServerAvailable] = useState(true);
  useEffect(() => {
    IsServerAvailable().then((availability) => {
      setIsServerAvailable(availability);
    });
  }, [location.pathname]);

  return (
    <Route
      {...rest}
      render={() => {
        return isLoggedIn() && isServerAvailable ? (
          allowedRoles.some((role) => role === userRole) ? (
            children
          ) : (
            <Redirect to={{ pathname: LICENSES_URL }} />
          )
        ) : (
          <Redirect
            to={{ pathname: "/", state: { from: location.pathname } }}
          />
        );
      }}
    />
  );
}
