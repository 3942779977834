import axios from "axios";
import { Cookies } from "react-cookie/es6";
import { API_URL, EVENTS } from "../../Constants";
import { decodeToken, isExpired } from "react-jwt";
import UserRole from "./UserRole";
import ILicensesInformation from "./ILicensesInformation";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { GetUser } from "src/api/UserApi";
import {useAuth0} from "@auth0/auth0-react";

const jwtCookieName = "jwt";
const cookies = new Cookies();

export enum LoginResult {
  Success,
  InvalidCredentials,
  NetworkError,
}

export function getToken(): string {
  var cookie = cookies.get(jwtCookieName);
  return cookie === undefined ? "" : cookie;
}

export function isLoggedIn() {
  const token = getToken();
  const isTokenExpired = isExpired(token);
  if (isTokenExpired) {
    logLmEvent(EVENTS.LOGOUT)
    cookies.remove(jwtCookieName);
  }
  return token !== "" && !isTokenExpired;
}

export function getUserEmail() {
  return decodeToken(getToken())[
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"
  ];
}

export function getUserRole(): UserRole {
  const token = getToken();
  if (token === undefined || token === "") {
    return UserRole.Undefined;
  }

  const roleString = decodeToken(getToken())[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ];
  const role: keyof typeof UserRole = roleString;
  return UserRole[role];
}

export function getUserLicensesInformation(): ILicensesInformation[]
{
  const userLicensesInformation = JSON.parse(decodeToken(getToken())["LicensesInformation"]);
  return userLicensesInformation;
}

export function login(username: string, password: string, reCaptchaToken: string| undefined | any) {
  return axios
    .post(
      API_URL + "/Authentication/Login",
      { Username: username, Password: password, ReCaptchaToken: reCaptchaToken },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    )
    .then((response) => {
      cookies.set(jwtCookieName, response.data, { sameSite: "strict" });
      return LoginResult.Success;
    })
    .catch((error) => {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 401) {
          return LoginResult.InvalidCredentials;
        }
      }
      return LoginResult.NetworkError;
    });
}

export function isAuthorized(){
  return GetUser().then((response) => {
        return response.status === 200;
    });
}

export function useLogout() {
  const { logout: auth0Logout, isAuthenticated } = useAuth0();

  return () => {
    logLmEvent(EVENTS.LOGOUT);
    cookies.remove(jwtCookieName);

    if (isAuthenticated) {
      auth0Logout();
    }
  };
}

export function GetIsNotSuspendedLicense(productType: number)
{
  var userInformation = getUserLicensesInformation();
  return userInformation !== null && !userInformation.filter(info => info.ProductTypeId === productType).map(info => info.IsSuspended)[0];
}
