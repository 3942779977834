import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
  Button,
} from "reactstrap";
import {
  ChangeUserRole,
  IsInSameCompany,
} from "../../../../../api/ContactsApi";
import UserRole from "../../../../authentication/UserRole";
import TextLabel from "../../../forms/TextLabel";
import IContact from "../../IContact";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function ChangeUserRoleModal(
  setContacts: React.Dispatch<React.SetStateAction<IContact[]>>,
  clientId: string,
  modal: boolean,
  modalToggle: () => void,
  contactId: string,
  userRole: UserRole,
  SetContactsFromAPI: (
    clientId: string,
    setContacts: React.Dispatch<React.SetStateAction<IContact[]>>
  ) => void,
  setAlertVisible: (isVisible: boolean) => void,
  setAlertColor: (color: string) => void,
  setAlertText: (text: string) => void
) {
  const [selectedUserRole, setSelectedUserRole] = useState<UserRole>(userRole);
  const [isSameCompany, setIsSameCompany] = useState(false);

  useEffect(() => {
    IsInSameCompany(clientId)
      .then(setIsSameCompany)
      .catch((error) => console.log(error));
  }, [clientId]);

  useEffect(() => {
    setSelectedUserRole(userRole);
  }, [userRole]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      backdrop={true}
      className="modal-centered modal-body"
    >
      <ModalHeader toggle={modalToggle}>Change user role</ModalHeader>
      <ModalBody>
        {TextLabel("Select user role:")}
        <UncontrolledDropdown
          className="ml-3 changeUserRoleDropdown"
          direction="down"
        >
          <DropdownToggle className="dropdown-input min-width-140" caret>
            {UserRole[selectedUserRole]}
          </DropdownToggle>
          <DropdownMenu>
            {GetAvailableRoles(isSameCompany).map((userRole) => (
              <DropdownItem
                className="min-width-140"
                key={userRole}
                onClick={() => {
                  logLmEvent(EVENTS.USER_ROLE_SELECTED,{Value:UserRole[Number(userRole)]});
                  setSelectedUserRole(Number(userRole));
                }}
              >
                {UserRole[Number(userRole)]}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            AssignNewRoleToContact(
              setContacts,
              clientId,
              contactId,
              selectedUserRole,
              SetContactsFromAPI,
              setAlertVisible,
              setAlertColor,
              setAlertText
            );
            logLmEvent(EVENTS.ASSIGN_ROLE,{Value:UserRole[selectedUserRole]});
            modalToggle();
          }}
        >
          Assign
        </Button>
        <Button color="secondary" onClick={()=>{
          logLmEvent(EVENTS.ASSIGN_ROLE_CLOSED);
          modalToggle();
          }}>
          Close
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}

function AssignNewRoleToContact(
  setContacts: React.Dispatch<React.SetStateAction<IContact[]>>,
  clientId: string,
  contactId: string,
  userRole: UserRole,
  SetContactsFromAPI: (
    clientId: string,
    setContacts: React.Dispatch<React.SetStateAction<IContact[]>>
  ) => void,
  setAlertVisible: (isVisible: boolean) => void,
  setAlertColor: (color: string) => void,
  setAlertText: (text: string) => void
) {
  if (contactId === null || contactId === "") {
    return;
  }

  ChangeUserRole(contactId, userRole).then((response) => {
    if (response.data.isSuccessful) {
      setAlertText(`User role changed successfully.`);
      setAlertColor("info");
      setAlertVisible(true);
      SetContactsFromAPI(clientId, setContacts);
    } else {
      setAlertText(response.data.errorMessage);
      setAlertColor("danger");
      setAlertVisible(true);
    }
  });
}

function GetAvailableRoles(isSameCompany: Boolean): string[] {
  if (isSameCompany) {
    return Object.keys(UserRole).filter(
      (userRole) =>
        Number(userRole) !== UserRole.Service &&
        Number(userRole) !== UserRole.Undefined &&
        !isNaN(Number(userRole))
    );
  }

  return [UserRole.User.toString(), UserRole.CompanyAdmin.toString()];
}

export default ChangeUserRoleModal;
