import { DownloadBuild } from "./DownloadBuild";
import MDEditor from "@uiw/react-md-editor";
import React from "react";
import { Column} from "react-table";
import IBuild from "./IBuild";
import { GetDateWithTime } from "../../DateHelpers";
import { Button } from "reactstrap";
import { API_URL, EDIT_BUILD_URL, EVENTS } from "src/Constants";
import { Link } from "react-router-dom";
import { getToken } from "../../authentication/AuthenticationService";
import ConfirmAlert from "../forms/ConfirmAlert";
import axios from "axios";
import GetBuildsByProductType from "src/api/BuildsApi";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import UserRole from "src/components/authentication/UserRole";

export default function GetBuildTableColumns(isNotSuspendedLicense: boolean) : Array<Column<IBuild>>
{
    var columns : Array<Column<IBuild>> = [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => {
          return (
            <div style={{ maxWidth: 150, minWidth: 100 }}>
              <div>{row.original.name}</div>
              <div className="font-weight-bold">{row.original.versionNumber}</div>
            
              <div>
                {new Date(row.original.uploadDate).toISOString().split("T")[0]}
              </div>
            </div>
          );
        },
      },
      {
        Header: "Comment",
        accessor: "mainComment",
        Cell: ({ row }) => {
          return (
            <div className="downloadBuildComments">
              {
                <MDEditor.Markdown
                  className="markdown-text"
                  source={row.original.mainComment}
                ></MDEditor.Markdown>
              }
              {
                <MDEditor.Markdown
                  className="markdown-text"
                  source={row.original.subComment}
                ></MDEditor.Markdown>
              }
            </div>
          );
        },
      },
      {
        Header: "Release notes",
        accessor: "releaseNotesUrl",
        Cell: ({ value }) => {
          return (
            <a
              style={{ maxWidth: 150 }}
              onClick={()=>logLmEvent(EVENTS.RELEASE_NOTES_CLICKED)}
              href={value}              
              className="text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Release notes
            </a>
          );
        },
      }
    ];

    if (isNotSuspendedLicense)
    {
      columns.push(
      {
        Header: "Download link",
        accessor: "file",
        Cell: ({ row, value }) => {
          return DownloadBuild(row.original.id, value, row.original.versionNumber, true, false, row.original.availableForDownload);
        },
      });
    }

    return columns;
}

export function GetBuildTableColumnsForUserRole(userRole, setAlertText, setAlertVisible, setData, productTypeId, doGetBuildsOfAllTime) : Array<Column<IBuild>>
{
    return [
        {
          Header: "Name",
          accessor: "name",
          Cell: ({ value }) => {
            return <div style={{ maxWidth: 150 }}>{value}</div>;
          },
        },
        {
          Header: "Upload date",
          accessor: "uploadDate",
          Cell: ({ value }) => {
            return <span>{GetDateWithTime(value)}</span>;
          },
        },
        {
          Header: "Version",
          accessor: "versionNumber",
          Cell: ({ value }) => {
            return <div style={{ maxWidth: 150 }}>{value}</div>;
          },
        },
        {
          Header: "Release notes",
          accessor: "releaseNotesUrl",
          Cell: ({ value }) => {
            return (
              <a
                onClick={()=>logLmEvent(EVENTS.RELEASE_NOTES_CLICKED)}
                href={value}
                className="text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                Release notes
              </a>
            );
          },
        },
        {
          Header: "Comment",
          accessor: "mainComment",
          Cell: ({ row }) => {
            return (
              <div className="buildComments">
                {
                  <MDEditor.Markdown
                    className="markdown-text"
                    source={row.original.mainComment}
                  ></MDEditor.Markdown>
                }
                {
                  <MDEditor.Markdown
                    className="markdown-text"
                    source={row.original.subComment}
                  ></MDEditor.Markdown>
                }
              </div>
            );
          },
        },
        {
          Header: "Available for download",
          accessor: "availableForDownload",
          Cell: ({ value }) => {
            return value ? (
              <i className="pli-yes icon-lg text-success"></i>
            ) : (
              <i className="pli-close icon-lg text-danger"></i>
            );
          },
        },
        {
          Header: "Download link",
          accessor: "file",
          Cell: ({ row, value }) => {
            return DownloadBuild(row.original.id, value, row.original.versionNumber,false, false, row.original.availableForDownload);
          },
        },
        {
          Header: "Actions",
          accessor: "id",
          Cell: ({ row }) => {
            if (userRole === UserRole.Administrator || userRole === UserRole.Development)
            {
              return (
                <span>
                  <Link to={`${EDIT_BUILD_URL}/${row.original.id}`}>
                    <Button className="btn row-button btn-sm btn-primary">
                      Edit
                    </Button>
                  </Link>
                  <br />
                  <Button
                    className="btn row-button btn-sm btn-primary js-confirm"
                    onClick={() =>
                      ConfirmAlert("Are you sure?", () => {
                        axios
                          .delete(
                            `${API_URL}/Builds/RemoveBuild/${row.original.id}`,
                            {
                              headers: {
                                Authorization: `Bearer ${getToken().toString()}`,
                              },
                            }
                          )
                          .then(() => {
                            GetBuildsByProductType(productTypeId, doGetBuildsOfAllTime).then((response) => {
                              setData(response);
                              setAlertVisible(true);
                              setAlertText("Build was deleted successfully.");
                            });
                        
                          });
                      })
                    }
                  >
                    Delete
                  </Button>
                </span>
                  );
            }
            else {
              return <span></span>
            }          
          },
        },
      ];
}