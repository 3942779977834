import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input, Button, Dropdown,DropdownItem, DropdownMenu,DropdownToggle } from "reactstrap";
import AppContainer from "../container/AppContainer";
import { useHistory } from "react-router-dom";
import { API_URL, BUILDS_GM_URL, BUILDS_RM_URL, EVENTS } from "../../../Constants";
import TextField from "../forms/TextField";
import { useEffect } from "react";
import { getToken } from "../../authentication/AuthenticationService";
import IBuild from "./IBuild";
import axios from "axios";
import IGroup from "../groups/IGroup";
import { nameof } from "ts-simple-nameof";
import { Alert } from "reactstrap";
import { ClipLoader } from "react-spinners";
import { GetUserGroups } from "../../../api/GroupsApi";
import MDEditor from "@uiw/react-md-editor";
import IProductType from "../allLicenses/IProductType";
import { GetProductTypes } from "../../../api/LicensesApi";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

type BuildProps = {
  buildId?: number;
  pageName: string;
  url:string;
};

function GetFileName(path: string) {
  return path ? path.replace(/^.*[\\/]/, "") : "";
}

function SaveBuild(
  build: IBuild,
  groups: IGroup[],
  mainComment: string,
  subComment: string,
  productTypeId : number,
  addNew: boolean
) {
  const buildToSend = { ...build };
  buildToSend.mainComment = mainComment;
  buildToSend.subComment = subComment;
  buildToSend.userGroups = groups.filter((group) => group.isChecked);
  buildToSend.productTypeId = productTypeId;
  return axios
    .request({
      url: `${API_URL}/Builds/${addNew ? "AddBuild" : "UpdateBuild"}`,
      method: addNew ? "POST" : "PATCH",
      data: buildToSend,
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      console.log(response);
      return response.data;
    });
}

function UploadFile(buildId: number, file?: File) {
  if (!file) return;
  const formData = new FormData();
  formData.append(
    nameof<IBuild>((b) => b.buildFile),
    file,
    file.name
  );
  formData.append(
    nameof<IBuild>((b) => b.id),
    String(buildId)
  );
  return axios
    .post(API_URL + "/Builds/AddBuildFile", formData, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      return response;
    });
}

function GetBuild(buildId?: number) {
  if (buildId === undefined) return;
  return axios
    .get<IBuild>(`${API_URL}/Builds/GetBuildById/${buildId}`, {
      headers: { Authorization: `Bearer ${getToken().toString()}` },
    })
    .then((response) => {
      var data = response.data;
      return data;
    });
}

function Build({ buildId, pageName, url }: BuildProps) {
  usePageTimeLogger(buildId?"Edit build (Page)":"Add build (Page)");

  const isAddNewMode = buildId === undefined;
  const [productTypeId, setProductTypeId] = useState(Number);
  const [productTypeName, setProductTypeName] = useState('');
  const [productTypes, setProductTypes] =  useState<IProductType[]>();
  const [productDropDownOpen, setProductDropDownOpen] = useState(false);

  useEffect(() =>  {
    if(productTypeName !== '')
    {
      return;
    }
     GetProductTypes().then(results => {
      setProductTypes(results.map(result => result));
      if (!buildId && productTypes)
        {
          setProductTypeId(productTypes[0].value);
          setProductTypeName(productTypes[0].name);
        }
  
    });
  }, [productTypes, productTypeName, productTypeId]);

  useEffect(() => {
    GetUserGroups()
      .then((groups) => {
        if (groups) setGroups(groups);
      })
      .then(() => GetBuild(buildId))
      .then((build) => {
        if (!build) return;
        setProductTypeId(build.productTypeId);
        setProductTypeName(build.productName);
        const userGroups = groupsRef.current?.map((group) =>
          build.userGroups?.find((buildGroup) => buildGroup.id === group.id)
            ? { ...group, isChecked: true }
            : group
        );
        if (userGroups) {
          setGroups(userGroups);
        }
        setBuild(build);
        setProductTypeId(build.productTypeId);
        setProductTypeName(build.productName);
        setMainComment(build.mainComment);
        setSubComment(build.subComment);
        setBuildName(GetFileName(build.file));
      })
      .catch((error) => {
        setAlertVisibile(true);
        console.log(error);
      });
  }, [buildId]);

  const [groups, setGroups] = useState(new Array<IGroup>());
  const groupsRef = useRef<Array<IGroup>>();
  groupsRef.current = groups;
  const [buildName, setBuildName] = useState("");
  const [build, setBuild] = useState({} as IBuild);

  const [mainComment, setMainComment] = useState("");
  const [subComment, setSubComment] = useState("");
  const [isAlertVisibile, setAlertVisibile] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [isLoading, setLoading] = useState(false);
  const history = useHistory();

  function Save() {
    setLoading(true);
    SaveBuild(build, groups, mainComment, subComment, productTypeId, isAddNewMode )
      .then((savedBuild) => UploadFile(savedBuild.id, build.buildFile))
      .then(() =>
        {
          console.log(productTypeId);
          history.push(
          `${productTypeId === 0 ? BUILDS_GM_URL : BUILDS_RM_URL}?success=${isAddNewMode ? "added" : "edited"}`
        )}
      )
      .catch((error) => {
        console.log(error);
        setAlertText("Network error");
        setAlertVisibile(true);
        setLoading(false);
      });
  }

  return (
    <AppContainer name={pageName}>
      <Alert color="danger" isOpen={isAlertVisibile}>
        {alertText}
      </Alert>
      
      <ClipLoader
        css={" position: absolute; top: 50%; left: 50%; margin-top:150px;"}
        color={"blue"}
        loading={isLoading}
        size={150}
      />
      <Form
        name="build"
        encType="multipart/form-data"
        hidden={isLoading}
        onSubmit={(e) => {
          e.preventDefault();
          Save();
        }}
      >
        <div className="panel">
          <div className="panel-body">
            {TextField("Name", build.name, (value: string) =>
              {
                if (value.length > 50){
                  setAlertText("Build name is too long (50 max length.)");
                  setAlertVisibile(true);
                }
                else{
                  setBuild({ ...build, name: value });
                  setAlertVisibile(false);
                }
              },
              undefined
            )}
            <FormGroup className="row">
              <Label className="col-form-label col-sm-2" for="build_file">
                Build file (*.msi)
              </Label>
              <div className="col-sm-10">
                <div className="custom-file">
                  <Input
                    className="custom-file-input"
                    type="file"
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.files &&
                        e.target.files[0].name.endsWith(".msi")
                      ) {
                        setBuildName(GetFileName(e.target.value));
                        setBuild({ ...build, buildFile: e.target.files[0] });
                        logLmEvent(EVENTS.FILE_UPLOADED,{Value:GetFileName(e.target.value)})
                      }
                    }}
                    id="build_file"
                    accept=".msi"
                    required={isAddNewMode}
                  />
                  <Label for="build_file" className="custom-file-label">
                    {buildName}
                  </Label>
                </div>
              </div>
            </FormGroup>

            <FormGroup className="row">
              <Label className="col-form-label col-sm-2 required">
                Product Type
              </Label>
              <div className="col-sm-10">
                <Dropdown
                style={{paddingLeft : "0.75rem"}}
                  direction="down"
                  isOpen={productDropDownOpen}
                  toggle={() => setProductDropDownOpen(!productDropDownOpen)}
                >
                  
                  <DropdownToggle className="dropdown-input" caret>
                    {productTypeName}
                  </DropdownToggle>
                  <DropdownMenu>
                    {productTypes?.map((type) => (
                      <DropdownItem
                        key={type.value}
                        value = {type.name}
                        onClick={() => {
                          logLmEvent(EVENTS.SELECT_PRODUCT_TYPE,{Value:type.name});
                          setProductTypeId(type.value);
                          setProductTypeName(type.name);
                        }}
                      >
                        {type.name}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </FormGroup>


            {TextField(
              "Release notes url",
              build.releaseNotesUrl,
              (value: string) => setBuild({ ...build, releaseNotesUrl: value }),
              undefined
            )}
            {Comment("Left comment", mainComment, setMainComment)}
            {Comment("Right comment", subComment, setSubComment)}
            {TextField("Version number", build.versionNumber, (value: string) =>
              setBuild({ ...build, versionNumber: value }),
            EVENTS.VERSION_NUMBER
            )}
            {Checkbox(
              "Available for download",
              "build_available_for_download",
              build.availableForDownload,
              (value: boolean) =>
                setBuild({ ...build, availableForDownload: value })
              
            )}
            {Checkbox(
              "Skip update notification",
              "build_skip_notification",
              build.skipNotification,
              (value: boolean) =>
                setBuild({ ...build, skipNotification: value })
            )}
            {Checkbox(
              "All groups",
              "build_all_groups",
              build.allGroups,
              (value: boolean) => setBuild({ ...build, allGroups: value })
            )}
            <fieldset className="form-group">
              <div className="row">
                <legend className="col-sm-2 col-form-label required">
                  Groups
                </legend>
                <div className="col-sm-10">
                  <div id="build_groups">
                    {groups.map((group) => {
                      return Checkbox(
                        group.name,
                        "build_groups_" + group.id,
                        !!group.isChecked,
                        (value: boolean) =>
                          setGroups(
                            groups.map((g) =>
                              g.id === group.id ? { ...g, isChecked: value } : g
                            )
                          )
                      );
                    })}
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
          <div className="panel-footer mx-4 pb-4">
            <Button className="btn btn-primary" type="submit">
              Save
            </Button>
            &nbsp;
              <Button onClick={() => {
                logLmEvent(EVENTS.BACK_ADD_BUILD);
                history.goBack();
                }} className="btn btn-outline-secondary">Back</Button>
          </div>
        </div>
      </Form>
    </AppContainer>
  );
}

function Checkbox(
  name: string,
  id: string,
  isChecked: boolean,
  onChange: Function
) {
  return (
    <FormGroup key={id} className="row">
      <div className="col-sm-10">
        <div className="form-check">
          <Input
            className="form-check-input"
            type="checkbox"
            id={id}
            onChange={(e) => {
              logLmEvent(name,{Value:e.currentTarget.checked})
              onChange(e.currentTarget.checked)
            }}
            checked={!!isChecked}
          />
          <Label className="form-check-label" for={id}>
            {name}
          </Label>
        </div>
      </div>
    </FormGroup>
  );
}

function Comment(name: string, comment: string, onChange: Function) {
  return (
    <FormGroup className="row">
      <Label className="col-form-label col-sm-2 required">{name}</Label>
      <div className="col-sm-10">
        <MDEditor
          highlightEnable={false}
          value={comment}
          onChange={(e) => onChange(e)}
        ></MDEditor>
      </div>
    </FormGroup>
  );
}

export default Build;
