import React, { useEffect, useState } from "react";
import AppContainer from "../container/AppContainer";
import { useParams } from "react-router-dom";
import { GetSurveyForResults, GetSurveyStats } from "src/api/SurveyApi";
import OpenTextResults from "./resultElements/OpenTextResults";
import RatingResults from "./resultElements/RatingResults";
import ChoiceResults from "./resultElements/ChoiceResults";
import ISurvey from "./ISurvey";
import {
  ISurveyChoiceQuestion,
  ISurveyOpenTextQuestion,
  ISurveyRatingQuestion,
} from "./ISurveyQuestions";
import ISurveyResults from "./ISurveyResults";
import { ISurveyStats } from "./ISurveyListItem";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

type Question =
  | ISurveyOpenTextQuestion
  | ISurveyChoiceQuestion
  | ISurveyRatingQuestion;

function SurveyResults() {
  usePageTimeLogger("Survey results (Page)");

  const { surveyId } = useParams<{ surveyId: string }>();

  const [questions, setQuestions] = useState(Array<Question>());

  const [data, setData] = useState<ISurveyResults>();

  const [surveyStats, setSurveyStats] = useState<ISurveyStats>();

  useEffect(() => {
    GetSurveyForResults(surveyId).then((surveyResults) => {
      setData(surveyResults);
      let surveyQuestions = [
        ...surveyResults.survey.openTextQuestions,
        ...surveyResults.survey.ratingQuestions,
        ...surveyResults.survey.choiceQuestions,
      ];
      surveyQuestions.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
      setQuestions(surveyQuestions);
    });

    GetSurveyStats(surveyId).then((stats) => {
      setSurveyStats(stats)
    })
  }, []);

  function isChoiceQuestion(
    question: Question
  ): question is ISurveyChoiceQuestion {
    return "isMultipleAnswers" in question;
  }

  function isRatingQuestion(
    question: Question
  ): question is ISurveyRatingQuestion {
    return "fromText" in question;
  }

  //TODO: THIS WHOLE PAGE NEEDS A MUCH BETTER DESIGN, BUT THE FUNCTIONALITY WORKS
  //I'M GONNA IMPROVE THE DESIGN, BUT FOR NOW, I DON'T WANT TO BLOCK THE CODE REVIEW
  return (
    <AppContainer name="Surveys">
      <div className="panel">
        <div className="panel-body">
          <h3>{data?.survey?.title} </h3>
          Unique recipients: {surveyStats?.uniqueRecipients ?? 0} | 
          Recipients responded: {surveyStats?.answeredUniqueRecipients ?? 0} |
          Total answers: {surveyStats?.totalAnswers ?? 0}
          {questions.map((questionItem, index) => {
            if (isChoiceQuestion(questionItem)) {
              return <ChoiceResults key={index} question={questionItem} />;
            } else if (isRatingQuestion(questionItem)) {
              return <RatingResults key={index} question={questionItem} />;
            } else {
              return (
                <OpenTextResults
                  key={Math.random()}
                  question={questionItem.question}
                  answers={questionItem.openTextQuestionAnswers}
                />
              );
            }
          })}
        </div>
      </div>
    </AppContainer>
  );
}

export default SurveyResults;
