import { useEffect, useRef } from "react";
import { postEvent } from "src/api/EventLoggingApi";
import { getCookie } from "src/components/app/helpers/Cookies";

const trackingVariable = 'isTrackingAccepted';

export function logLmEvent(eventName : string, eventProperties: { [key: string]: any } = {}){
    if(getCookie(trackingVariable)!=='true'){
      return;
    }
    const enhancedEventProperties = { ...eventProperties, Route: document.location.pathname };
    return postEvent(eventName, enhancedEventProperties)
}

const usePageTimeLogger = (pageName) => {
    const startTimeRef = useRef(Date.now());
  
    useEffect(() => {
      const startTime = startTimeRef.current;
  
      return () => {
        const endTime = Date.now();
        const timeSpent = (endTime - startTime) / 1000;
        logLmEvent(pageName,{TimeSpent:timeSpent+'s'});
      };
    }, [pageName]);
  };
  
export default usePageTimeLogger;