import React, { useEffect, useMemo, useState } from "react";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { Input, Button } from "reactstrap";
import AppContainer from "../container/AppContainer";
import CustomPagination from "../CustomPagination";
import ClientColumns from "./ClientColumns";
import IClient from "./IClient";
import GroupFiltering, {
  FilterByGroups,
  GetAvailableGroups,
  ResetAvailableGroups,
} from "../groups/GroupFiltering";
import { IsStringIncludes } from "../../StringsExtensions";
import { useHistory } from "react-router-dom";
import { CLIENTS_MANAGE_URL, CREATE_COMPANY_URL, EVENTS } from "../../../Constants";
import IGroup from "../groups/IGroup";
import { GetDateWithTimeSort } from "../../SortHelper";
import { GetCompanies } from "../../../api/CompanyApi";
import { getUserRole } from "src/components/authentication/AuthenticationService";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

function Clients() {
  usePageTimeLogger("Clients (Page)");
  
  const history = useHistory();
  function ManageClient(clientId: string) {
    history.push(`${CLIENTS_MANAGE_URL}/${clientId}`);
  }

  const dateWithTimeSort = React.useMemo(() => GetDateWithTimeSort(), []);
  const columns: Array<Column<IClient>> = useMemo(
    () => ClientColumns(dateWithTimeSort, ManageClient),
    // eslint-disable-next-line
    [dateWithTimeSort]
  );

  const [clients, setClients] = useState(Array<IClient>());
  const [allClients, setAllClients] = useState(clients);
  const [availableGroups, setAvailableGroups] = useState(
    GetAvailableGroups(clients)
  );
  const [companyFilter, setCompanyFilter] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  useEffect(() => {
    GetCompanies()
      .then((companies) => {
        setClients(companies);
        setAllClients(companies);
        setAvailableGroups(GetAvailableGroups(companies));
      })
      .catch((error) => console.log(error));
  }, []);

  const tableInstance = useTable(
    {
      columns: columns,
      data: clients,
      initialState: { pageIndex: 0, pageSize: 20 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      logLmEvent(EVENTS.SEARCH_PERFORMED, {Value:companyFilter})
      FilterClients(allClients, companyFilter, availableGroups, setClients);
    }
  }

  function ClearFilters() {
    setCompanyFilter("");
    const updatedAvailableGroups = ResetAvailableGroups(availableGroups);
    setAvailableGroups(updatedAvailableGroups);
    FilterClients(clients, "", updatedAvailableGroups, setClients);
  }

  function Filter() {
    FilterClients(allClients, companyFilter, availableGroups, setClients);
  }

  const userRole = getUserRole();

  return (
    <div>
      <AppContainer name="Clients">
        <div className="panel">
          <div className="panel-body">
            <div className="pb-2 ">
              {userRole === 1 && (
                <Button
                  onClick={() => {
                    logLmEvent(EVENTS.ADD_NEW);
                    history.push(CREATE_COMPANY_URL)
                  }}
                  className="btn btn-primary mr-3"
                >
                  Add new
                </Button>
              )}
            </div>

            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.render("Header")}
                          {column.Header !== "Actions" && (
                            <div className="sorting d-inline-block align-middle">
                              <i
                                onClick={() => column.toggleSortBy(true, false)}
                                className="pli-down"
                              ></i>
                              <i
                                onClick={() =>
                                  column.toggleSortBy(false, false)
                                }
                                className="pli-up"
                              ></i>
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
                <tr className="filters-row">
                  <th className="text-left">
                    <Input
                      className="form-control"
                      value={companyFilter}
                      onChange={(e) => setCompanyFilter(e.target.value)}
                      onKeyPress={handleKeyPress}
                    ></Input>
                  </th>

                  <th></th>
                  <th className="text-right">
                    <div className="btn-group">
                      <Button
                        className="btn-sm btn-primary js-filter-by-groups"
                        onClick={()=>{
                          logLmEvent(EVENTS.FILTER_BY_GROUPS);
                          toggle();}}
                      >
                        <i className="pli-business-mens"></i>
                      </Button>
                    </div>
                    {" "}
                    <div className="btn-group">
                      <Button
                        className="btn-sm btn-primary"
                        onClick={() => {
                          logLmEvent(EVENTS.SEARCH_PERFORMED, {Value:companyFilter})
                          Filter();
                        }}
                      >
                        <i className="pli-magnifi-glass"></i>
                      </Button>
                      <Button
                        className="btn-sm btn-outline-secondary"
                        onClick={() => {
                          logLmEvent(EVENTS.SEARCH_CLEARED)
                          ClearFilters();
                        }}
                      >
                        <i className="pli-cross"></i>
                      </Button>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ color: "#7a878e" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          {CustomPagination(pageOptions, pageIndex, tableInstance)}
          {GroupFiltering(
            modal,
            toggle,
            Filter,
            availableGroups,
            setAvailableGroups
          )}
        </div>
      </AppContainer>
    </div>
  );
}

function FilterClients(
  clients: Array<IClient>,
  companyFilter: string,
  availableGroups: Array<IGroup>,
  setData: (data: Array<IClient>) => void
) {
  let newData = clients.filter((client) =>
    IsStringIncludes(client.companyName ?? "", companyFilter)
  );
  newData = FilterByGroups(newData, availableGroups) as IClient[];
  setData(newData);
}

export default Clients;
