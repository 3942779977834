import React, { useEffect, useMemo, useState } from "react";
import IBuild from "./IBuild";
import AppContainer from "../container/AppContainer";
import { useTable, usePagination } from "react-table";
import { CustomTable } from "../helpers/CreateTableBody";
import CustomPagination from "../CustomPagination";
import GetBuildTableColumns from "./BuildTableColumns";
import GetBuildsByProductType from "src/api/BuildsApi";
import { GetIsNotSuspendedLicense } from "../../authentication/AuthenticationService"
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function DownloadBuildsRm() {
  usePageTimeLogger("RM application versions (Page)");

  useEffect(() => {
    GetBuildsByProductType(1, false)
      .then(setRmData)
      .catch((error) => console.log(error));
  }, []);

  // eslint-disable-next-line
  const columns = useMemo(() => GetBuildTableColumns(GetIsNotSuspendedLicense(1)), []);
  // eslint-disable-next-line
  const [rmData, setRmData] = useState(Array<IBuild>());
  const tableInstance = useTable({ columns: columns, data:rmData , initialState: { pageIndex: 0, pageSize: 10 }}, usePagination)
  const { getTableBodyProps, getTableProps, headerGroups, prepareRow, page, pageOptions,
    state: { pageIndex } } = tableInstance;
  
  return (
    <AppContainer name="Application versions RM">
      <div className="panel">
        <div className="panel-body">
        {CustomTable(getTableProps,headerGroups,  page, prepareRow, getTableBodyProps, 'No downloads available at this moment.')}
        {CustomPagination(pageOptions,pageIndex, tableInstance)}
        </div>
      </div>
    </AppContainer>
  );
}

export default DownloadBuildsRm;