import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import {
  API_URL,
  EVENTS,
  LICENSE_ACTIVATION_LOG_URL,
  LICENSE_TRANSFER_LOG_URL,
} from "../../../../Constants";
import {
  getToken,
  getUserRole,
} from "../../../authentication/AuthenticationService";
import ILicense from "../../allLicenses/ILicense";

import ClientLicensesColumns from "./ClientLicensesColumns";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import AssignLicenseModal from "./modalWindows/AssignLicenseModal";
import { Alert } from "reactstrap/lib";
import {
  GetExcludedLicenseTypes,
  UpdateLicense,
} from "../../../../api/LicensesApi";
import { Table } from "../../helpers/CompanyLicenseTable";
import Loader from "../../helpers/Loader";
import { logLmEvent } from "src/assets/services/EventHandlerService";

type LicensesProp = {
  clientId: string;
};

function ClientLicenses(licensesProp: LicensesProp) {
  const [licenses, setLicenses] = useState(Array<ILicense>());

  const [modal, setModal] = useState(false);
  const [selectedLicenseId, setSelectedLicenseId] = useState("");
  const toggle = () => setModal(!modal);

  const [licenseTypes, setLicenseTypes] = useState(Array<string>());
  const [editableLicense, setEditabledLicense] = useState({} as ILicense);
  const [editingRowId, setEditingRowId] = useState(-1);

  const [isAlertVisibile, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const columns: Array<Column<ILicense>> = useMemo(
    () =>
      ClientLicensesColumns(
        OpenAssignLicenseModal,
        editingRowId,
        setEditingRowId,
        editableLicense,
        setEditabledLicense,
        licenseTypes,
        SaveLicense
      ),
    // eslint-disable-next-line
    [editingRowId, licenseTypes]
  );

  const userRole = getUserRole();

  function SaveLicense() {
    if (editableLicense.validTill === undefined) {
      setAlertText("Valid till date is invalid!");
      setAlertColor("danger");
      setAlertVisible(true);
      setIsLoading(false);
      return;
    }

    setAlertVisible(false);
    setIsLoading(true);
    UpdateLicense(
      editableLicense.id,
      editableLicense.type,
      editableLicense.validTill
    )
      .then((updatedLicense) => {
        setLicenses((old) =>
          old.map((row, index) => {
            return index === editingRowId ? updatedLicense : row;
          })
        );

        setAlertText("License was updated successfully.");
        setAlertColor("info");
        setAlertVisible(true);
        setIsLoading(false);
      })
      .catch(() => {
        setAlertText("Network error.");
        setAlertColor("danger");
        setAlertVisible(true);
        setIsLoading(false);
      });
  }

  function OpenAssignLicenseModal(licenseId: string) {
    setAlertVisible(false);
    setSelectedLicenseId(licenseId);
    toggle();
  }

  useEffect(() => {
    UpdateLicenses();
    // eslint-disable-next-line
  }, [licensesProp.clientId]);

  useEffect(() => {
    if (editableLicense.type !== undefined) {
      GetExcludedLicenseTypes(editableLicense.type).then((licenseTypes) =>
        setLicenseTypes(licenseTypes.map((licenseType) => licenseType.type))
      );
    }
  }, [licensesProp.clientId, editableLicense]);

  function UpdateLicenses() {
    axios
      .get<ILicense[]>(
        API_URL + `/LicensesInfo/GetCompanyLicenses/${licensesProp.clientId}`,
        {
          headers: { Authorization: "Bearer " + getToken().toString() },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setLicenses(response.data);
        } else {
          setLicenses(Array<ILicense>());
        }
      })
      .catch((error) => console.error(error));
  }

  return (
    <>
      {isLoading ? <Loader/> : null}
      <Alert isOpen={isAlertVisibile} color={alertColor}>
        {alertText}
      </Alert>
      <div className="panel">
        <div className="panel-body">
          {userRole === 1 && (
              <Alert color="info">
                License creation from LM is disabled. Please create licenses via Salesforce.
              </Alert>
          )}
          {/* This button was disabled with INF-1032. Should be removed later. */}
          {userRole === 1 && (
              <Button color="primary" className="ml-2" disabled> 
                Add new license
              </Button>
          )}
          <Link to={`${LICENSE_TRANSFER_LOG_URL}/${licensesProp.clientId}`}>
            <Button onClick={()=>logLmEvent(EVENTS.LICENSE_TRANSFER_LOG)} color="secondary" className="ml-2">
              License transfer log
            </Button>
          </Link>
          <Link to={`${LICENSE_ACTIVATION_LOG_URL}/${licensesProp.clientId}`}>
            <Button onClick={()=>logLmEvent(EVENTS.LICENSE_ACTIVATION_LOG)} color="secondary" className="ml-2">
              License activation log
            </Button>
          </Link>
          <h3>Ground Mount</h3>
          {Table(
            columns,
            licenses.filter((license) => license.productTypeId === 0)
          )}
          <h3>Roof Mount</h3>
          {Table(
            columns,
            licenses.filter((license) => license.productTypeId === 1)
          )}
        </div>

        {AssignLicenseModal(
          modal,
          toggle,
          licensesProp.clientId,
          selectedLicenseId,
          UpdateLicenses,
          setAlertVisible,
          setAlertColor,
          setAlertText
        )}
      </div>
    </>
  );
}

export default ClientLicenses;
