import React from "react";
import { Jumbotron } from "reactstrap";
import logo from "../../assets/images/logo.png";
import WithChildren from "../WithChildren";
import HotjarTracking from "../app/metrics/HotjarTracking";

function AuthenticationContainer({ children }: WithChildren) {
  HotjarTracking();
  return (
    <div className="h-100 d-flex justify-content-center">
      <div className="row">
        <Jumbotron className="bg-white m-auto">
          <div className="mt-3 mb-5 text-center">
            <img src={logo} alt="logo" />
          </div>
          {children}
        </Jumbotron>
      </div>
    </div>
  );
}

export default AuthenticationContainer;
