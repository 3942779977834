import React from "react";
import { Column } from "react-table";
import { nameof } from "ts-simple-nameof";
import { GetDate } from "../../DateHelpers";
import ILicense from "../allLicenses/ILicense";
import UserRole from "src/components/authentication/UserRole";
import { getUserRole } from "src/components/authentication/AuthenticationService";

function CompanyAdminLicensesColumns(): Array<Column<ILicense>> {
  const userRole = getUserRole();

  let list = [
    {
      Header: "Name",
      Cell: ({ row }) => {
        return (
          <span>
            {`${row.original.name ?? ""} ${row.original.surname ?? ""}`}
          </span>
        );
      },
    },
    {      
      Header: "Email",
      accessor: nameof<ILicense>((license) => license.email),
    },    
    {
      Header: "Status",
      accessor: nameof<ILicense>((license) => license.isActive),
      Cell: ({ value }) => {
        return value ? (
          <i className="pli-yes icon-lg text-success"></i>
        ) : (
          <i className="pli-close icon-lg text-danger"></i>
        );
      },
    },
    {
      Header: "Valid until",
      accessor: nameof<ILicense>((license) => license.validTill),
      Cell: ({ value }) => {
        return <span>{GetDate(value)}</span>;
      },
    },
  ];

  if (userRole === UserRole.CompanyAdmin) {
    list.push(
      {
        Header: "Company",
        accessor: nameof<ILicense>((license) => license.companyName),
      })
  }

  return list;
}

export default CompanyAdminLicensesColumns;
