import { useTable, Column } from "react-table";
import ILicense from "../allLicenses/ILicense";
import { GetRowColor } from "../allLicenses/AllLicenses";
import React, { useMemo } from "react";

export function Table ( columns : Array<Column<ILicense>> , data : ILicense[]) {
    const tableColumns = useMemo(() => columns, [columns]);
    const { getTableBodyProps, getTableProps, headerGroups, prepareRow, rows } = useTable({ columns: tableColumns, data : data });
    if (!rows.length) {
      return <p className="font-weight-bold">
        No licenses to display
      </p>
    }
    return (
        <table
className="table table-striped table-hover table-vcenter"
{...getTableProps()}
>
<thead>
  {headerGroups.map((headerGroup) => (
    <tr
      className="text-left"
      {...headerGroup.getHeaderGroupProps()}
    >
      {headerGroup.headers.map((column) => (
        <th {...column.getHeaderProps()}>
          {column.render("Header")}
        </th>
      ))}
    </tr>
  ))}
</thead>
<tbody {...getTableBodyProps()}>
  {rows.map((row) => {
    prepareRow(row);
    return (
      <tr
        className={GetRowColor(
          row.original.isValid,
          row.original.wasNotVerifiedInLast30Days
        )}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          return (
            <td
              {...cell.getCellProps()}
              style={{
                color: "#7a878e",
                verticalAlign: "top",
                wordWrap: "break-word",
              }}
            >
              {cell.render("Cell")}
            </td>
          );
        })}
      </tr>
    );
  })}
</tbody>
</table>
    );
  };

export default Table;