import axios from "axios";
import IClient from "../components/app/clients/IClient";
import IGroup from "../components/app/groups/IGroup";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function GetCompanies() {
  return axios
    .get<IClient[]>(API_URL + "/Company/GetAllClients", {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      return response.data.length > 0 ? response.data : [];
    });
}

export function GetCompanyNameByContactId() {
  return axios
    .get<string>(`${API_URL}/Company/GetUserCompanyName`, {
      headers: { Authorization: `Bearer ${getToken().toString()}` },
    })
    .then((response) => {
      return response.data;
    })
    .catch(() => {
      return "";
    });
}

export function GetCompanyById(companyId: string) {
  return axios
    .get<IClient>(API_URL + `/Company/GetCompanyById/${companyId}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetChildCompanies(companyId: string) {
  return axios
    .get<IClient[]>(API_URL + `/Company/GetChildCompanies/${companyId}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      if (response.status === 204) return [];
      return response.data;
    });
}

export function GetCompanyUserGroups(companyId: string) {
  return axios
    .get<IGroup[]>(API_URL + `/Company/GetCompanyUserGroups/${companyId}`, {
      headers: { Authorization: "Bearer " + getToken().toString() },
    })
    .then((response) => {
      if (response.status === 204) return [];
      return response.data;
    });
}

export function UpdateCompanyGroups(companyId: string, groups: IGroup[]) {
  return axios
    .post(
      `${API_URL}/Company/UpdateCompanyGroups`,
      {
        companyId: companyId,
        groups: groups,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function AddCompany(name: string) {
  return axios
    .post<IClient>(
      `${API_URL}/Company/AddCompany`,
      {
        companyName: name,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}
