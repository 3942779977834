import React, { useEffect, useMemo, useState } from "react";
import { Column, useSortBy, useTable } from "react-table";
import { Alert } from "reactstrap";
import { GetUserCompanyContacts } from "../../../api/ContactsApi";
import { ResetPasswordAsCompanyAdmin, SoftDeleteUser } from "../../../api/UserApi";
import { GetNamesSort } from "../../SortHelper";
import IContact from "../clients/IContact";
import AppContainer from "../container/AppContainer";
import MembersColumns from "./MembersColumns";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function Members() {
  usePageTimeLogger("Members (Page)");
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");

  const sortByName = React.useMemo(() => GetNamesSort(), []);
  const [contacts, setContacts] = useState(Array<IContact>());
  const columns: Array<Column<IContact>> = useMemo(
    () => MembersColumns(ResetPassword, SoftDeleteUserWithAlert, sortByName),
    [sortByName]
  );

  useEffect(() => {
    GetUserCompanyContacts()
      .then((response) => {
        if (response.status === 200) {
          setContacts(response.data);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  const tableInstance = useTable(
    {
      columns: columns,
      data: contacts,
    },
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  function ResetPassword(contactId: string) {
    ResetPasswordAsCompanyAdmin(contactId)
      .then(() => {
        setAlertText("Password reset email sent to member email.");
        setAlertColor("info");
        setAlertVisible(true);
      })
      .catch((error) => {
        setAlertText(error.response?.data ?? error.message);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }

  function SoftDeleteUserWithAlert(userId: string) {
    SoftDeleteUser(userId)
      .then(() => {
        setAlertText(`Delete successful.`);
        setAlertColor("info");
        setAlertVisible(true);

        setContacts(
          tableInstance.data.filter((contact: IContact) => contact.userId !== userId)
        );
      })
      .catch((error) => {
        setAlertText(error.response?.data ?? error.message);
        setAlertColor("danger");
        setAlertVisible(true);
      });
  }

  return (
    <div>
      <AppContainer name={"Members"}>
        <Alert isOpen={alertVisible} color={alertColor}>
          {alertText}
        </Alert>
        <div className="panel">
          <div className="panel-body">
            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.render("Header")}
                          {column.Header !== "Actions" && (
                            <div className="sorting d-inline-block align-middle">
                              <i
                                onClick={() => column.toggleSortBy(true, false)}
                                className="pli-down"
                              ></i>
                              <i
                                onClick={() =>
                                  column.toggleSortBy(false, false)
                                }
                                className="pli-up"
                              ></i>
                            </div>
                          )}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);

                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ color: "#7a878e" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

export default Members;
