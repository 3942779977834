import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { Alert, Button, Form, Input, Label } from "reactstrap";
import { SendForgotPassword } from "../../api/UserApi";
import AuthenticationContainer from "./AuthenticationContainer";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function ForgotPassword() {
  usePageTimeLogger("Forgot Password (Page)");

  const [email, setEmail] = useState("");
  const [alertVisible, setAlertVisibility] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertColor, setAlertColor] = useState("");
  const [isLoading, setLoading] = useState(false);

  function SubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) return;
    setLoading(true);
    setAlertVisibility(false);
    SendForgotPassword(email)
      .then(() => {
        setAlertVisibility(true);
        setAlertColor("info");
        setAlertMessage(
          `If there's an account associated with this email you will\r\nreceive a password reset link. Please check your inbox.`
        );
      })
      .catch(() => {
        setAlertVisibility(true);
        setAlertColor("danger");
        setAlertMessage("Network error.");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <AuthenticationContainer>
      <Alert isOpen={alertVisible} color={alertColor}>
        <div className="new-line">{alertMessage}</div>
      </Alert>
      <Form onSubmit={SubmitForm}>
        <div className="form-group">
          <Label for="username">Email address</Label>
          <Input
            class="form-control"
            type="email"
            id="email"
            name="emailInput"
            required={true}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="w-100 h-100 text-center">
          <PropagateLoader
            color={"#1c3550"}
            loading={isLoading}
            css={" display: inline-block; "}
          />
          <div className={isLoading ? "d-none" : "row mar-no justify-content-between"}>
            <Button className="btn btn-primary">Reset password</Button>
            <Link className="btn btn-outline-secondary" to="/">
              Back
            </Link>
          </div>
        </div>
      </Form>
    </AuthenticationContainer>
  );
}

export default ForgotPassword;
