import React, {useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";

const Auth0Login: React.FC = () => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect({authorizationParams:{prompt: 'login'}});
    }, [loginWithRedirect]);

    return null;
};

export default Auth0Login;