import React, { useEffect, useMemo, useState } from "react";
import { Column, useTable } from "react-table";
import { GetUserLicense } from "../../../api/LicensesApi";
import ILicense from "../allLicenses/ILicense";
import IBuild from "../builds/IBuild";
import AppContainer from "../container/AppContainer";
import CompanyUserLicensesColumns from "./CompanyUserLicensesColumns";
import { GetGroundMountLatestBuild, GetRoofMountLatestBuild } from "src/api/BuildsApi";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function CompanyUserLicenses() {
  usePageTimeLogger("My license (Page)");
  const [licenses, setLicenses] = useState(Array<ILicense>());
  const [latestGmBuild, setLatestGmBuild] = useState<IBuild | undefined>()
  const [latestRmBuild, setLatestRmBuild] = useState<IBuild | undefined>()

  useEffect(() => {
    if  (latestGmBuild === undefined) {
      GetGroundMountLatestBuild().then(response => setLatestGmBuild(response) )
    }

    if(latestRmBuild === undefined)
    {
      GetRoofMountLatestBuild().then((response) => {setLatestRmBuild(response);
      })
    }
    
  }, [latestRmBuild, latestGmBuild])

  const columns: Array<Column<ILicense>> = useMemo(
    () => CompanyUserLicensesColumns(latestGmBuild, latestRmBuild),
    [latestGmBuild, latestRmBuild]
  );

  useEffect(() => {
    GetUserLicense()
      .then((license) => {
        if (license) setLicenses(license);
      })
      .catch((error) => console.log(error));
  }, []);

  const tableInstance = useTable({
    columns: columns,
    data: licenses,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
  } = tableInstance;

  return (
    <AppContainer name={""}>
      <div className="panel">
        <div className="panel-body">
          <table
            className="table table-striped table-hover table-vcenter"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  className="text-left"
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr  {...row.getRowProps()} style={{ height: 58 }}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          style={{
                            color: "#7a878e",
                            verticalAlign: "middle",
                            width: "30%"
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </AppContainer>
  );
}

export default CompanyUserLicenses;
