import axios from "axios";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function postEvent (eventName, eventProperties) {
    return axios.post(`${API_URL}/EventLogging/PostLmEvent`,
      {
        EventName: eventName,
        EventProperties: eventProperties
      },
      {
        headers: {
          Authorization: "Bearer " + getToken(),
          "Content-Type": "application/json",
        },
      });
  };