import React from "react";
import "./assets/scss/theme.scss";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginForm from "./components/authentication/LoginForm";
import ForgotPassword from "./components/authentication/ForgotPassword";
import Builds from "./components/app/builds/BuildsGm";
import {
  FORGOT_PASSWORD_URL,
  ALL_LICENSES_URL,
  BUILDS_GM_URL,
  ADD_BUILD_URL,
  EDIT_BUILD_URL,
  SETTINGS_URL,
  CLIENTS_URL,
  CLIENTS_MANAGE_URL,
  GROUPS_URL,
  CHANGE_PASSWORD_URL,
  DOWNLOAD_BUILDS_URL,
  LICENSES_URL,
  COMPANY_LICENSES_URL,
  MEMBERS_URL,
  ALL_LICENSES_URL_ALLOWED_ROLES,
  ALL_ROLES,
  MEMBERS_URL_ALLOWED_ROLES,
  EDIT_BUILD_URL_ALLOWED_ROLES,
  BUILDS_GM_URL_ALLOWED_ROLES,
  CLIENTS_MANAGE_URL_ALLOWED_ROLES,
  CLIENTS_ALLOWED_ROLES,
  GROUPS_URL_ALLOWED_ROLES,
  ADD_BUILD_URL_ALLOWED_ROLES,
  COMPANY_LICENSES_URL_ALLOWED_ROLES,
  LICENSE_TRANSFER_LOG_URL,
  LICENSE_TRANSFER_LOG_URL_ALLOWED_ROLES,
  CREATE_COMPANY_URL,
  CREATE_COMPANY_ALLOWED_ROLES,
  LICENSE_ACTIVATION_LOG_URL,
  LICENSE_ACTIVATION_LOG_URL_ALLOWED_ROLES,
  SYNC_LOGS_URL,
  SYNC_LOGS_URL_ALLOWED_ROLES,
  SYNC_LOGS_BY_DAYS_URL,
  SYNC_LOGS_BY_DAYS_URL_ALLOWED_ROLES,
  DOWNLOAD_BUILDS_RM_URL,
  BUILDS_RM_URL,
  BUILDS_RM_URL_ALLOWED_ROLES,
  GM_SURVEYS_URL,
  RM_SURVEYS_URL,
  SURVEYS_URL_ALLOWED_ROLES,
  SURVEY_RESULTS_URL,
  SURVEY_RESULTS_ALLOWED_ROLES,
  EDIT_SURVEY_URL,
  EDIT_SURVEY_ALLOWED_ROLES,
  CREATE_SURVEY_URL,
  CREATE_SURVEY_ALLOWED_ROLES,
  LICENSES_URL_ALLOWED_ROLES
} from "./Constants";
import EditBuild from "./components/app/builds/EditBuild";
import AddBuild from "./components/app/builds/AddBuild";
import AllLicenses from "./components/app/allLicenses/AllLicenses";
import Clients from "./components/app/clients/Clients";
import Settings from "./components/app/user/Settings";
import ManageClient from "./components/app/clients/manageClient/ManageClient";
import Groups from "./components/app/groups/Groups";
import { PrivateRoute } from "./PrivateRoute";
import ChangePassword from "./components/authentication/ChangePassword";
import DownloadBuilds from "./components/app/builds/DownloadBuildsGm";
import CompanyAdminLicenses from "./components/app/licenses/CompanyAdminLicenses";
import CompanyUserLicenses from "./components/app/licenses/CompanyUserLicenses";
import Members from "./components/app/members/Members";
import LicenseTransferLog from "./components/app/clients/manageClient/LicenseTransferLog";
import CreateCompany from "./components/app/clients/CreateCompany";
import LicenseActivationLog from "./components/app/clients/manageClient/LicenseActivationLog";
import SyncLogs from "./components/app/syncLogs/SyncLogs";
import SyncLogsByDays from "./components/app/syncLogs/SyncLogsByDays";
import DownloadBuildsRm from "./components/app/builds/DownloadBuildRm";
import RmBuilds from "./components/app/builds/BuildsRm";
import SurveysGM from "./components/app/surveys/SurveysGM";
import SurveyResults from "./components/app/surveys/SurveyResults";
import EditSurvey from "./components/app/surveys/EditSurvey";
import CreateSurvey from "./components/app/surveys/CreateSurvey";
import SurveysRM from "./components/app/surveys/SurveysRM";
import CookiesModal from "./components/app/modals/Cookies";
import CallbackRoute from "./components/authentication/AuthenticationCallbackHandler";
import AuthCallbackRoute from "./components/authentication/AuthenticationAuth0Handler";
import Auth0Login from "./components/authentication/Auth0Login";
import UnauthorizedInterceptor from "./components/authentication/UnauthorizedInterceptor";

function App() {
  return (
    <BrowserRouter>
      <UnauthorizedInterceptor />
      <CookiesModal />
      <Switch>
        <PrivateRoute
          path={ALL_LICENSES_URL}
          allowedRoles={ALL_LICENSES_URL_ALLOWED_ROLES}
        >
          <AllLicenses />
        </PrivateRoute>
        <PrivateRoute path={LICENSES_URL} allowedRoles={LICENSES_URL_ALLOWED_ROLES}>
          <CompanyUserLicenses />
        </PrivateRoute>
        <PrivateRoute
          path={COMPANY_LICENSES_URL}
          allowedRoles={COMPANY_LICENSES_URL_ALLOWED_ROLES}
        >
          <CompanyAdminLicenses />
        </PrivateRoute>
        <PrivateRoute
          path={MEMBERS_URL}
          allowedRoles={MEMBERS_URL_ALLOWED_ROLES}
        >
          <Members />
        </PrivateRoute>
        <PrivateRoute
          path={ADD_BUILD_URL}
          allowedRoles={ADD_BUILD_URL_ALLOWED_ROLES}
        >
          <AddBuild />
        </PrivateRoute>
        <PrivateRoute
          path={`${EDIT_BUILD_URL}/:buildId`}
          allowedRoles={EDIT_BUILD_URL_ALLOWED_ROLES}
        >
          <EditBuild />
        </PrivateRoute>
        <PrivateRoute
          path={`${BUILDS_GM_URL}`}
          allowedRoles={BUILDS_GM_URL_ALLOWED_ROLES}
        >
          <Builds />
        </PrivateRoute>
        <PrivateRoute
          path={`${BUILDS_RM_URL}`}
          allowedRoles={BUILDS_RM_URL_ALLOWED_ROLES}
        >
          <RmBuilds />
        </PrivateRoute>
        <PrivateRoute path={`${DOWNLOAD_BUILDS_URL}`} allowedRoles={ALL_ROLES}>
          <DownloadBuilds />
        </PrivateRoute>
        <PrivateRoute
          path={`${DOWNLOAD_BUILDS_RM_URL}`}
          allowedRoles={ALL_ROLES}
        >
          <DownloadBuildsRm />
        </PrivateRoute>
        <Route path={FORGOT_PASSWORD_URL}>
          <ForgotPassword />
        </Route>
        <PrivateRoute
          path={`${CLIENTS_MANAGE_URL}/:clientId`}
          allowedRoles={CLIENTS_MANAGE_URL_ALLOWED_ROLES}
        >
          <ManageClient />
        </PrivateRoute>
        <PrivateRoute
          path={`${LICENSE_TRANSFER_LOG_URL}/:clientId`}
          allowedRoles={LICENSE_TRANSFER_LOG_URL_ALLOWED_ROLES}
        >
          <LicenseTransferLog />
        </PrivateRoute>
        <PrivateRoute
          path={`${LICENSE_ACTIVATION_LOG_URL}/:clientId`}
          allowedRoles={LICENSE_ACTIVATION_LOG_URL_ALLOWED_ROLES}
        >
          <LicenseActivationLog />
        </PrivateRoute>
        <PrivateRoute path={CLIENTS_URL} allowedRoles={CLIENTS_ALLOWED_ROLES}>
          <Clients />
        </PrivateRoute>
        <PrivateRoute
          path={CREATE_COMPANY_URL}
          allowedRoles={CREATE_COMPANY_ALLOWED_ROLES}
        >
          <CreateCompany />
        </PrivateRoute>
        <PrivateRoute path={SETTINGS_URL} allowedRoles={ALL_ROLES}>
          <Settings />
        </PrivateRoute>
        <PrivateRoute path={GROUPS_URL} allowedRoles={GROUPS_URL_ALLOWED_ROLES}>
          <Groups />
        </PrivateRoute>
        <PrivateRoute
          path={SYNC_LOGS_BY_DAYS_URL}
          allowedRoles={SYNC_LOGS_BY_DAYS_URL_ALLOWED_ROLES}
        >
          <SyncLogsByDays />
        </PrivateRoute>
        <PrivateRoute
          path={`${SYNC_LOGS_URL}/:date`}
          allowedRoles={SYNC_LOGS_URL_ALLOWED_ROLES}
        >
          <SyncLogs />
        </PrivateRoute>
        <PrivateRoute
          path={GM_SURVEYS_URL}
          allowedRoles={SURVEYS_URL_ALLOWED_ROLES}
        >
          <SurveysGM />
        </PrivateRoute>
        <PrivateRoute
          path={RM_SURVEYS_URL}
          allowedRoles={SURVEYS_URL_ALLOWED_ROLES}
        >
          <SurveysRM />
        </PrivateRoute>
        <PrivateRoute
          path={`${SURVEY_RESULTS_URL}/:surveyId`}
          allowedRoles={SURVEY_RESULTS_ALLOWED_ROLES}
        >
          <SurveyResults />
        </PrivateRoute>
        <PrivateRoute
          path={`${EDIT_SURVEY_URL}/:productType/:surveyId`}
          allowedRoles={EDIT_SURVEY_ALLOWED_ROLES}
        >
          <EditSurvey />
        </PrivateRoute>
        <PrivateRoute
          path={`${CREATE_SURVEY_URL}/:productType`}
          allowedRoles={CREATE_SURVEY_ALLOWED_ROLES}
        >
          <CreateSurvey />
        </PrivateRoute>
        <Route path={`${CHANGE_PASSWORD_URL}/:token`}>
          <ChangePassword />
        </Route>
        <Route path="/callback">
          <CallbackRoute />
        </Route>
        <Route path="/auth">
          <AuthCallbackRoute />
        </Route>
        <Route path="/login">
          <Auth0Login />
        </Route>
        <Route path="/">
          <LoginForm />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
