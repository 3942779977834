import { ADD_RM_BUILD_URL, ADD_BUILD_URL } from "src/Constants";
import Build from "./Build";

function AddBuild() {
    return Build({ buildId: undefined, pageName: "Add build" , url: ADD_BUILD_URL});
}

export function AddRmBuild()
{
    return Build({buildId: undefined, pageName: "Add RM build", url: ADD_RM_BUILD_URL})
}

export default AddBuild;