import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppContainer from "../../container/AppContainer";
import ClientChildCompanies from "./ClientChildCompanies";
import ClientLicenses from "./ClientLicenses";
import ClientMembers from "./ClientMembers";
import ClientSettings from "./ClientSettings";
import { Button } from "reactstrap";
import usePageTimeLogger from "src/assets/services/EventHandlerService";

function ManageClient() {
  usePageTimeLogger("Manage clients (Page)");

  const { clientId } = useParams<{ clientId: string }>();

  const [companyName, setCompanyName] = useState("");
  const history = useHistory();

  return (
    <div>
      <AppContainer name={companyName}>
        <Button
          onClick={() => history.goBack()}
          className="btn btn-outline-secondary"
          style={{ marginBottom: "2rem" }}
        >
          Back
        </Button>
        <ClientSettings clientId={clientId} setCompanyName={setCompanyName} />
        <ClientChildCompanies clientId={clientId}></ClientChildCompanies>
        <ClientLicenses clientId={clientId}></ClientLicenses>
        <ClientMembers clientId={clientId}></ClientMembers>
      </AppContainer>
    </div>
  );
}

export default ManageClient;
