import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { GetCompanyContacts } from "../../../../../api/ContactsApi";
import { AssignLicense } from "../../../../../api/LicensesApi";
import { getUserEmail } from "../../../../authentication/AuthenticationService";
import IContact from "../../IContact";
import TextLabel from "../../../forms/TextLabel";

function AssignLicenseModal(
  modal: boolean,
  modalToggle: () => void,
  clientId: string,
  licenseId: string,
  update: () => void,
  setAlertVisible: (isVisible: boolean) => void,
  setAlertColor: (color: string) => void,
  setAlertText: (text: string) => void
) {
  const [contacts, setContacts] = useState(Array<IContact>());
  const [selectedContact, setSelectedContact] = useState<IContact | undefined>(
    undefined
  );

  useEffect(() => {
    UpdateContacts();
    // eslint-disable-next-line
  }, [clientId]);

  return (
    <Modal
      isOpen={modal}
      toggle={modalToggle}
      backdrop={true}
      className="modal-centered modal-body"
    >
      <ModalHeader toggle={modalToggle}>Assign license</ModalHeader>
      <ModalBody>
        {TextLabel("Select a member:")}
        <UncontrolledDropdown className="ml-3" direction="down">
          <DropdownToggle className="dropdown-input" caret>
            {GetName(selectedContact)}
          </DropdownToggle>
          <DropdownMenu className="dropdown-scroll">
            {contacts.map((contact) => (
              <DropdownItem
                key={contact.id}
                onClick={() => {
                  setSelectedContact(contact);
                }}
              >
                {GetName(contact)}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            AssignLicenseToContact(
              selectedContact,
              licenseId,
              update,
              UpdateContacts,
              setAlertVisible,
              setAlertColor,
              setAlertText,
            );
            modalToggle();
          }}
        >
          Assign
        </Button>
        <Button color="secondary" onClick={modalToggle}>
          Close
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );

  function UpdateContacts() {
   let promise = GetCompanyContacts(clientId);
   promise
      .then((response) => {
        if (response.status === 200) {
          setContacts(response.data);

          if (response.data !== undefined && response.data.length > 0) {
            setSelectedContact(response.data[0]);
          }
        }
      })
      .catch((error) => console.error(error));
  }
}

function GetName(
  contact: IContact | undefined
): string {
  return contact === undefined
    ? ""
    : `${contact.name} ${contact.surname} ${
        contact.hasLicense ? "(In use)" : ""
      }${contact.email === getUserEmail() ? "(Me)" : ""}`;
}

function AssignLicenseToContact(
  contact: IContact | undefined,
  licenseId: string,
  update: () => void,
  UpdateContacts: () => void,
  setAlertVisible: (isVisible: boolean) => void,
  setAlertColor: (color: string) => void,
  setAlertText: (text: string) => void
) {
  if (contact === undefined) {
    return;
  }

  if(contact.hasLicense){
    setAlertText("Member already has license assigned.");
    setAlertColor("danger");
    setAlertVisible(true);
    return;
  }

  AssignLicense(licenseId, contact.id)
    .then(() => {
      update();
      UpdateContacts();
      setAlertText(
        `License was assigned to ${contact.name} ${contact.surname} successfully.`
      );
      setAlertColor("info");
      setAlertVisible(true);
    })
    .catch((error) => {
      console.log(error);
      setAlertText(error.response?.data ?? error.message);
      setAlertColor("danger");
      setAlertVisible(true);
    });
}

export default AssignLicenseModal;
