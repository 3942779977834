import React, { useEffect, useMemo, useState } from "react";
import ILicense from "./ILicense";
import AppContainer from "../container/AppContainer";
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import LicenseColumns from "./LicenseColumns";
import CustomPagination from "../CustomPagination";
import { Button, Input } from "reactstrap";
import GroupFiltering, {
  FilterByGroups,
  GetAvailableGroups,
  ResetAvailableGroups,
} from "../groups/GroupFiltering";
import { IsStringIncludes } from "../../StringsExtensions";
import IpFilterModal from "./IpFilterModal";
import IGroup from "../groups/IGroup";
import { GetDateWithTimeSort } from "../../SortHelper";
import { GetLicenses } from "../../../api/LicensesApi";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function AllLicenses() {
  usePageTimeLogger("All licenses (Page)");

  const dateWithTimeSort = React.useMemo(() => GetDateWithTimeSort(), []);
  const columns: Array<Column<ILicense>> = useMemo(
    () => LicenseColumns(dateWithTimeSort),
    [dateWithTimeSort]
  );

  const [licenses, setLicenses] = useState(Array<ILicense>());
  const [data, setData] = useState(licenses);
  useEffect(() => {
    GetLicenses()
      .then((licenses) => {
        var data = licenses.map((license) => {
          if (license.groups === undefined) {
            license.groups = [];
          }
          return license;
        });

        setLicenses(data);
        setData(data);
        setAvailableGroups(GetAvailableGroups(data));
      })
      .catch((error) => console.log(error));
  }, []);

  const [availableGroups, setAvailableGroups] = useState(
    GetAvailableGroups(licenses)
  );
  const [licenseIdFilter, setLicenseIdFilter] = useState("");
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [ipFilter, setIpFilter] = useState("");
  const [groupModal, setGroupModal] = useState(false);
  const toggleGroup = () => setGroupModal(!groupModal);
  const [ipModal, setIpModal] = useState(false);
  const toggleIp = () => setIpModal(!ipModal);

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
      initialState: { pageIndex: 0, pageSize: 20 },
    },

    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex },
  } = tableInstance;

  function handleKeyPress(logName:string, value:any, event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === "Enter") {
      logLmEvent(logName,{Value:value})
      FilterLicenses(
        licenses,
        licenseIdFilter,
        companyNameFilter,
        emailFilter,
        ipFilter,
        availableGroups,
        setData
      );
    }
  }

  function ClearFilters() {
    setLicenseIdFilter("");
    setCompanyNameFilter("");
    setEmailFilter("");
    setIpFilter("");
    const updatedAvailableGroups = ResetAvailableGroups(availableGroups);
    setAvailableGroups(updatedAvailableGroups);
    FilterLicenses(licenses, "", "", "", "", updatedAvailableGroups, setData);
  }

  function Filter() {
    FilterLicenses(
      licenses,
      licenseIdFilter,
      companyNameFilter,
      emailFilter,
      ipFilter,
      availableGroups,
      setData
    );
  }

  return (
    <div>
      <AppContainer name="All licenses">
        <div className="panel">
          <div className="panel-body">
            <table
              className="table table-striped table-hover table-vcenter"
              {...getTableProps()}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    className="text-left"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        <div>
                          {column.render("Header")}
                          <div className="sorting d-inline-block align-middle">
                            <i
                              onClick={() => column.toggleSortBy(true, false)}
                              className="pli-down"
                            ></i>
                            <i
                              onClick={() => column.toggleSortBy(false, false)}
                              className="pli-up"
                            ></i>
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
                <tr className="filters-row">
                  <th></th>
                  <th className="text-left">
                    <Input
                      size={7}
                      className="form-control"
                      value={licenseIdFilter}
                      onChange={(e) => setLicenseIdFilter(e.target.value)}
                      onKeyPress={(e)=>{
                        handleKeyPress(EVENTS.SEARCH_LICENSE_ID,licenseIdFilter,e)}}
                    ></Input>
                  </th>
                  <th className="text-left">
                    <Input
                      className="form-control"
                      value={companyNameFilter}
                      onChange={(e) => setCompanyNameFilter(e.target.value)}
                      onKeyPress={(e)=>handleKeyPress(EVENTS.SEARCH_COMPANY_NAME,companyNameFilter,e)}
                    ></Input>
                  </th>
                  <th className="text-left">
                    <Input
                      className="form-control"
                      value={emailFilter}
                      onChange={(e) => setEmailFilter(e.target.value)}
                      onKeyPress={(e)=>handleKeyPress(EVENTS.SEARCH_EMAIL,emailFilter,e)}
                    ></Input>
                  </th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th className="text-right" colSpan={2}>
                    <div className="btn-group">
                      <Button
                        className="btn-sm btn-primary js-filter-by-groups"
                        onClick={toggleIp}
                      >
                        IP
                      </Button>
                    </div>
                    {" "}
                    <div className="btn-group">
                      <Button
                        className="btn-sm btn-primary js-filter-by-groups"
                        onClick={toggleGroup}
                      >
                        <i className="pli-business-mens"></i>
                      </Button>
                    </div>
                    {" "}
                    <div className="btn-group">
                      <Button
                        className="btn-sm btn-primary"
                        onClick={() =>
                          FilterLicenses(
                            licenses,
                            licenseIdFilter,
                            companyNameFilter,
                            emailFilter,
                            ipFilter,
                            availableGroups,
                            setData
                          )
                        }
                      >
                        <i className="pli-magnifi-glass"></i>
                      </Button>
                      <Button
                        className="btn-sm btn-outline-secondary"
                        onClick={() => ClearFilters()}
                      >
                        <i className="pli-cross"></i>
                      </Button>
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);

                  return (
                    <tr
                      className={GetRowColor(
                        row.original.isValid,
                        row.original.wasNotVerifiedInLast30Days
                      )}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ color: "#7a878e" }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {CustomPagination(pageOptions, pageIndex, tableInstance)}
            {GroupFiltering(
              groupModal,
              toggleGroup,
              Filter,
              availableGroups,
              setAvailableGroups
            )}
            {IpFilterModal(ipModal, toggleIp, Filter, ipFilter, setIpFilter)}
          </div>
        </div>
      </AppContainer>
    </div>
  );
}

function FilterLicenses(
  licenses: Array<ILicense>,
  licenseIdFilter: string,
  companyNameFilter: string,
  emailFilter: string,
  ipFilter: string,
  availableGroups: Array<IGroup>,
  setData: (data: Array<ILicense>) => void
) {
  let licenseIdFilterTrimmed = licenseIdFilter.trim();
  let companyNameFilterTrimmed = companyNameFilter.trim();
  let emailFilterTrimmed = emailFilter.trim();
  let ipFilterTrimmed = ipFilter.trim();

  let newData = licenses.filter(
    (license) =>
      (licenseIdFilterTrimmed === "" ||
        IsStringIncludes(license.id.toString(), licenseIdFilterTrimmed)) &&
      (companyNameFilterTrimmed === "" ||
        IsStringIncludes(license.companyName, companyNameFilterTrimmed)) &&
      (emailFilterTrimmed === "" ||
        IsStringIncludes(license.email ?? "", emailFilterTrimmed)) &&
      (ipFilterTrimmed === "" ||
        IsStringIncludes(license.ip ?? "", ipFilterTrimmed))
  );
  newData = FilterByGroups(newData, availableGroups) as ILicense[];
  setData(newData);
}

export function GetRowColor(
  isValid: boolean,
  wasNotVerifiedInLast30Days: boolean
) {
  if (isValid) {
    return wasNotVerifiedInLast30Days ? "bg-warning" : undefined;
  }

  return "bg-light-danger";
}

export default AllLicenses;
