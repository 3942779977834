import React from "react";
import { Column, SortByFn } from "react-table";
import { UncontrolledTooltip } from "reactstrap";
import { nameof } from "ts-simple-nameof";
import { GetDate, GetDateWithTime } from "../../DateHelpers";
import ILicense from "./ILicense";

function LicenseColumns(
  dateWithTimeSort: SortByFn<any>
): Array<Column<ILicense>> {
  return [
    {
      Header: "Product",
      accessor: nameof<ILicense>((license) => license.productTypeName),
    },
    {
      Header: "License ID",
      accessor: nameof<ILicense>((license) => license.id),
    },
    {
      Header: "Company name",
      accessor: nameof<ILicense>((license) => license.companyName),
    },
    {
      Header: "Email",
      accessor: nameof<ILicense>((license) => license.email),
    },
    {
      Header: "Valid till",
      accessor: nameof<ILicense>((license) => license.validTill),
      Cell: ({ value }) => {
        return <span>{GetDate(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Status",
      accessor: nameof<ILicense>((license) => license.isActive),
      Cell: ({ value }) => {
        return value ? (
          <i className="pli-yes icon-lg text-success"></i>
        ) : (
          <i className="pli-close icon-lg text-danger"></i>
        );
      },
      sortType: "basic",
    },
    {
      Header: "Activation date",
      accessor: nameof<ILicense>((license) => license.activationDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Last verify",
      accessor: nameof<ILicense>((license) => license.lastVerificationDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Created",
      accessor: nameof<ILicense>((license) => license.dateCreated),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Type",
      accessor: nameof<ILicense>((license) => license.type),
    },
    {
      Header: "Version",
      accessor: nameof<ILicense>((license) => license.pvcaseVersion),
      Cell: ({ value, row }) => {
        const tooltipId = "tooltip" + row.id;
        const license = row.original;
        const showTooltip =
          license.productName !== undefined ||
          license.autocadVersion !== undefined ||
          license.windowsVersion !== undefined ||
          license.ip !== undefined;
        return (
          <div>
            <span id={tooltipId}>{value}</span>
            {showTooltip && (
              <UncontrolledTooltip
                placement="left"
                autohide={false}
                target={tooltipId}
              >
                {license.productName !== undefined && (
                  <div>Autodesk product name: {license.productName}</div>
                )}
                {license.windowsVersion !== undefined && (
                  <div>Windows version: {license.windowsVersion}</div>
                )}
                {license.ip !== undefined && <div>IP: {license.ip}</div>}
              </UncontrolledTooltip>
            )}
          </div>
        );
      },
    },
    {
      Header: "Remote Control Verification",
      accessor: nameof<ILicense>((license) => license.remoteDesktopValidation),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
  ];
}

export default LicenseColumns;
