import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { getCookie, setCookie } from '../helpers/Cookies';

interface CookiesModalProps {
  isOpenProp?: boolean;
  toggle?: (isOpen?: boolean) => void;
  onConsentChange?: (consent: boolean) => void;
}

const CookiesModal: React.FC<CookiesModalProps> = ({ isOpenProp, toggle, onConsentChange }) => {
  const [isOpenInternal, setIsOpenInternal] = useState(false);
  const location = useLocation();
  const trackingVariable = 'isTrackingAccepted';

  useEffect(() => {
    if (isOpenProp === undefined) {
      if (location.pathname !== "/" && location.pathname !== "/callback"  && getCookie(trackingVariable) === undefined) {
        setIsOpenInternal(true);
      }
    }
  }, [location, isOpenProp]);

  const handleAgree = () => {
    setCookie(trackingVariable, 'true', 365);
    onConsentChange && onConsentChange(true);
    closeHandler();
  };

  const handleCancel = () => {
    setCookie(trackingVariable, 'false', 365);
    onConsentChange && onConsentChange(false);
    closeHandler();
  };

  const closeHandler = () => {
    if (toggle) {
      toggle(false);
    } else {
      setIsOpenInternal(false);
    }
  };

  const isOpen = isOpenProp !== undefined ? isOpenProp : isOpenInternal;

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} toggle={closeHandler} backdrop="static">
      <ModalHeader><b>Consent for personal data processing</b></ModalHeader>
      <ModalBody className='scrollable-modal-body'>
        By choosing “Accept” you agree to the processing of personal data for analytical purposes:
        <ul>
          <li>
            <b>Using Hotjar analytical tool</b> (technology service that 
            helps us better understand our users’ 
            experience (e.g. how much time they spend on which pages, which 
            links they choose to click, what users do and don’t like.)).
          </li>
          <li>
            <b>Using Mixpanel analytical tool</b> (technology services that 
            help us better understand user’s actions, time spent on the website).
          </li>
        </ul>
        More in-depth information on the Consent given and how we process your 
        personal data, are provided below.
        <div className='h6 mt-4'>Purpose and legal basis</div>
        In addition to information regarding the processing of your personal 
        data provided, we (PVcase UAB, code 304839853 and its subsidiaries) 
        would like to collect data to improve our users` experience using our 
        service through third party analytical tools. Please note that we will 
        only process such data provided you give your consent.
        <div className='h6 mt-4'>Hotjar</div>
        We use Hotjar, an analytical tool of Hotjar Ltd, Dragonara Business Centre 
        5th Floor, Dragonara Road, Paceville St Julian`s STJ 3141 Malta, Europe, 
        in order to better understand our users’ needs and to optimize this service 
        and experience. Hotjar is a technology service that helps us better 
        understand our users’ experience (e.g. how much time they spend on which 
        pages, which links they choose to click, what users do and don’t like.) 
        and this enables us to build and maintain our service with user feedback. 
        Hotjar uses cookies and other technologies to collect data on our users’ 
        behavior and their devices. This includes:
        <ul>
          <li>
            a device's IP address (processed during your session and stored in a de-identified form), 
          </li>
          <li>
            device screen size, device type (unique device identifiers), 
          </li>
          <li>
            browser information, 
          </li>
          <li>
            geographic location (country only),
          </li>
          <li>
            the preferred language used to display our website. 
          </li>
        </ul>
        Hotjar stores this above information on our behalf in a pseudonymized user profile. 
        Hotjar is contractually forbidden to sell any of the data collected on our behalf.
        For further details how Hotjar handles personal data can be found here:&nbsp;
        <a  href='https://www.hotjar.com/privacy/' 
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary">
              https://www.hotjar.com/privacy/
        </a>. 
        <div className='h6 mt-4'>Mixpanel</div>
        We use Mixpanel to track and visualize user behavior in order to understand and 
        improve user experience and interactions with our products and services. 
        The following information is collected by Mixpanel in connection with your 
        use of this webpage:
        <ul>
          <li>
            clicks and actions, 
          </li>
          <li>
            time spent, 
          </li>
          <li>
            account information (name, email, license type, company name),
          </li>
          <li>
            location (Country and IP address). 
          </li>
        </ul>
        Above stated information will be transmitted to a server of Mixpanel in the 
        EU where it will be stored, processed and made available to us for further analysis. 
        More information on how Mixpanel handles personal data can be found at:&nbsp;
        <a  href='https://mixpanel.com/legal/mixpanel-gdpr' 
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary">
              https://mixpanel.com/legal/mixpanel-gdpr
        </a>.
        <div className='h6 mt-4'>Retention period</div>
        We will process data for analytic purposes until the withdrawal of your consent 
        or until we stop using above-mentioned analytical tools. Approximately every 
        five years, PVcase will review its existing consents to make sure if this is 
        still relevant for you.
        <div className='h6 mt-4'>Your rights related to consent</div>
        In addition to your rights under section below <b>Your rights related to data protection</b>, 
        you have the right, without any negative consequences, to withdraw your consent at 
        any time later. This can be done by going to settings and turning off the 
        “Consent tracking” function or by writing to us to&nbsp;
        <a  href='mailto:legal@pvcase.com' 
            className="text-primary">
            legal@pvcase.com
        </a>.
        <div className='h6 mt-4'>Login</div>
        In order for you to receive a license, you must log into account with your 
        personal information. For this purpose we collect the following information 
        about you: e-mail address and password. Aforementioned data will be retained 
        as long as you actively use our services. In addition, we are using token-based 
        authentication which allows you to verify your identity. This means you do 
        not have to be reidentified if you are visiting our website within one hour time frame.
        <div className='h6 mt-4'>External tools</div>
        Occasionally, at our discretion, we may include or use third party products or 
        services through our Services. These third-party sites have separate and independent 
        privacy policies. We, therefore, have no responsibility or liability for the content 
        and activities of these linked sites. Nonetheless, we seek to protect the integrity 
        of our Services and welcome any feedback about these sites.
        <div className='h6 mt-4'>Processing of information outside the EU / the EEA</div>
        Your information may also be processed in countries outside the European Union 
        (“EU”) or the European Economic Area (“EEA”), which does not have adequate levels 
        of protection, as the EU.
        <p>
        According to General Data Protection Regulation (EU) 2016/679 (GDPR), in such cases, 
        the transfer will be based on a European Commission adequacy decision or subject to 
        appropriate safeguards, for example the&nbsp;
        <a  href='https://eur-lex.europa.eu/eli/dec_impl/2021/914/oj' 
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary">
              Standard Contractual Clauses
        </a>&nbsp;adopted by the European Commission, or in the absence of any of the appropriate 
        safeguards mentioned above, we may use the exceptions provided in Article 49 of the GDPR.
        </p>
        <div className='h6 mt-4'>Your rights related to data protection</div>
        You may have some or all of the following rights in respect of information about 
        you that we process: (i) request us to give you access to it; (ii) request us to rectify 
        it, update it, or erase it; (iii) request us to restrict our usage, in certain 
        circumstances; (iv) object to our usage of it, in certain circumstances; (v) withdraw 
        your consent to our usage of it; (vi) data portability, in certain circumstances; (vii) 
        opt out from our usage for direct marketing; and (viii) lodge a complaint with the 
        supervisory authority in your country.
        <div className='h6 mt-4'>Contact information</div>
        To withdraw your Consent or for any questions you may have with respect to data privacy, 
        please write an e-mail to&nbsp;
        <a  href='mailto:legal@pvcase.com' 
            className="text-primary">
            legal@pvcase.com
        </a>.
        <div className='h6 mt-4'>Cookie policy</div>
        Cookies used for this purpose help us observe the performance of the website and 
        analyse how we can improve our website&nbsp;
        <a  href='https://pvcase.com/cookie-policy/' 
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary">
              Review our policy
        </a>.
        <p>LAST UPDATED: MAY 29, 2024</p>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleCancel} className="flex-grow-1">Decline</Button>
        <Button color="primary" onClick={handleAgree} className="flex-grow-1">Accept</Button>
      </ModalFooter>
    </Modal>
  );
}

export default CookiesModal;
