import { hotjar } from "react-hotjar";
import { getCookie } from "../helpers/Cookies";

function HotjarTracking() {
  const trackingVariable = 'isTrackingAccepted';
  if(getCookie(trackingVariable)==='true'){
    var hjid = Number(process.env.REACT_APP_HOTJAR_ID);
    var hjsv = Number(process.env.REACT_APP_HOTJAR_SV);
    hotjar.initialize(hjid, hjsv);
  }
}

export default HotjarTracking;
