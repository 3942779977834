import React, { useEffect, useState } from "react";
import AppContainer from "../container/AppContainer";
import {
  ISurveyChoiceQuestion,
  ISurveyOpenTextQuestion,
  ISurveyRatingQuestion,
  ISurveyNewQuestion,
} from "./ISurveyQuestions";
import OpenTextQuestion from "./questionElements/OpenTextQuestion";
import { Alert, Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import ChoiceQuestion from "./questionElements/ChoiceQuestion";
import RatingQuestion from "./questionElements/RatingQuestion";
import NewQuestion from "./questionElements/NewQuestion";
import {
  AddChoiceQuestion,
  AddOpenTextQuestion,
  AddRatingQuestion,
  AddSurvey,
  DeleteChoiceQuestion,
  DeleteOpenTextQuestion,
  DeleteRatingQuestion,
  GetRandomReceivers,
  GetSurvey,
  UpdateChoiceQuestion,
  UpdateOpenTextQuestion,
  UpdateRatingQuestion,
  UpdateSurvey,
  VerifySurveyLicenses,
} from "src/api/SurveyApi";
import ISurvey from "./ISurvey";
import QuestionTypes from "./questionTypes";
import { WithContext as ReactTags } from "react-tag-input";
import TextField from "../forms/TextField";
import { useHistory } from "react-router-dom";
import { EVENTS, GM_SURVEYS_URL, RM_SURVEYS_URL } from "src/Constants";
import "../../../assets/scss/survey/reactTags.css";
import { Guid } from "guid-typescript";
import IProductType from "../allLicenses/IProductType";
import { GetProductTypes } from "src/api/LicensesApi";
import Checkbox from "../helpers/Checkbox";
import usePageTimeLogger, { logLmEvent } from "src/assets/services/EventHandlerService";

type SurveyProps = {
  surveyId?: number;
  productType?: number;
};

type QuestionUpdate = {
  type: QuestionTypes;
  id: number;
};

type Question =
  | ISurveyOpenTextQuestion
  | ISurveyChoiceQuestion
  | ISurveyRatingQuestion
  | ISurveyNewQuestion;

function Survey({ surveyId, productType }: SurveyProps) {
  usePageTimeLogger(surveyId?"Edit survey (Page)":"Add survey (Page)");

  const isAddNewMode = surveyId === undefined;
  const returnUrl = (productType === undefined || productType === 0) ? GM_SURVEYS_URL : RM_SURVEYS_URL;
  const [isTriggerSurvey, setIsTriggerSurvey] = useState<boolean | undefined>(
    undefined
  );
  const [internalTitle, setInternalTitle] = useState("");
  const [userFriendlyTitle, setUserFriendlyTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [questions, setQuestions] = useState(Array<Question>());
  const [productTypes, setProductTypes] = useState(Array<IProductType>());
  const [selectedProductType, setSelectedProductType] = useState<number>(0);
  const [isProductDropDownOpen, setIsProductDropDownOpen] = useState(false);
  const [isMandatory, setIsMandatory] = useState(false);

  const [triggerCode, setTriggerCode] = useState<string>("");
  const [recipients, setRecipients] = useState(Array<any>());
  const [isEnabled, setIsEnabled] = useState(false);
  const [removedQuestions, setRemovedQuestions] = useState(
    Array<QuestionUpdate>()
  );
  const [updatedQuestions, setUpdatedQuestions] = useState(
    Array<QuestionUpdate>()
  );
  const [isForAllReceivers, setIsForAllReceivers] = useState<boolean>(false);

  const [isAlertVisibile, setAlertVisible] = useState(false);
  const [alertColor, setAlertColor] = useState("");
  const [alertText, setAlertText] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [repeatAfterMonths, setRepeatAfter] = useState<number>(0);


  const history = useHistory();

  useEffect(() => {
    if (!isAddNewMode) {
      GetSurvey(surveyId?.toString() ?? "").then((survey) => {
        if (!survey) {
          return;
        }
        setInternalTitle(survey.title);
        setUserFriendlyTitle(survey.userFriendlyTitle);
        var isEditingTriggerSurvey = survey.triggerCode != null;
        setIsTriggerSurvey(isEditingTriggerSurvey);
        if (!isEditingTriggerSurvey) {
          setIsForAllReceivers(false);
        } else {
          setIsForAllReceivers(survey.isSurveyForAllReceivers);
          setTriggerCode(survey.triggerCode);
        }

        setStartDate(
          survey.startDate.substring(0, survey.startDate.indexOf("T"))
        );
        setEndDate(survey.endDate.substring(0, survey.endDate.indexOf("T")));
        let surveyQuestions = [
          ...survey.openTextQuestions,
          ...survey.ratingQuestions,
          ...survey.choiceQuestions,
        ];
        surveyQuestions.sort((a, b) => a.sequenceNumber - b.sequenceNumber);
        setQuestions(surveyQuestions);

        const uniqueIds : Array<number> = survey.surveyReceivers
          .map((receiver) => receiver.licenseId)
          .filter((value, index, array) => array.indexOf(value) === index);

        setIsEnabled(survey.isEnabled);
        setIsMandatory(survey.isMandatory)
        setRecipients(
          uniqueIds.map((id) => {
            return {
              id: id.toString(),
              text: id.toString(),
            };
          })
        );

        setSelectedProductType(survey.productType);
        setRepeatAfter(survey.repeatAfterMonths)
      });
    }

    GetProductTypes().then((types) => {
      setProductTypes(types);
    });
    
    //This is temporary - there is some sort of bug with
    //React-Tag-Input that causes it to capture "Enter"
    //And delete tags for no reason when it is not focused
    document.addEventListener("keydown", (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    });
  }, [isAddNewMode, surveyId]);

  function isChoiceQuestion(
    question: Question
  ): question is ISurveyChoiceQuestion {
    return "isMultipleAnswers" in question;
  }

  function isRatingQuestion(
    question: Question
  ): question is ISurveyRatingQuestion {
    return "fromText" in question;
  }

  function isNewQuestion(question: Question): question is ISurveyNewQuestion {
    return "isNewQuestion" in question;
  }

  function isOpenTextQuestion(
    object: Question
  ): object is ISurveyOpenTextQuestion {
    return (
      !isChoiceQuestion(object) &&
      !isRatingQuestion(object) &&
      !isNewQuestion(object)
    );
  }

  function addQuestion(
    question:
      | ISurveyChoiceQuestion
      | ISurveyOpenTextQuestion
      | ISurveyRatingQuestion,
    addingIndex: number
  ) {
    const newQuestions = questions.map((existingQuestion, index) => {
      if (index === addingIndex) {
        return question;
      }
      return existingQuestion;
    });

    setQuestions(newQuestions);
  }

  function createQuestion() {
    logLmEvent(EVENTS.NEW_QUESTION_BUTTON);
    setQuestions([...questions, { isNewQuestion: true, id: -1 }]);
  }

  function addRandomRecipients() {
    GetRandomReceivers(selectedProductType).then((receivers) => {
      const newReceivers = receivers.reduce((result, receiver) => {
        if (
          !recipients.some((recipient) => {
            return recipient.id === receiver.toString();
          })
        ) {
          result.push({ id: receiver.toString(), text: receiver.toString() });
        }
        return result;
      }, Array<any>());
      logLmEvent(EVENTS.ADD_RANDOM_RECIPIENTS_BUTTON,{Value:newReceivers.map((r)=>r.text).join(", ")});
      setRecipients([...recipients, ...newReceivers]);
      setIsSaveDisabled(true);
    });
  }

  function verifySurveyLicenses() {
    const licenseIds = recipients.map((receiver) => {
      return parseInt(receiver.text);
    });
    VerifySurveyLicenses(selectedProductType, licenseIds).then((result) => {
      const newReceivers = result.reduce((result, receiver) => {
          result.push({ id: receiver.toString(), text: receiver.toString() });
        return result;
      }, Array<any>());
      logLmEvent(EVENTS.VERIFY_RECIPIENTS_BUTTON);
      setRecipients(newReceivers);
      setIsSaveDisabled(false);
    });
  }

  function generateRandomTriggerCode() {
    const guid = Guid.raw();
    logLmEvent(EVENTS.GENERATE_TRIGGER_CODE_BUTTON,{Value:guid});
    setTriggerCode(guid);
  }

  function removeQuestion(removal) {
    if (!isAddNewMode) {
      if (
        !removedQuestions.some(
          (previousRemoval) =>
            previousRemoval.type === removal.type &&
            previousRemoval.id === removal.id
        )
      ) {
        setRemovedQuestions([...removedQuestions, removal]);
      }
    }

    setQuestions([
      ...questions.slice(0, removal.index),
      ...questions.slice(removal.index + 1),
    ]);
  }

  function updateQuestion(update) {
    if (!isAddNewMode) {
      if (
        !updatedQuestions.some(
          (previousUpdate) =>
            previousUpdate.type === update.type &&
            previousUpdate.id === update.id
        )
      ) {
        setUpdatedQuestions([...updatedQuestions, update]);
      }
    }
  }

  function isTriggerCodeValid(): boolean {
    if (triggerCode === undefined || triggerCode === "") {
      return false;
    }

    return Guid.isGuid(triggerCode);
  }

  function saveSurvey() {
    logLmEvent(EVENTS.RECEIVERS,{Value:recipients.map((r)=>r.id).join(", ")});
    if (isAddNewMode) {
      createSurvey();
    } else {
      updateSurvey();
    }
  }

  function createSurvey() {
    if (isForAllReceivers && triggerCode === "") {
      handleFrontEndError(
        'Field "Send survey to all receivers" can be checked only for surveys with trigger code'
      );
      return;
    }

    if (triggerCode !== "" && !isTriggerCodeValid()) {
      handleFrontEndError("Incorrect trigger code format");
      return;
    }

    if (Date.parse(startDate) > Date.parse(endDate)) {
      handleFrontEndError("End Date has to be after Start Date");
      return;
    }

    if (!IsRepeatAfterValid()){
      return;
    }

    const survey: ISurvey = {
      title: internalTitle,
      userFriendlyTitle: userFriendlyTitle,
      startDate: startDate,
      endDate: endDate,
      isEnabled: false,
      isMandatory: isMandatory,
      isSurveyForAllReceivers: isForAllReceivers,
      triggerCode: triggerCode,
      productType: selectedProductType,
      repeatAfterMonths: repeatAfterMonths,
      openTextQuestions: questions.filter(
        (question): question is ISurveyOpenTextQuestion =>
          isOpenTextQuestion(question)
      ),
      ratingQuestions: questions.filter(
        (question): question is ISurveyRatingQuestion =>
          isRatingQuestion(question)
      ),
      choiceQuestions: questions.filter(
        (question): question is ISurveyChoiceQuestion =>
          isChoiceQuestion(question)
      ),
      receiversLicenseIds: recipients.map((recipient) => {
        return parseInt(recipient.text);
      }),
    };

    if (
      survey.choiceQuestions?.some((question) => {
        let answerOptions = question.answerOptions.split(";");
        return answerOptions.some((option) => option.trim() === "");
      })
    ) {
      handleFrontEndError("Choice question options cannot be empty!");
      return;
    }

    AddSurvey(survey)
      .then(() => {
        history.push(returnUrl);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async function updateSurvey() {
    if (Date.parse(startDate) > Date.parse(endDate)) {
      handleFrontEndError("End Date has to be after Start Date");
      return;
    }

    if (isTriggerSurvey && !isTriggerCodeValid()) {
      handleFrontEndError("Trigger survey MUST a have valid trigger code");
      return;
    }

    if (!IsRepeatAfterValid()){
        return;
    }

    const survey: ISurvey = {
      surveyId: surveyId,
      title: internalTitle,
      userFriendlyTitle: userFriendlyTitle,
      startDate: startDate,
      endDate: endDate,
      isEnabled: isEnabled,
      isMandatory: isMandatory,
      receiversLicenseIds: recipients.map((recipient) => {
        return parseInt(recipient.text);
      }),
      isSurveyForAllReceivers: isForAllReceivers,
      triggerCode: triggerCode,
      productType: selectedProductType,
      repeatAfterMonths: repeatAfterMonths
    };

    UpdateSurvey(survey)
      .then(() => updateQuestions())
      .catch((error) => {
        handleError(error);
      });
  }

  function IsRepeatAfterValid() : boolean{
    if (!repeatAfterMonths && repeatAfterMonths !== 0){
      handleFrontEndError("Repeat after(months) should be a valid number");
      return false
    }

    if (repeatAfterMonths < 0){
      handleFrontEndError("Repeat after(months) can't be negative");
      return false
    }

    return true
  }

  function isAValidId(id: string): boolean {
    return (id.trim() !== '') && (!isNaN(+id)) && (id.trim().length < 10);
  }

  function updateQuestions() {
    const promises: Array<Promise<any>> = [];
    try {
      updatedQuestions.forEach((questionUpdate) => {
        if (questionUpdate.id !== undefined) {
          if (questionUpdate.type === QuestionTypes.Choice) {
            let question = questions.filter((question) => {
              if (isChoiceQuestion(question) && question.id === questionUpdate.id) {
                return question;
              };
            })[0]

            if (question === undefined) {
              return;
            }

            if (!validateChoiceQuestionUpdate(question)) {
              throw new Error("Choice question options cannot be empty!");
            }
            promises.push(
              UpdateChoiceQuestion(
                questions.filter(
                  (question): question is ISurveyChoiceQuestion =>
                    isChoiceQuestion(question) &&
                    question.id === questionUpdate.id
                )[0]
              )
            );
          } else if (questionUpdate.type === QuestionTypes.OpenText) {
            promises.push(
              UpdateOpenTextQuestion(
                questions.filter(
                  (question): question is ISurveyOpenTextQuestion =>
                    isOpenTextQuestion(question) &&
                    question.id === questionUpdate.id
                )[0]
              )
            );
          } else if (questionUpdate.type === QuestionTypes.Rating) {
            promises.push(
              UpdateRatingQuestion(
                questions.filter(
                  (question): question is ISurveyRatingQuestion =>
                    isRatingQuestion(question) &&
                    question.id === questionUpdate.id
                )[0]
              )
            );
          }
        }
      });
    } catch (e) {
      handleFrontEndError(e.message);
      return;
    }

    removedQuestions.forEach(async (questionRemoval) => {
      if (questionRemoval.type === QuestionTypes.Choice) {
        promises.push(DeleteChoiceQuestion(questionRemoval.id.toString()));
      } else if (questionRemoval.type === QuestionTypes.Rating) {
        promises.push(DeleteRatingQuestion(questionRemoval.id.toString()));
      } else if (questionRemoval.type === QuestionTypes.OpenText) {
        promises.push(DeleteOpenTextQuestion(questionRemoval.id.toString()));
      }
    });

    const addedQuestions = questions.filter((question) => {
      return question.id === undefined;
    });

    addedQuestions.forEach(async (question) => {
      if (surveyId === undefined) {
        return;
      }

      if (isChoiceQuestion(question)) {
        promises.push(AddChoiceQuestion(question, surveyId));
      } else if (isRatingQuestion(question)) {
        promises.push(AddRatingQuestion(question, surveyId));
      } else if (isOpenTextQuestion(question)) {
        promises.push(AddOpenTextQuestion(question, surveyId));
      }
    });

    Promise.all(promises)
      .then(() => {
        history.push(returnUrl);
      })
      .catch((error) => {
        handleError(error);
      });
  }

  function validateChoiceQuestionUpdate(question): boolean {
    if (question === undefined) {
      return false;
    }

    let answerOptions = question.answerOptions.split(";");
    return !answerOptions.some((option) => option.trim() === "");
  }

  const handleDelete = (i) => {
    setRecipients(recipients.filter((tag, index) => index !== i));
  };

  const handleAddition = (tag) => {
    if (isAValidId(tag.text)) {
      const licenseId = tag.id.toString()
      setRecipients([
        ...recipients,
        { id: licenseId, text: licenseId },
      ]);
      setIsSaveDisabled(true);
    } else {
      if (tag.text.includes(',')){
        const ids = tag.text.split(',');
        ids.forEach(id => {
          if (!isAValidId(id.trim())){
            ids.splice(ids.indexOf(id), 1);
          }
        });
        let newRecipients: {id: string, text: string}[] = [];
        ids.forEach(id => {
          if(isAValidId(id)) {
            const licenseId = id.toString().trim();
            newRecipients.push({ id: licenseId, text: licenseId })
          }
        });
        setRecipients([
          ...recipients,
          ...newRecipients
        ]);
        setIsSaveDisabled(true);
      }
    }
  };

  const handleCheckBox = () => {
    setIsForAllReceivers(!isForAllReceivers);
    setRecipients(Array<any>());
  };

  const handleError = (error) => {
    console.log(error);
    const errors = error.response?.data?.errors;
    if (errors === undefined) {
      handleFrontEndError(
        error.response?.data?.errorMessage ?? "Network error"
      );
    }

    const errorKey = Object.keys(error.response?.data?.errors)[0];
    const errorText = error.response?.data.errors[errorKey];
    handleFrontEndError(errorText ?? error.message ?? "Network error");
  };

  const handleFrontEndError = (errorText) => {
    setAlertText(errorText);
    setAlertVisible(true);
    setAlertColor("danger");
  };

  const addTagManually = () => {
    const input = document.getElementsByClassName(
      "ReactTags__tagInputField"
    )[0] as HTMLInputElement;
    if (input.value !== "") {
      handleAddition({ id: input.value, text: input.value });
      logLmEvent(EVENTS.RECEIVER_ADDED,{Value:input.value})
      input.value = "";
    }
  };

  return (
    <AppContainer name={isAddNewMode ? "Create a new survey" : "Edit a survey"}>
      <Alert color={alertColor} isOpen={isAlertVisibile}>
        {alertText}
      </Alert>
      <div className="panel">
        <div className="panel-body">
          <form>
            <div style={{ marginBottom: "15px" }}>
              {TextField(
                "Internal Title",
                internalTitle,
                setInternalTitle,
                undefined,
                undefined,
                undefined,
                "text",
                true,
                1,
                300
              )}
              {TextField(
                "User Friendly Title",
                userFriendlyTitle,
                setUserFriendlyTitle,
                undefined,
                undefined,
                undefined,
                "text",
                true,
                1,
                300
              )}
              {TextField(
                "Start Date",
                startDate,
                setStartDate,
                undefined,
                undefined,
                undefined,
                "date",
                true
              )}
              {TextField(
                "End Date",
                endDate,
                setEndDate,
                undefined,
                undefined,
                undefined,
                "date",
                true
              )}
              {Checkbox(
                "Is survey mandatory?",
                "MandatorySurveyCheckbox",
                isMandatory,
                setIsMandatory
              )}
              <div className="row form-group">
                <label className="col-sm-2 col-form-label">Product Type</label>
                  <Dropdown
                    style={{ marginLeft: "10px" }}
                    className="col-sm-10"
                    direction="down"
                    isOpen={isProductDropDownOpen}
                    toggle={() => setIsProductDropDownOpen(!isProductDropDownOpen)}
                  >
                    <DropdownToggle className="dropdown-input" caret>
                      {productTypes.find((product) => product.value === selectedProductType)?.name}
                    </DropdownToggle>
                    <DropdownMenu>
                      {productTypes?.map((type) => (
                        <DropdownItem
                          key={type.value}
                          value = {type.name}
                          onClick={() => {
                            logLmEvent(EVENTS.PRODUCT_TYPE_SELECTED,{Value:type.name})
                            setSelectedProductType(type.value);
                          }}
                        >
                          {type.name}
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </Dropdown>
              </div>
              <div style={{ marginTop: "10px", maxWidth: "30%" }}>
              {TextField(
                    "Repeat after (months)",
                    repeatAfterMonths.toString(),
                    setRepeatAfter,
                    undefined,
                    undefined,
                    undefined,
                    "number",
                    true,
                    undefined,
                    undefined,
                    undefined,
                    "0"
                  )}
                  
                Receivers:
                <div style={{ display: "flex" }}>
                  <ReactTags
                    tags={recipients}
                    handleDelete={handleDelete}
                    handleAddition={handleAddition}
                    placeholder={"Enter a license id"}
                    inputFieldPosition={"bottom"}
                    allowDeleteFromEmptyInput={false}
                    
                  />
                  <Button
                    onClick={addTagManually}
                    disabled={isForAllReceivers}
                    style={{
                      marginLeft: "10px",
                      height: "35px",
                      alignSelf: "end",
                    }}
                  >
                    Add
                  </Button>
                </div>
                <div>
                  Send survey to all receivers:
                  <input
                    type="checkbox"
                    checked={isForAllReceivers}
                    onChange={(e)=>{
                      logLmEvent(EVENTS.SEND_SURVEY_TO_ALL_RECEIVERS_CHECKBOX,{Value:e.currentTarget.checked})
                      handleCheckBox();
                    }}
                    disabled={isTriggerSurvey !== undefined && !isTriggerSurvey}
                  ></input>
                </div>
                <div style={{display: "flex"}}>
                <Button
                  onClick={addRandomRecipients}
                  disabled={isForAllReceivers}
                  style={{ margin: "5px 5px 5px 0" }}
                >
                  Add random recipients
                </Button>
                <Button
                  onClick={verifySurveyLicenses}
                  disabled={isForAllReceivers}
                  style={{ margin: "5px 5px 5px 0"}}
                >
                  Verify recipients
                </Button>
                </div>
                <div
                  style={
                    isTriggerSurvey !== undefined && !isTriggerSurvey
                      ? { display: "none" }
                      : { display: "block" }
                  }
                >
                  {TextField(
                    "Trigger Code",
                    triggerCode,
                    setTriggerCode,
                    undefined,
                    undefined,
                    undefined,
                    "text",
                    true,
                    undefined,
                    undefined,
                    undefined,
                    "00000000-0000-0000-0000-000000000000"
                  )}

                  <Button
                    onClick={generateRandomTriggerCode}
                    style={{ marginTop: "5px" }}
                  >
                    Generate trigger code
                  </Button>
                </div>
              </div>
            </div>
            {questions.map((questionItem, index) => {
              if (isChoiceQuestion(questionItem)) {
                return (
                  <ChoiceQuestion
                    key={index}
                    question={questionItem}
                    index={index}
                    setQuestions={setQuestions}
                    questions={questions}
                    updateQuestion={updateQuestion}
                    removeQuestion={removeQuestion}
                  />
                );
              } else if (isRatingQuestion(questionItem)) {
                return (
                  <RatingQuestion
                    key={index}
                    question={questionItem}
                    index={index}
                    setQuestions={setQuestions}
                    questions={questions}
                    updateQuestion={updateQuestion}
                    removeQuestion={removeQuestion}
                  />
                );
              } else if (isNewQuestion(questionItem)) {
                return (
                  <NewQuestion
                    key={index}
                    addQuestion={addQuestion}
                    index={index}
                  />
                );
              } else {
                return (
                  <OpenTextQuestion
                    key={index}
                    question={questionItem}
                    index={index}
                    setQuestions={setQuestions}
                    questions={questions}
                    updateQuestion={updateQuestion}
                    removeQuestion={removeQuestion}
                  />
                );
              }
            })}
          </form>
          <div style={{ marginTop: "15px" }}>
            <Button onClick={createQuestion}> New Question </Button>
          </div>
          <div style={{ marginTop: "5px" }}>
            <Button disabled={isSaveDisabled} onClick={saveSurvey}> Save </Button>
            <label hidden={!isSaveDisabled} style={{marginLeft: "5px", color: "red"}}>You need to verify recipients before saving</label>
          </div>
        </div>
      </div>
    </AppContainer>
  );
}

export default Survey;
