import React from "react";
import QuestionTypes from "../questionTypes";
import { Button } from "reactstrap";
import {
  ISurveyChoiceQuestion,
  ISurveyOpenTextQuestion,
  ISurveyRatingQuestion,
} from "../ISurveyQuestions";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function NewQuestion(props) {
  const addQuestion = props.addQuestion;

  function createQuestion(type: QuestionTypes) {
    let question:
      | ISurveyOpenTextQuestion
      | ISurveyChoiceQuestion
      | ISurveyRatingQuestion
      | any = null;

    if (type === QuestionTypes.OpenText) {
      question = { question: "", sequenceNumber: props.index } as ISurveyOpenTextQuestion;
    } else if (type === QuestionTypes.Choice) {
      question = {
        question: "",
        answerOptions: ";",
        isMultipleAnswers: true,
        sequenceNumber: props.index
      } as ISurveyChoiceQuestion;
    } else if (type === QuestionTypes.Rating) {
      question = {
        question: "",
        fromText: "",
        toText: "",
        from: 1,
        to: 5,
        sequenceNumber: props.index
      };
    }

    if (question !== null) {
      addQuestion(question, props.index);
    }
  }

  return (
    <div
      style={{
        borderTop: "1px solid black",
        padding: "5px 0 5px 0",
        display: "flex",
        justifyContent: "space-around",
      }}
    >
      <Button
        onClick={() => {
          logLmEvent(EVENTS.OPEN_TEXT_QUESTION);
          createQuestion(QuestionTypes.OpenText);
        }}
      >
        {" "}
        Open Text Question{" "}
      </Button>
      <Button
        onClick={() => {
          logLmEvent(EVENTS.RATING_QUESTION);
          createQuestion(QuestionTypes.Rating);
        }}
      >
        {" "}
        Rating Question{" "}
      </Button>
      <Button
        onClick={() => {
          logLmEvent(EVENTS.CHOICE_QUESTION);
          createQuestion(QuestionTypes.Choice);
        }}
      >
        {" "}
        Choice Question{" "}
      </Button>
    </div>
  );
}

export default NewQuestion;
