import React, { useEffect, useState } from "react";
import { Alert, FormGroup } from "reactstrap";
import { Button } from "reactstrap";
import { Form } from "reactstrap";
import {
  GetCompanyById,
  GetCompanyUserGroups,
  UpdateCompanyGroups,
} from "../../../../api/CompanyApi";
import { GetUserGroups } from "../../../../api/GroupsApi";
import { GroupsSelection } from "../../groups/GroupFiltering";
import IGroup from "../../groups/IGroup";
import { getUserRole } from "src/components/authentication/AuthenticationService";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

type ClientsProp = {
  clientId: string;
  setCompanyName: (name: string) => void;
};

function ClientSettings(clientProp: ClientsProp) {
  const [availableGroups, setAvailableGroups] = useState(Array<IGroup>());
  const userRole = getUserRole();

  useEffect(() => {
    GetUserGroups()
      .then((groups) =>
        GetCompanyById(clientProp.clientId).then((company) => {
          clientProp.setCompanyName(company.companyName);
          GetCompanyUserGroups(clientProp.clientId).then((companyGroups) => {
            setAvailableGroups(GetAvailableGroups(groups, companyGroups));
          });
        })
      )
      .catch((error) => console.error(error));
  }, [clientProp]);

  function SubmitForm(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const groups = availableGroups.filter((group) => group.isChecked);
    UpdateCompanyGroups(clientProp.clientId, groups)
      .then(() => {
        setAlertInformation("Client groups were updated successfully.");
        setAlertVisibile(true);
        setAlertColor("info");
      })
      .catch((error) => {
        console.log(error);
        setAlertInformation("Network error.");
        setAlertVisibile(true);
        setAlertColor("danger");
      });
  }

  const [isAlertVisibile, setAlertVisibile] = useState(false);
  const [alertInformation, setAlertInformation] = useState("");
  const [alertColor, setAlertColor] = useState("");

  return (
    <>
      <Alert color={alertColor} isOpen={isAlertVisibile}>
        {alertInformation}
      </Alert>

      {userRole === 1 && (
        <div>
          <Form onSubmit={SubmitForm}>
            <div className="panel">
              <div className="panel-body no-padding-bottom">
                <FormGroup className="">
                  {GroupsSelection(availableGroups, setAvailableGroups)}
                </FormGroup>
              </div>
              <div className="panel-footer mx-4 pb-4">
                <Button color="primary"
                  type="submit"
                  onClick={()=>{
                    logLmEvent(EVENTS.SELECT_GROUP,{Value:availableGroups.filter((g)=>g.isChecked).map((g)=>g.name).join(", ")});
                    logLmEvent(EVENTS.SAVE_GROUP);
                  }}>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </div>
      )}
    </>
  );
}

export default ClientSettings;

function GetAvailableGroups(allGroups: IGroup[], companyGroups: IGroup[]) {
  return allGroups.map((group) =>
    companyGroups.some((companyGroup) => companyGroup.id === group.id)
      ? { ...group, isChecked: true }
      : group
  );
}
