import axios from "axios";
import ISurvey from "src/components/app/surveys/ISurvey";
import { ISurveyStats } from "src/components/app/surveys/ISurveyListItem";
import {
  ISurveyChoiceQuestion,
  ISurveyOpenTextQuestion,
  ISurveyRatingQuestion,
} from "src/components/app/surveys/ISurveyQuestions";
import { getToken } from "../components/authentication/AuthenticationService";
import { API_URL } from "../Constants";

export function EnableSurvey(surveyId: string) {
  return axios
    .patch(
      `${API_URL}/Survey/EnableSurvey/${surveyId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function DisableSurvey(surveyId: string) {
  return axios
    .patch(
      `${API_URL}/Survey/DisableSurvey/${surveyId}`,
      {},
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function AddReceiver(surveyId: string, licenseId: string) {
  return axios
    .post(
      `${API_URL}/Survey/AddReceiver`,
      {
        surveyId: surveyId,
        licenseId: licenseId,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}

export function GetRandomReceivers(productType: number) {
  return axios
    .get<string[]>(`${API_URL}/Survey/GetRandomReceivers/${productType}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetSurveys(productType: number) {
  return axios
    .get(`${API_URL}/Survey/GetSurveys/${productType}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data.reverse();
    });
}

export function GetSurvey(surveyId: string) {
  return axios
    .get(`${API_URL}/Survey/GetSurvey/${surveyId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetSurveyForResults(surveyId: string) {
  return axios
    .get(`${API_URL}/Survey/GetSurveyForResults/${surveyId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function GetSurveyStats(surveyId: string) : Promise<ISurveyStats> {
  return axios
    .get<Promise<ISurveyStats>>(`${API_URL}/Survey/GetSurveyStats/${surveyId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function AddSurvey(survey: ISurvey) {
  return axios
    .post(`${API_URL}/Survey/AddSurvey`, survey, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function UpdateSurvey(survey: ISurvey) {
  return axios
    .put(`${API_URL}/Survey/UpdateSurvey`, survey, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function DeleteSurvey(surveyId: string) {
  return axios
    .delete(`${API_URL}/Survey/DeleteSurvey/${surveyId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function UpdateOpenTextQuestion(question: ISurveyOpenTextQuestion) {
  return axios
    .put(`${API_URL}/Survey/UpdateOpenTextQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function UpdateChoiceQuestion(question: ISurveyChoiceQuestion) {
  return axios
    .put(`${API_URL}/Survey/UpdateChoiceQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function UpdateRatingQuestion(question: ISurveyRatingQuestion) {
  return axios
    .put(`${API_URL}/Survey/UpdateRatingQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function DeleteOpenTextQuestion(questionId: string) {
  return axios
    .delete(`${API_URL}/Survey/DeleteOpenTextQuestion/${questionId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function DeleteChoiceQuestion(questionId: string) {
  return axios
    .delete(`${API_URL}/Survey/DeleteChoiceQuestion/${questionId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function DeleteRatingQuestion(questionId: string) {
  return axios
    .delete(`${API_URL}/Survey/DeleteRatingQuestion/${questionId}`, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function AddChoiceQuestion(
  question: ISurveyChoiceQuestion,
  surveyId: number
) {
  question.surveyId = surveyId;
  return axios
    .post(`${API_URL}/Survey/AddChoiceQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function AddOpenTextQuestion(
  question: ISurveyOpenTextQuestion,
  surveyId: number
) {
  question.surveyId = surveyId;
  return axios
    .post(`${API_URL}/Survey/AddOpenTextQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function AddRatingQuestion(
  question: ISurveyRatingQuestion,
  surveyId: number
) {
  question.surveyId = surveyId;
  return axios
    .post(`${API_URL}/Survey/AddRatingQuestion`, question, {
      headers: {
        Authorization: "Bearer " + getToken().toString(),
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      return response.data;
    });
}

export function VerifySurveyLicenses(productType: number, licenseIds: number[]) {
  return axios
    .post(
      `${API_URL}/Survey/VerifySurveyLicenses`,
      {
        productType: productType,
        licenseIds: licenseIds,
      },
      {
        headers: {
          Authorization: "Bearer " + getToken().toString(),
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      return response.data;
    });
}