import React from "react";
import { FormGroup, Input } from "reactstrap";
import { InputType } from "reactstrap/lib/Input";
import TextLabel from "./TextLabel";
import { logLmEvent } from "src/assets/services/EventHandlerService";

function TextField(
  name: string,
  value: string,
  onChange?: Function,
  logEvent?: string,
  onEnter?: Function,
  onEscape?: Function,
  inputType: InputType = "text",
  isRequired: boolean = true,
  minLength?: number,
  maxLength?: number,
  showLabel: boolean = true,
  placeholder: string = ""
) {

  return (
    <FormGroup className="row">
      {
        showLabel && 
        TextLabel(name)
      }
      <div className="col-sm-10">
        <Input
          className="form-control"
          type={inputType}
          required={isRequired}
          minLength={minLength ? minLength : undefined}
          maxLength={maxLength ? maxLength : 200}
          defaultValue = {value}
          value = {value}
          onBlur={(e)=>logEvent?logLmEvent(logEvent,{Value:e.target.value}):null}
          onKeyDown={(key) => {
            if (key.key === "Enter" && onEnter) {
              onEnter();
            } else if (key.key === "Escape" && onEscape) {
              onEscape();
            }
          }}
          onChange={(e) => {
            if (onChange) {
              onChange(e.target.value);
            }
          }}
          placeholder={placeholder}
        />
      </div>
    </FormGroup>
  );
}

export default TextField;
