import React, { useState, useRef, useEffect } from "react";
import { ISurveyChoiceQuestion } from "../ISurveyQuestions";
import QuestionTypes from "../questionTypes";
import { Button } from "reactstrap";
import TextField from "../../forms/TextField";
import { uuid4 } from "@sentry/utils";

function ChoiceQuestion(props) {
  const question: ISurveyChoiceQuestion = props.question;
  const [title, setTitle] = useState(question.question);
  const [options, setOptions] = useState(question.answerOptions.split(";"));
  const [optionKeys, setOptionKeys] = useState(Array<string>())
  const [isMultipleAnswers, setIsMultipleAnswers] = useState(
    question.isMultipleAnswers
  );
  const [mounted, setMounted] = useState(false)
  const setQuestions = props.setQuestions;
  const updateQuestion = props.updateQuestion;

  if(!mounted){
    const optionKeysCopy = [...optionKeys]
    options.forEach((option, index) => {
      if (typeof(optionKeysCopy[index] === 'undefined')) {
        optionKeysCopy[index] = uuid4()
      }
    })
    setOptionKeys(optionKeysCopy)
    setMounted(true)
  }

  useEffect(() => {
    updateValue();
  }, [options, isMultipleAnswers, title]);

  const updateValue = () => {
    const questions = [...props.questions];
    questions.splice(props.index, 1, {
      id: question.id,
      question: title,
      isMultipleAnswers: isMultipleAnswers,
      answerOptions: options.join(";"),
      sequenceNumber: props.index 
    });
    setQuestions(questions);
    updateQuestion({ type: QuestionTypes.Choice, id: question.id});
  };

  const removeChoice = (index) => {
    const optionKeyCopy = [...optionKeys]
    optionKeyCopy.splice(index, 1)
    const optionArrayCopy = [...options]
    optionArrayCopy.splice(index, 1)
    setOptions(optionArrayCopy);
    setOptionKeys(optionKeyCopy)
  };

  return (
    <div
      style={{
        borderTop: "1px solid black",
        padding: "5px 0 5px 0",
        display: "flex",
      }}
    >
      <div
        style={{
          padding: "20px",
          borderRight: "1px solid lightgray",
          minWidth: "65px",
        }}
      >
        Choice
      </div>
      <div style={{ paddingLeft: "20px" }}>
        {TextField(
          "Question",
          title,
          setTitle,
          undefined,
          undefined,
          undefined,
          "text",
          true,
          1,
          300,
        )}
        <div style={{ paddingLeft: "20px" }}>
          {options.map((option, index) => {
            return (
              <ChoiceOption
                key={optionKeys[index]}
                questionOption={option}
                updateOptions={setOptions}
                updateParent={updateValue}
                removeChoice={removeChoice}
                options={options}
                index={index}
              />
            );
          })}
        </div>
        <div style={{ marginLeft: "32px" }}>
          <Button
            onClick={() => {
              setOptions([...options, ""]);
              setOptionKeys([...optionKeys, uuid4()])
            }}
          >
            {" "}
            Add option{" "}
          </Button>
        </div>
        <label>
          Is Multiple Answers?
          <input
            type="checkbox"
            checked={isMultipleAnswers}
            onChange={(e) => setIsMultipleAnswers(e.target.checked)}
          />
        </label>
      </div>
      <div style={{ marginLeft: "auto" }}>
        <Button
          onClick={() => {
            props.removeQuestion({
              type: QuestionTypes.Choice,
              id: question.id,
              index: props.index,
            });
          }}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

function ChoiceOption(props) {
  const [option, setOption] = useState(props.questionOption);
  const updateOptions = props.updateOptions;

  useEffect(() => {
    updateValue();
  }, [option]);

  const updateValue = async () => {
    const options = [...props.options];
    options.splice(props.index, 1, option);
    updateOptions(options);
  };

  return (
    <div style={{ display: "flex" }}>
      <label
        style={{ display: "flex", alignItems: "center", marginRight: "5px" }}
      >
        {props.index + 1}
      </label>
      {TextField(
        "option",
        option,
        setOption,
        undefined,
        undefined,
        undefined,
        "text",
        true,
        1,
        45,
        false,
        "Option"
      )}
      <Button
        onClick={() => {
          props.removeChoice(props.index);
        }}
      >
        X 
      </Button>
    </div>
  );
}

export default ChoiceQuestion;
