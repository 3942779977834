import React, { useState } from "react";
import { ReactNode } from "react";
import { TableInstance } from "react-table";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

function CustomPagination(
  pageOptions: number[],
  pageIndex: number,
  tableInstance: TableInstance<any>
): ReactNode {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [paginationPageSize, setPaginationPageSize] = useState(
    tableInstance?.initialState?.pageSize === undefined
      ? 20
      : tableInstance?.initialState?.pageSize
  );

  return (
    <div className="panel-footer mx-4 pb-4">
      <div className="d-flex justify-content-center">
        <div className="pagination-wrapper d-flex">
          <div className="pagination">
            <Button
              className="btn-pagination"
              onClick={() => tableInstance.previousPage()}
              disabled={!tableInstance.canPreviousPage}
              color="white"
            >
              {"← Previous"}
            </Button>

            <Button
              className={
                0 === pageIndex ? "btn-pagination-active" : "btn-pagination"
              }
              onClick={() => tableInstance.gotoPage(0)}
            >
              {"1"}
            </Button>

            <span>{InnerButtons(pageOptions, pageIndex, tableInstance)}</span>

            {tableInstance.pageCount > 1 && (
              <Button
                className={
                  tableInstance.pageCount - 1 === pageIndex
                    ? "btn-pagination-active"
                    : "btn-pagination"
                }
                onClick={() =>
                  tableInstance.gotoPage(tableInstance.pageCount - 1)
                }
              >
                {tableInstance.pageCount}
              </Button>
            )}
            <Button
              className="btn-pagination"
              onClick={() => tableInstance.nextPage()}
              disabled={!tableInstance.canNextPage}
              color="white"
            >
              {"Next →"}
            </Button>
            {"    "}

            <Dropdown
              className="ml-2"
              direction="up"
              isOpen={dropdownOpen}
              toggle={toggle}
            >
              <DropdownToggle caret>{paginationPageSize}</DropdownToggle>
              <DropdownMenu>
                {[10, 20, 50, 100].map((pageSize) => (
                  <DropdownItem
                    key={pageSize}
                    onClick={() => {
                      setPaginationPageSize(Number(pageSize));
                      tableInstance.setPageSize(Number(pageSize));
                    }}
                  >
                    {pageSize}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

function InnerButtons(
  pageOptions: number[],
  pageIndex: number,
  tableInstance: TableInstance<any>
): ReactNode {
  let pageLength = pageOptions.length;
  if (pageLength < 2) {
    return <> </>;
  }

  let start = Math.max(Math.min(pageIndex - 3, pageLength - 7), 1);
  let end = Math.min(Math.max(pageIndex + 4, 7), pageLength - 1);
  var numbers: number[] = [];
  for (var _i = start; _i < end; _i++) {
    numbers.push(_i);
  }

  return (
    <>
      {pageIndex > 4 && (
        <Button className="btn-pagination" disabled={true} color="white">
          {"…"}
        </Button>
      )}
      {numbers.map((number) => (
        <Button
          className={
            number === pageIndex ? "btn-pagination-active" : "btn-pagination"
          }
          onClick={() => tableInstance.gotoPage(number)}
          key={number}
        >
          {number + 1}
        </Button>
      ))}

      {pageLength - pageIndex >= 6 && (
        <Button className="btn-pagination" disabled={true} color="white">
          {"…"}
        </Button>
      )}
    </>
  );
}

export default CustomPagination;
