import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { nameof } from "ts-simple-nameof";
import { DistinctBy } from "../../RandomHelper";
import IGroup from "./IGroup";
import IGroups from "./IGroups";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function GroupFiltering(
  modal: boolean,
  toggle: () => void,
  Filter: () => void,
  availableGroups: IGroup[],
  setAvailableGroups: React.Dispatch<React.SetStateAction<IGroup[]>>
) {
  return (
    
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        className="groupsModal"
      >
        <ModalHeader toggle={toggle}>Filter by groups</ModalHeader>
        <ModalBody>
          {GroupsSelection(availableGroups, setAvailableGroups)}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={()=>{
            logLmEvent(EVENTS.SELECT_GROUP,{Value:availableGroups.filter((g)=>g.isChecked).map((g)=>g.name).join(", ")});
            logLmEvent(EVENTS.FILTER_GROUPS,{Value:"Close"});
            toggle();}
            }>
            Close
          </Button>{" "}
          <Button
            color="primary"
            onClick={() => {
              logLmEvent(EVENTS.SELECT_GROUP,{Value:availableGroups.filter((g)=>g.isChecked).map((g)=>g.name).join(", ")});
              logLmEvent(EVENTS.FILTER_GROUPS,{Value:"Filter"});
              toggle();
              Filter();
            }}
          >
            Filter
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export function GroupsSelection(
  availableGroups: Array<IGroup>,
  setAvailableGroups: React.Dispatch<React.SetStateAction<IGroup[]>>
) {
  return (
    <div className="modal-text-align">
      <legend className="col-sm-2 col-form-label required">Groups</legend>
      {availableGroups.map((group) => {
        return (
          <FormGroup key={group.name} check>
            <Input
              onChange={() =>
                UpdateGroups(availableGroups, setAvailableGroups, group.id)
              }
              type="checkbox"
              id={"check" + group.name}
              checked={group.isChecked}
            />
            <Label for={"check" + group.name} check>
              {group.name}
            </Label>
          </FormGroup>
        );
      })}
    </div>
  );
}

export function ResetAvailableGroups(
  availableGroups: Array<IGroup>
): Array<IGroup> {
  const temporaryGroup: Array<IGroup> = availableGroups.map((group) => ({
    ...group,
    isChecked: true,
  }));
  return temporaryGroup;
}

export function FilterByGroups(newData: IGroups[], availableGroups: IGroup[]) {
  newData = newData.filter((oldGroups) => {
    if (
      oldGroups.groups === undefined ||
      (oldGroups.groups.length === 0 &&
        availableGroups[availableGroups.length - 1].isChecked)
    ) {
      return true;
    }

    return oldGroups.groups.some((group) =>
      availableGroups.some(
        (availableGroup) =>
          availableGroup.isChecked && availableGroup.name === group.name
      )
    );
  });
  return newData;
}

export function GetAvailableGroups(currentGroups: IGroups[]) {
  currentGroups = currentGroups.length > 0 ? currentGroups : [];
  const groups = currentGroups
    .flatMap((g) => g.groups)
    .filter((g) => g !== undefined);
  const selectableGroups = DistinctBy(
    groups,
    nameof<IGroup>((group) => group.id)
  ).map((group) => ({ ...group, isChecked: true }));
  selectableGroups.push({ id: -1, name: "Without group", isChecked: true });
  return selectableGroups;
}

function UpdateGroups(
  availableGroups: IGroup[],
  setAvailableGroups: React.Dispatch<React.SetStateAction<IGroup[]>>,
  groupId: number
) {
  const temporaryGroup: Array<IGroup> = availableGroups.map((group) => ({
    ...group,
    isChecked: group.id === groupId ? !group.isChecked : group.isChecked,
  }));
  setAvailableGroups(temporaryGroup);
}

export default GroupFiltering;
