import React from "react";
import { FormGroup, Input } from "reactstrap";
import TextLabel from "./TextLabel";

function TextFieldReadOnly(name: string, value: string) {
  return (
    <FormGroup className="row">
      {TextLabel(name)}
      <div className="col-sm-10">
        <Input
          disabled={true}
          className="form-control"
          type="text"
          plaintext
          value={value}
        />
      </div>
    </FormGroup>
  );
}

export default TextFieldReadOnly;
