import React from "react";
import { Column, SortByFn } from "react-table";
import { Button } from "reactstrap";
import { nameof } from "ts-simple-nameof";
import UserRole from "../../../authentication/UserRole";
import { GetDateWithTime } from "../../../DateHelpers";
import ConfirmAlert from "../../forms/ConfirmAlert";
import IContact from "../IContact";
import { getUserRole } from "src/components/authentication/AuthenticationService";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function ClientMembersColumns(
  dateWithTimeSort: SortByFn<any>,
  sortByName: SortByFn<any>,
  sortByEnum: SortByFn<any>,
  OpenChangeUserRoleModal: (contactId: string) => void,
  ResetPassword: (contactId: string) => void,
  onCopyResetLinkClick: (copyText: string) => void
): Array<Column<IContact>> {
  const userRole = getUserRole();
  
  return [
    {
      Header: "Name",
      //Sort can't work without accessor (even thought it's not being used)
      accessor: nameof<IContact>((contact) => contact.name),
      Cell: ({ row }) => {
        const name = `${row.original.name} ${row.original.surname}`;
        return (
          <span> {row.original.companyAdmin ? name + " ADMIN" : name}</span>
        );
      },
      sortType: sortByName,
    },
    {
      Header: "Email",
      accessor: nameof<IContact>((contact) => contact.email),
    },
    {
      Header: "User role",
      accessor: nameof<IContact>((contact) => contact.userRole),
      Cell: ({ value }) => {
        return <span>{UserRole[value]}</span>;
      },
      sortType: sortByEnum,
    },
    {
      Header: "Last login",
      accessor: nameof<IContact>((contact) => contact.lastLogin),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "First login",
      accessor: nameof<IContact>((contact) => contact.firstLogin),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "First download",
      accessor: nameof<IContact>((contact) => contact.firstDownload),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
      sortType: dateWithTimeSort,
    },
    {
      Header: "Active",
      accessor: nameof<IContact>((contact) => contact.isActive),
      Cell: ({ value }) => {
        return value ? (
          <i className="pli-yes icon-lg text-success"></i>
        ) : (
          <i className="pli-close icon-lg text-danger"></i>
        );
      },
      sortType: "basic",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          <Button
            color="primary"
            className="row-button btn-sm"
            onClick={(e) => {
              logLmEvent(!!row.original.firstLogin ? EVENTS.RESET_PASSWORD : EVENTS.ACTIVATE_USER,{});
              e.preventDefault();
              ConfirmAlert("Are you sure?", () =>
                ResetPassword(row.original.id)
              );
            }}
          >
            {!!row.original.firstLogin ? "Reset password" : "Activate user"}
          </Button>
          {row.original.passwordChangeLink && (
            <Button
              color="primary"
              className="row-button btn-sm"
              onClick={() =>
                onCopyResetLinkClick(row.original.passwordChangeLink || "")
              }
            >
              Copy Reset Link
            </Button>
          )}
          &nbsp;&nbsp;
          {userRole === 1 && (
            <Button
              color="primary"
              className="row-button btn-sm"
              onClick={() => {
                logLmEvent(EVENTS.CHANGE_USER_ROLE);
                OpenChangeUserRoleModal(row.original.id)}
              }
            >
              Change user role
            </Button>
          )}
        </div>
      ),
    },
  ];
}

export default ClientMembersColumns;
