import moment from "moment";
import React, { useState } from "react";
import { Column } from "react-table";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import { Button } from "reactstrap/lib";
import { nameof } from "ts-simple-nameof";
import { GetDate, GetDateWithTime } from "../../../DateHelpers";
import ILicense from "../../allLicenses/ILicense";
import KeyCopyCell from "./KeyCopyCell";
import { getUserRole } from "src/components/authentication/AuthenticationService";
import { logLmEvent } from "src/assets/services/EventHandlerService";
import { EVENTS } from "src/Constants";

function ClientLicensesColumns(
  OpenAssignLicenseModal: (licenseId: string) => void,
  editableRowIndex: number,
  setEditingRowIndex: React.Dispatch<React.SetStateAction<number>>,
  editableLicense: ILicense,
  setEditabledLicense: React.Dispatch<React.SetStateAction<ILicense>>,
  licenseTypes: string[],
  Save: () => void
): Array<Column<ILicense>> {
  const userRole = getUserRole();

  return [
    {
      Header: "Key",
      accessor: nameof<ILicense>((license) => license.licenseKey),
      Cell: ({ value }) => {
        return KeyCopyCell(value, true);
      },
    },
    {
      Header: "Valid from",
      accessor: nameof<ILicense>((license) => license.validFrom),
      Cell: ({ value }) => {
        return <span>{GetDate(value)}</span>;
      },
    },
    {
      Header: "Valid till",
      accessor: nameof<ILicense>((license) => license.validTill),
      Cell: ({ row, value }) => {
        return row.index !== editableRowIndex ? (
          <span>{GetDate(value)}</span>
        ) : (
          <Input
            className="input-field date-input-field"
            type="datetime"
            defaultValue={GetDate(value)}
            onChange={(e) => {
              editableLicense.validTill = moment(e.target.value)
                ? new Date(e.target.value)
                : undefined;
            }}
          ></Input>
        );
      },
    },
    {
      Header: "Status",
      accessor: nameof<ILicense>((license) => license.isActive),
      Cell: ({ value }) => {
        return value ? (
          <i className="pli-yes icon-lg text-success"></i>
        ) : (
          <i className="pli-close icon-lg text-danger"></i>
        );
      },
    },
    {
      Header: "Activation date",
      accessor: nameof<ILicense>((license) => license.activationDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
    },
    {
      Header: "Last verify",
      accessor: nameof<ILicense>((license) => license.lastVerificationDate),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
    },
    {
      Header: "Created",
      accessor: nameof<ILicense>((license) => license.dateCreated),
      Cell: ({ value }) => {
        return <span>{GetDateWithTime(value)}</span>;
      },
    },
    {
      Header: "Version",
      accessor: nameof<ILicense>((license) => license.pvcaseVersion),
      Cell: ({ row, value }) => {
        const tooltipId = "tooltip" + row.id;
        const license = row.original;
        const showTooltip =
          license.productName !== undefined ||
          license.autocadVersion !== undefined ||
          license.windowsVersion !== undefined ||
          license.ip !== undefined;
        return (
          <div>
            <span id={tooltipId}>{value}</span>
            {showTooltip && (
              <UncontrolledTooltip
                placement="left"
                autohide={false}
                target={tooltipId}
              >
                {license.productName !== undefined && (
                  <div>Autodesk product name: {license.productName}</div>
                )}
                {license.windowsVersion !== undefined && (
                  <div>Windows version: {license.windowsVersion}</div>
                )}
                {license.ip !== undefined && <div>IP: {license.ip}</div>}
              </UncontrolledTooltip>
            )}
          </div>
        );
      },
    },
    {
      Header: "Type",
      accessor: nameof<ILicense>((license) => license.type),
      Cell: ({ row, value }) => {
        const [dropdownOpen, setDropdownOpen] = useState(false);
        return row.index !== editableRowIndex ||
          row.original.type.toUpperCase() === "PAID" ||
          row.original.type.toUpperCase() === "EXPIRED PAID" ||
          row.original.type.toUpperCase() === "INTERNAL" ||
          row.original.type.toUpperCase() === "EXPIRED INTERNAL" ? (
          <div>{value}</div>
        ) : (
          <Dropdown
            direction="up"
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
          >
            <DropdownToggle className="dropdown-input" caret>
              {editableLicense.type}
            </DropdownToggle>
            <DropdownMenu>
              {licenseTypes.map((type) => (
                <DropdownItem
                  key={type}
                  onClick={() => {
                    editableLicense.type = type;
                  }}
                >
                  {type}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        );
      },
    },
    {
      Header: "Member",
      accessor: nameof<ILicense>((license) => license.email),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div className="d-flex justify-content-end">
          {userRole === 1 && 
            <>
              {editableRowIndex === -1 && (
                <>
                  <Button
                    color="primary"
                    className="row-button btn-sm"
                    onClick={() => {
                      logLmEvent(EVENTS.ASSIGN_A_LICENSE);
                      OpenAssignLicenseModal(row.original.id.toString())}}
                  >
                    Assign license
                  </Button>
                  <Button
                    onClick={() => {
                      logLmEvent(EVENTS.EDIT_LICENSE);
                      setEditabledLicense(JSON.parse(JSON.stringify(row.original)));
                      setEditingRowIndex(row.index);
                    }}
                    color="primary"
                    className="row-button btn-sm ml-1"
                  >
                    Edit
                  </Button>
                </>
              )}
              {editableRowIndex === row.index && (
                <Button
                  onClick={() => {
                    Save();
                    setEditingRowIndex(-1);
                  }}
                  color="primary"
                  className="row-button btn-sm ml-1"
                >
                  Save
                </Button>
              )}
          </>
        }
      </div>
      ),
    },
  ];
}

export default ClientLicensesColumns;
